import { useMapsLibrary } from "@vis.gl/react-google-maps";
import { useEffect, useRef, useState } from "react";

interface PlaceAutocompleteProps {
  onPlaceSelect: (place: google.maps.places.PlaceResult | null) => void;
  name?: string
  placeHolder?: string
  register?: any
}

const PlaceAutocomplete = ({ onPlaceSelect, placeHolder, name, register }: PlaceAutocompleteProps) => {
  const [placeAutocomplete, setPlaceAutocomplete] =
    useState<google.maps.places.Autocomplete | null>(null);
    const inputRef = useRef<HTMLInputElement | null>(null);
  const places = useMapsLibrary('places');

  useEffect(() => {
    if (!places || !inputRef.current) return;

    const options = {
      types: ["address"],
      componentRestrictions: { country: "us" },
      fields: ['geometry', 'name', 'formatted_address', 'address_components']
    };

    setPlaceAutocomplete(new places.Autocomplete(inputRef.current, options));
  }, [places]);

  useEffect(() => {
    if (!placeAutocomplete) return;

    const listener = placeAutocomplete.addListener('place_changed', () => {
      onPlaceSelect(placeAutocomplete.getPlace());
    });

    return () => {
      google.maps.event.removeListener(listener);
    };
  }, [onPlaceSelect, placeAutocomplete]);

  return (
    register && name ? (
      <input
        {...register(name)}
        ref={(e) => {
          register(name).ref(e)
          inputRef.current = e
        }}
        placeholder={placeHolder ?? "Enter location"}
        className='form-control px-2 py-1'
      />
    ) : (
      <input
        ref={inputRef}
        placeholder={placeHolder ?? "Enter location"}
        className='form-control px-2 py-1'
      />
    )
  );
};

export default PlaceAutocomplete;