import React, { useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import style from '../Steps/style.module.scss';
import rescheduleStyle from './style.module.scss';
import check from '../../../../assests/images/check.svg';
import { StepNumberLabel } from '../Steps/StepNumberLabel';
import Step2 from '../Steps/Step2/Step2';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { SelectedPropertyCard } from './SelectedProperyCard';
import { getMeetingWithToken, rescheduleMeeting } from '../../../../api/bookings';
import { FaArrowRightLong } from 'react-icons/fa6';
import { AppButton } from '../../../../designSystem/AppButton';
import LoaderRing from '../../../../components/LoaderRing';

import { PropertyDetail } from '../PropertyDetail';
import { DrawerModalContainer } from '../../../../designSystem/DrawerModalContainer';
import { margin } from '@mui/system';

const CircleContentChecked = () => (
  <img
    src={check}
    alt=''
  />
);

export const RescheduleSteps = () => {
  const [open, setOpen] = useState(false);
  const [searchParams] = useSearchParams()
  const token = searchParams.get("token")

 

  const onOpen = () => {
    setOpen(true);
  };
  const onClose = () => {
    setOpen(false);
  };

  const navigate = useNavigate();
  const [rescheduledDate, setRescheduledDate] = useState(null);
  const handleInputChange = (date) => {
    setRescheduledDate(date.target.value);
  };

  const {
    data: booking,
    isLoading,
    isError,
  } = useQuery({
    queryKey: ['booking', token],
    queryFn: () => getMeetingWithToken(token),
  });

  const queryClient = useQueryClient();
  const {
    mutateAsync: rescheduleMutation,
    isLoading: isRescheduling,
    isError: errorrRescheduling,
  } = useMutation({
    mutationKey: ['booking', token, rescheduledDate],
    mutationFn: ({ token, rescheduledDate }) =>
      rescheduleMeeting(token, rescheduledDate),
    onSuccess: (data) => {
      queryClient.invalidateQueries(['booking', token]);
      navigate(`/book/confirmation?token=${token}`);
    },
    onError: (error) => {
      console.log('ERROR', error);
    },
  });


  const handleConfirm = async () => {
    if (rescheduledDate) {
      try {
        await rescheduleMutation({ token, rescheduledDate });
      } catch (error) {
        console.log('ERROR', error);
      }
    }
  };

  const onDetails = () => {
    navigate(`/tour-property/${booking?.booking?.property?._id}`);
  };

  return (
    <div className={`${style.layout} ${rescheduleStyle.layout}`}>
      <>
        <h3 className={style.headerTitle}>Choose A Time</h3> 
        <p className={style.headerSubtitle}>Book a new time and day that is most convenient for you</p>
      </>
      <div className={style.stepLabels}>
        <StepNumberLabel
          className={style.inactive}
          circleContent={<CircleContentChecked />}
          state='completed'
          title='Choose A Property'
        />
        <StepNumberLabel
          circleContent='2'
          state='selected'
          title='Choose Time'
        />
        <StepNumberLabel
          stepNumber={3}
          circleContent={<CircleContentChecked />}
          state='default'
          title='Identity Verifications'
        />
      </div>
      {isLoading ? (
        <div>Loading...</div>
      ) : isError ? (
        <div>Error getting booking</div>
      ) : (
        <div className='d-flex flex-column gap-2 mt-5 align-items-center justify-content-center'>
          <SelectedPropertyCard
            className={`${rescheduleStyle.card}`}
            property={booking?.booking?.property}
            onSeeDetails={onDetails}
          />
          <Step2
            className={style.containerRes}
            currentStep={2}
            propertyId={booking?.booking?.property?._id}
            onChange={handleInputChange}
            agent={booking?.booking?.user?._id}
          />

          <AppButton
            as='button'
            onClick={handleConfirm}
            disabled={!rescheduledDate}
          >
            {!isRescheduling ? <FaArrowRightLong /> : <LoaderRing />}
            {'  '}Confirm and Reschedule
          </AppButton>
          <DrawerModalContainer
            onOpen={onOpen}
            onClose={onClose}
            isOpen={open}
          >
            <PropertyDetail property={booking.booking?.property} />
          </DrawerModalContainer>
        </div>
      )}
    </div>
  );
};
