import React, { useEffect, useState } from "react";
import TopLevelDetails from "../components/TopLevelDetails";
import styled from "styled-components";
import { Carousel } from "react-bootstrap";
import { Col, Row } from "react-bootstrap";
import { useProperty } from "../../../context/PropertyContext";
import ButtonUnderlineText from "../../../components/ButtonUnderlineText";
import { Copy } from "react-feather";
import { Link } from "react-router-dom";
// import OnlineTag from "../../../components/OnlineTag";
import moment from "moment";
import MobileCardDetails from "./Mobile-CardDetails";
// import { FaEdit } from "react-icons/fa";
import '../Properties.scss'
import '../Properties.scss'
import useViewport from "../../../hooks/useViewport";

const StyledLink = styled(Link)`
  display: flex;
  height: 25px;
  justify-content: center;
  align-items: center;
  ${"" /* gap: 8px; */}

  white-space: nowrap;
  font-family: Poppins;
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  line-height: 140%; /* 22.4px */
  letter-spacing: 0.16px;
  text-decoration: underline;
  color: #5271FF;

  &:hover,
  &:focus {
    color: #4143a7;
  }
  &:disabled {
    color: #bdbebf;
    background-color: #eaeaeb;
  }
  & > svg {
    height: 16px;
    width: 16px;
  }
`;

const CopyIcon = styled(Copy)`
  display: flex;
  flex-direction: row;
  color: #5d5fef;
  border: 1px solid;
  border-radius: 4px;
  padding: 4px;
  & svg {
    height: 16px;
    width: 16px;
  }
`;

const ContainerLink = styled.div`
  display: flex;
  height: 32px;
  align-items: center;
  gap: 8px;
  &:hover {
    ${CopyIcon} {
      color: #ffffff;
      background-color: #4143a7;
    }
    a {
      color: #4143a7;
    }
  }
`;

const Card = styled.div`
  display: flex;
  padding: 16px;
  flex-direction: column;
  align-items: flex-start;
  gap: 16px;
  border-radius: 5px;
  background: #ffffff;
  border: solid 1px rgb(153, 153, 153, 0.5);
  margin-top: 20px;
  box-shadow: 0px 5px 5px 0px rgba(153, 153, 153, 0.5);
`;


const CardSection = styled.div`
  display: flex;
  padding: 0px;
  flex-direction: column;
  align-items: flex-start;
  gap: 4px;
  width: 100%;
`;


const StyledSpan = styled.span`
  font-size: 12px;
  font-family: Poppins;
  font-weight: 400;
  color: #4C4D52;
  text-transform: uppercase;
  letter-spacing: 0.22px;
  margin: 8px 0;
`;

const Details = () => {
  const { property } = useProperty();
  const {width: screenWidth} = useViewport();

  return (
    <div className="d-flex flex-column gap-3 py-3 px-4 my-4">
      <Row>
        <Col xl={6} className="mb-4 mb-xl-0">
          {property?.images && property?.images?.length > 0 ? (
            <Carousel interval={null}>
              {property.images.map((image, index) => (
                <Carousel.Item key={index}>
                  <img
                    className="w-100 rounded-3"
                    src={image}
                    onError={({ currentTarget }) => {
                      currentTarget.onerror = null;
                      currentTarget.src = require("../../../assests/images/1-placeholder.png");
                    }}
                    alt={`property-${index}`}
                  />
                </Carousel.Item>
              ))}
            </Carousel>
          ) : (
            <img
              className="w-100 rounded-3"
              
              src={require("../../../assests/images/1-placeholder.png")}
              alt="placeholder"
            />
          )}

        </Col>
        <Col xl={6}>
          <TopLevelDetails />
        </Col>
      </Row>
      {screenWidth < 755 ? (
        <MobileCardDetails />
      ) : (
        <>
        <Row className="">
        <Col xl={8} className="mb-3 mb-xl-0 pe-2">
          <Card className="h-100">
            <h4 className="card-headers">Features & Amenities</h4>
            <CardSection>
              <StyledSpan className="tags-xs">Floor Plan Highlights</StyledSpan>
              {property?.amenitiesAndFeatures &&
              property?.amenitiesAndFeatures?.floorPlanHighlights?.length > 0 ? (
                <Row className="d-flex justify-content-between w-100 gap-2 gap-xl-0">
                  <Col
                    xl={4}
                    className="d-flex justify-content-start align-items-center"
                  >
                    <ul>
                      {property?.amenitiesAndFeatures?.floorPlanHighlights
                        .slice(0, 5)
                        .map((option, index) => {
                          return (
                            <li className="listed-items" key={index}>
                              {option}
                            </li>
                          );
                        })}
                    </ul>
                  </Col>
                  <Col
                    xl={4}
                    className="d-flex justify-content-start align-items-center p-xl-0"
                  >
                    <ul>
                      {property?.amenitiesAndFeatures?.floorPlanHighlights
                        .slice(5, 10)
                        .map((option, index) => {
                          return (
                            <li className="listed-items" key={index}>
                              {option}
                            </li>
                          );
                        })}
                    </ul>
                  </Col>
                  <Col
                    xl={4}
                    className="d-flex justify-content-start align-items-center p-xl-0"
                  >
                    <ul>
                      {property?.amenitiesAndFeatures?.floorPlanHighlights
                        .slice(10, 16)
                        .map((option, index) => {
                          return (
                            <li className="listed-items" key={index}>
                              {option}
                            </li>
                          );
                        })}
                    </ul>
                  </Col>
                </Row>
              ) : (
                <span className="listed-items">N/A</span>
              )}
            </CardSection>
            <CardSection>
              <StyledSpan className="tags-xs">Kitchen Features</StyledSpan>
              {property?.amenitiesAndFeatures &&
              property?.amenitiesAndFeatures?.kitchenFeatures?.length > 0 ? (
                <Row className="d-flex justify-content-between w-100 gap-2 gap-xl-0">
                  <Col
                    xl={4}
                    className="d-flex justify-content-start align-items-center"
                  >
                    <ul>
                      {property?.amenitiesAndFeatures?.kitchenFeatures
                        .slice(0, 3)
                        .map((option, index) => {
                          return (
                            <li className="listed-items" key={index}>
                              {option}
                            </li>
                          );
                        })}
                    </ul>
                  </Col>
                  <Col
                    xl={4}
                    className="d-flex justify-content-start align-items-center p-xl-0"
                  >
                    <ul>
                      {property?.amenitiesAndFeatures?.kitchenFeatures
                        .slice(3, 6)
                        .map((option, index) => {
                          return (
                            <li className="listed-items" key={index}>
                              {option}
                            </li>
                          );
                        })}
                    </ul>
                  </Col>
                  <Col
                    xl={4}
                    className="d-flex justify-content-start align-items-center p-xl-0"
                  >
                    <ul>
                      {property?.amenitiesAndFeatures?.kitchenFeatures
                        .slice(6, 8)
                        .map((option, index) => {
                          return (
                            <li className="listed-items" key={index}>
                              {option}
                            </li>
                          );
                        })}
                    </ul>
                  </Col>
                </Row>
              ) : (
                <span className="listed-items">N/A</span>
              )}
            </CardSection>
            <CardSection>
              <StyledSpan className="tags-xs">Building Features</StyledSpan>
              {property?.amenitiesAndFeatures &&
              property?.amenitiesAndFeatures?.buildingFeatures?.length > 0 ? (
                <Row className="d-flex justify-content-between w-100 gap-2 gap-xl-0">
                  <Col
                    xl={4}
                    className="d-flex justify-content-start align-items-center"
                  >
                    <ul>
                      {property?.amenitiesAndFeatures?.buildingFeatures
                        .slice(0, 3)
                        .map((option, index) => {
                          return (
                            <li className="listed-items" key={index}>
                              {option}
                            </li>
                          );
                        })}
                    </ul>
                  </Col>
                  <Col
                    xl={4}
                    className="d-flex justify-content-start align-items-center p-xl-0"
                  >
                    <ul>
                      {property?.amenitiesAndFeatures?.buildingFeatures
                        .slice(3, 6)
                        .map((option, index) => {
                          return (
                            <li className="listed-items" key={index}>
                              {option}
                            </li>
                          );
                        })}
                    </ul>
                  </Col>
                  <Col
                    xl={4}
                    className="d-flex justify-content-start align-items-center p-xl-0"
                  >
                    <ul>
                      {property?.amenitiesAndFeatures?.buildingFeatures
                        .slice(6, 10)
                        .map((option, index) => {
                          return (
                            <li className="listed-items" key={index}>
                              {option}
                            </li>
                          );
                        })}
                    </ul>
                  </Col>
                </Row>
              ) : (
                <span className="listed-items">N/A</span>
              )}
            </CardSection>
          </Card>
        </Col>
        <Col xl={4} className="ps-2">
          <Card className="h-100">
            <h4 className="card-headers">Pet Policies</h4>
            <CardSection>
              <Row className="d-flex justify-content-between w-100 gap-2 gap-xl-0">
                <Col
                  xl={6}
                  className="d-flex justify-content-start align-items-center"
                >
                  <StyledSpan className="body-xs-b">Pets Allowed?</StyledSpan>
                </Col>
                <Col
                  xl={6}
                  className="d-flex justify-content-start justify-content-xl-end align-items-center p-xl-0"
                >
                  <span className="card-body-text">
                    {property.pets && property.pets.allowed
                      ? "Yes"
                      : property?.pets && !property?.pets?.allowed
                      ? "No"
                      : "N/A"}
                  </span>
                </Col>
              </Row>
              <Row className="d-flex justify-content-between w-100 gap-2 gap-xl-0">
                <Col
                  xl={6}
                  className="d-flex justify-content-start align-items-center"
                >
                  <StyledSpan className="body-xs-b">Pet Deposit</StyledSpan>
                </Col>
                <Col
                  xl={6}
                  className="d-flex justify-content-start justify-content-xl-end align-items-center p-xl-0"
                >
                  <span className="card-body-text">
                    {property.pets && property.pets.deposit
                      ? `$${property.pets.deposit}`
                      : "N/A"}
                  </span>
                </Col>
              </Row>
              <Row className="d-flex justify-content-between w-100 gap-2 gap-xl-0">
                <Col
                  xl={6}
                  className="d-flex justify-content-start align-items-center"
                >
                  <StyledSpan className="body-xs-b">Monthly Pet Rent</StyledSpan>
                </Col>
                <Col
                  xl={6}
                  className="d-flex justify-content-start justify-content-xl-end align-items-center p-xl-0"
                >
                  <span className="card-body-text">
                    {property.pets && property.pets.monthlyPetRent
                      ? `$${property.pets.monthlyPetRent}`
                      : "N/A"}
                  </span>
                </Col>
              </Row>
            </CardSection>
            <CardSection>
              <h4 className="card-subheaders">Dog Policies</h4>
              <Row className="d-flex justify-content-between w-100 gap-2 gap-xl-0">
                <Col
                  xl={6}
                  className="d-flex justify-content-start align-items-center"
                >
                  <StyledSpan className="body-xs-b">Pet Limit</StyledSpan>
                </Col>
                <Col
                  xl={6}
                  className="d-flex justify-content-start justify-content-xl-end align-items-center p-xl-0"
                >
                  <span className="card-body-text">
                    {property.pets && property.pets.dogs.allowed
                      ? `${property.pets.dogs.maxAllowed}`
                      : property.pets && !property.pets.dogs.allowed
                      ? "N/A"
                      : "N/A"}
                  </span>
                </Col>
              </Row>
              <Row className="d-flex justify-content-between w-100 gap-2 gap-xl-0">
                <Col
                  xl={6}
                  className="d-flex justify-content-start align-items-center"
                >
                  <StyledSpan className="body-xs-b">Weight Limit</StyledSpan>
                </Col>
                <Col
                  xl={6}
                  className="d-flex justify-content-start justify-content-xl-end align-items-center p-xl-0"
                >
                  <span className="card-body-text">
                    {property.pets && property.pets.dogs.allowed
                      ? `${property.pets.dogs.weight} lbs`
                      : property.pets && !property.pets.dogs.allowed
                      ? "N/A"
                      : "N/A"}
                  </span>
                </Col>
              </Row>
            </CardSection>
            <CardSection>
              <h4 className="card-subheaders">Cat Policies</h4>
              <Row className="d-flex justify-content-between w-100 gap-2 gap-xl-0">
                <Col
                  xl={6}
                  className="d-flex justify-content-start align-items-center"
                >
                  <StyledSpan className="body-xs-b">Pet Limit</StyledSpan>
                </Col>
                <Col
                  xl={6}
                  className="d-flex justify-content-start justify-content-xl-end align-items-center p-xl-0"
                >
                  <span className="card-body-text">
                    {property.pets && property.pets.cats.allowed
                      ? `${property.pets.cats.maxAllowed}`
                      : property.pets && !property.pets.cats.allowed
                      ? "N/A"
                      : "N/A"}
                  </span>
                </Col>
              </Row>
              <Row className="d-flex justify-content-between w-100 gap-2 gap-xl-0">
                <Col
                  xl={6}
                  className="d-flex justify-content-start align-items-center"
                >
                  <StyledSpan className="body-xs-b">Weight Limit</StyledSpan>
                </Col>
                <Col
                  xl={6}
                  className="d-flex justify-content-start justify-content-xl-end align-items-center p-xl-0"
                >
                  <span className="card-body-text">
                    {property.pets && property.pets.cats.allowed
                      ? `${property.pets.cats.weight} lbs`
                      : property.pets && !property.pets.cats.allowed
                      ? "N/A"
                      : "N/A"}
                  </span>
                </Col>
              </Row>
            </CardSection>
            <CardSection>
              <StyledSpan className="tags-xs">Notes</StyledSpan>
              <span className="card-body-text">
                {property.pets && property.pets.notes
                  ? property.pets.notes
                  : "N/A"}
              </span>
            </CardSection>
          </Card>
        </Col>
      </Row>

      <Row>
        <Col xl={8} className="pe-2">
          <div className="d-flex flex-column gap-3 h-100">
            <Card className="h-100">
              <h4 className="card-headers">Lease Options</h4>
              <CardSection>
                <Row className="d-flex justify-content-between w-100 gap-2 gap-xl-0">
                  <Col
                    xl={6}
                    className="d-flex justify-content-start align-items-center"
                  >
                    <StyledSpan className="body-xs-b">Lease Term(s)</StyledSpan>
                  </Col>
                  <Col
                    xl={6}
                    className="d-flex justify-content-start justify-content-xl-end align-items-center p-xl-0"
                  >
                    <span className="card-body-text">
                      {property.leaseTermOptions &&
                      property.leaseTermOptions.length > 0
                        ? property.leaseTermOptions.join(", ")
                        : "N/A"}
                    </span>
                  </Col>
                </Row>
              </CardSection>
              <CardSection>
                <Row className="d-flex justify-content-between w-100 gap-2 gap-xl-0">
                  <Col
                    xl={6}
                    className="d-flex justify-content-start align-items-center"
                  >
                    <StyledSpan className="body-xs-b">Listed Date</StyledSpan>
                  </Col>
                  <Col
                    xl={6}
                    className="d-flex justify-content-start justify-content-xl-end align-items-center p-xl-0"
                  >
                    <span className="card-body-text">
                      {property.listedTs
                        ? moment(property.listedTs).format("MM/DD/YY")
                        : "N/A"}
                    </span>
                  </Col>
                </Row>
              </CardSection>
              <CardSection>
                <Row className="d-flex justify-content-between w-100 gap-2 gap-xl-0">
                  <Col
                    xl={6}
                    className="d-flex justify-content-start align-items-center"
                  >
                    <StyledSpan className="body-xs-b">Available</StyledSpan>
                  </Col>
                  <Col
                    xl={6}
                    className="d-flex justify-content-start justify-content-xl-end align-items-center p-xl-0"
                  >
                    <span className="card-body-text">
                      {property.dateAvailableTs
                        ? moment(property.dateAvailableTs).format("MM/DD/YY")
                        : "N/A"}
                    </span>
                  </Col>
                </Row>
              </CardSection>
              {property?.status && property?.status === "rented" && (
                <CardSection>
                  <Row className="d-flex justify-content-between w-100 gap-2 gap-xl-0">
                    <Col
                      xl={6}
                      className="d-flex justify-content-start align-items-center"
                    >
                      <StyledSpan className="body-xs-b">Rented Date</StyledSpan>
                    </Col>
                    <Col
                      xl={6}
                      className="d-flex justify-content-start justify-content-xl-end align-items-center p-xl-0"
                    >
                      <span className="card-body-text">
                        {property.rentedDate
                          ? moment(property.rentedDate).format("MM/DD/YY")
                          : "N/A"}
                      </span>
                    </Col>
                  </Row>
                </CardSection>
              )}
              <CardSection>
                <Row className="d-flex justify-content-between w-100 gap-2 gap-xl-0">
                  <Col
                    xl={6}
                    className="d-flex justify-content-start align-items-center"
                  >
                    <StyledSpan className="body-xs-b">Application URL</StyledSpan>
                  </Col>
                  <Col
                    xl={6}
                    className="d-flex justify-content-start justify-content-xl-end align-items-center p-xl-0"
                  >
                    {property.rentalApplicationForm &&
                    property.rentalApplicationForm.url ? (
                      <StyledLink to={property.rentalApplicationForm.url}>
                        {/* <ButtonUnderlineText */}
                          {/* variantType="primary"
                          
                          target="_blank"
                        > */}
                          Application Form
                        {/* </ButtonUnderlineText> */}
                      </StyledLink>
                    ) : (
                      <span className="card-body-text">N/A</span>
                    )}
                  </Col>
                </Row>
                <Row className="d-flex justify-content-between w-100 gap-2 gap-xl-0">
                  <Col
                    xl={6}
                    className="d-flex justify-content-start align-items-center"
                  >
                    <StyledSpan className="body-xs-b mt-3">Application Fee</StyledSpan>
                  </Col>
                  <Col
                    xl={6}
                    className="d-flex justify-content-start justify-content-xl-end align-items-center p-xl-0"
                  >
                    <span className="card-body-text mt-3">
                      {property.rentalApplicationForm &&
                      property.rentalApplicationForm.fee
                        ? `$${property.rentalApplicationForm.fee}`
                        : "N/A"}
                    </span>
                  </Col>
                </Row>
              </CardSection>
            </Card>
            <Card className="h-100 mb-3 mb-xl-0">
              <h4 className="card-headers">Property Management Contacts </h4>
              <CardSection>
                <Row className="d-flex justify-content-between w-100 gap-2 gap-xl-0">
                  <Col
                    xl={6}
                    className="d-flex justify-content-start align-items-center"
                  >
                    <StyledSpan className="body-xs-b">Leasing Agent</StyledSpan>
                  </Col>
                  <Col
                    xl={6}
                    className="d-flex justify-content-start justify-content-xl-end align-items-center p-xl-0"
                  >
                    <span className="card-body-text">
                      {property.leasingAgent &&
                      (property.leasingAgent.firstName ||
                        property.leasingAgent.lastName)
                        ? `${property.leasingAgent.firstName || ""}${
                            property.leasingAgent.firstName &&
                            property.leasingAgent.lastName
                              ? " "
                              : ""
                          }${property?.leasingAgent?.lastName || ""}`
                        : "N/A"}
                    </span>
                  </Col>
                </Row>
                <Row className="d-flex justify-content-between w-100 gap-2 gap-xl-0">
                  <Col
                    xl={6}
                    className="d-flex justify-content-start align-items-center"
                  >
                    <StyledSpan className="body-xs-b">Phone</StyledSpan>
                  </Col>
                  <Col
                    xl={6}
                    className="d-flex justify-content-start justify-content-xl-end align-items-center p-xl-0"
                  >
                    {property.leasingAgent && property.leasingAgent.phone ? (
                      <StyledLink className="card-body-text">
                        {property.leasingAgent.phone}
                      </StyledLink>
                    ) : (
                      <span className="card-body-text">N/A</span>
                    )}
                  </Col>
                </Row>
                <Row className="d-flex justify-content-between w-100 gap-2 gap-xl-0">
                  <Col
                    xl={6}
                    className="d-flex justify-content-start align-items-center"
                  >
                    <StyledSpan className="body-xs-b">Email</StyledSpan>
                  </Col>
                  <Col
                    xl={6}
                    className="d-flex justify-content-start justify-content-xl-end align-items-center p-xl-0"
                  >
                    {property.leasingAgent && property.leasingAgent.email ? (
                      <StyledLink className="card-body-text">
                        {property.leasingAgent.email}
                      </StyledLink>
                    ) : (
                      <span className="card-body-text">N/A</span>
                    )}
                  </Col>
                </Row>
              </CardSection>
              <CardSection>
                <Row className="d-flex justify-content-between w-100 gap-2 gap-xl-0">
                  <Col
                    xl={6}
                    className="d-flex justify-content-start align-items-center"
                  >
                    <h4 className="card-subheaders">Property Manager</h4>
                  </Col>
                  <Col
                    xl={6}
                    className="d-flex justify-content-start justify-content-xl-end align-items-center p-xl-0"
                  >
                    <span className="card-body-text">
                      {property.propertyManager &&
                      (property.propertyManager.firstName ||
                        property.propertyManager.lastName)
                        ? `${property.propertyManager.firstName || ""}${
                            property.propertyManager.firstName &&
                            property.propertyManager.lastName
                              ? " "
                              : ""
                          }${property?.propertyManager?.lastName || ""}`
                        : "N/A"}
                    </span>
                  </Col>
                </Row>
                <Row className="d-flex justify-content-between w-100 gap-2 gap-xl-0">
                  <Col
                    xl={6}
                    className="d-flex justify-content-start align-items-center"
                  >
                    <StyledSpan className="body-xs-b">On Site?</StyledSpan>
                  </Col>
                  <Col
                    xl={6}
                    className="d-flex justify-content-start justify-content-xl-end align-items-center p-xl-0"
                  >
                    <span className="card-body-text">
                      {property.propertyManager &&
                      property.propertyManager.onSite
                        ? "Yes"
                        : property?.propertyManager &&
                          !property?.propertyManager?.onSite
                        ? "No"
                        : "N/A"}
                    </span>
                  </Col>
                </Row>
                <Row className="d-flex justify-content-between w-100 gap-2 gap-xl-0">
                  <Col
                    xl={6}
                    className="d-flex justify-content-start align-items-center"
                  >
                    <StyledSpan className="body-xs-b">Phone</StyledSpan>
                  </Col>
                  <Col
                    xl={6}
                    className="d-flex justify-content-start justify-content-xl-end align-items-center p-xl-0"
                  >
                    {property.propertyManager &&
                    property.propertyManager.phone ? (
                      <StyledLink className="card-body-text">
                        {property.propertyManager.phone}
                      </StyledLink>
                    ) : (
                      <span className="card-body-text">N/A</span>
                    )}
                  </Col>
                </Row>
                <Row className="d-flex justify-content-between w-100 gap-2 gap-xl-0">
                  <Col
                    xl={6}
                    className="d-flex justify-content-start align-items-center"
                  >
                    <StyledSpan className="body-xs-b">Email</StyledSpan>
                  </Col>
                  <Col
                    xl={6}
                    className="d-flex justify-content-start justify-content-xl-end align-items-center p-xl-0"
                  >
                    {property.propertyManager &&
                    property.propertyManager.email ? (
                      <StyledLink className="card-body-text">
                        {property.propertyManager.email}
                      </StyledLink>
                    ) : (
                      <span className="card-body-text">N/A</span>
                    )}
                  </Col>
                </Row>
              </CardSection>
              <CardSection>
                <Row className="d-flex justify-content-between w-100 gap-2 gap-xl-0">
                  <Col
                    xl={6}
                    className="d-flex justify-content-start align-items-center"
                  >
                    <h4 className="card-subheaders">Maintenance Manager</h4>
                  </Col>
                  <Col
                    xl={6}
                    className="d-flex justify-content-start justify-content-xl-end align-items-center p-xl-0"
                  >
                    <span className="card-body-text">
                      {property.maintenanceManager &&
                      (property.maintenanceManager.firstName ||
                        property.maintenanceManager.lastName)
                        ? `${property.maintenanceManager.firstName || ""}${
                            property.maintenanceManager.firstName &&
                            property.maintenanceManager.lastName
                              ? " "
                              : ""
                          }${property?.maintenanceManager?.lastName || ""}`
                        : "N/A"}
                    </span>
                  </Col>
                </Row>
                <Row className="d-flex justify-content-between w-100 gap-2 gap-xl-0">
                  <Col
                    xl={6}
                    className="d-flex justify-content-start align-items-center"
                  >
                    <StyledSpan className="body-xs-b">On Site?</StyledSpan>
                  </Col>
                  <Col
                    xl={6}
                    className="d-flex justify-content-start justify-content-xl-end align-items-center p-xl-0"
                  >
                    <span className="card-body-text">
                      {property.maintenanceManager &&
                      property.maintenanceManager.onSite
                        ? "Yes"
                        : property?.maintenanceManager &&
                          !property?.maintenanceManager?.onSite
                        ? "No"
                        : "N/A"}
                    </span>
                  </Col>
                </Row>
                <Row className="d-flex justify-content-between w-100 gap-2 gap-xl-0">
                  <Col
                    xl={6}
                    className="d-flex justify-content-start align-items-center"
                  >
                    <StyledSpan className="body-xs-b">Phone</StyledSpan>
                  </Col>
                  <Col
                    xl={6}
                    className="d-flex justify-content-start justify-content-xl-end align-items-center p-xl-0"
                  >
                    {property.maintenanceManager &&
                    property.maintenanceManager.phone ? (
                      <StyledLink className="card-body-text">
                        {property.maintenanceManager.phone}
                      </StyledLink>
                    ) : (
                      <span className="card-body-text">N/A</span>
                    )}
                  </Col>
                </Row>
                <Row className="d-flex justify-content-between w-100 gap-2 gap-xl-0">
                  <Col
                    xl={6}
                    className="d-flex justify-content-start align-items-center"
                  >
                    <StyledSpan className="body-xs-b">Email</StyledSpan>
                  </Col>
                  <Col
                    xl={6}
                    className="d-flex justify-content-start justify-content-xl-end align-items-center p-xl-0"
                  >
                    {property.maintenanceManager &&
                    property.maintenanceManager.email ? (
                      <StyledLink className="card-body-text">
                        {property.maintenanceManager.email}
                      </StyledLink>
                    ) : (
                      <span className="card-body-text">N/A</span>
                    )}
                  </Col>
                </Row>
              </CardSection>
              <CardSection>
                <Row className="d-flex justify-content-between w-100 gap-2 gap-xl-0">
                  <Col
                    xl={6}
                    className="d-flex justify-content-start align-items-center"
                  >
                    <h4 className="card-subheaders">Management Co.</h4>
                  </Col>
                  <Col
                    xl={6}
                    className="d-flex justify-content-start justify-content-xl-end align-items-center p-xl-0"
                  >
                    <span className="card-body-text">
                      {property.company && property.company.name
                        ? property.company.name
                        : "N/A"}
                    </span>
                  </Col>
                </Row>
                <Row className="d-flex justify-content-between w-100 gap-2 gap-xl-0">
                  <Col
                    xl={6}
                    className="d-flex justify-content-start align-items-center"
                  >
                    <StyledSpan className="body-xs-b">Company Website</StyledSpan>
                  </Col>
                  <Col
                    xl={6}
                    className="d-flex justify-content-start justify-content-xl-end align-items-center p-xl-0"
                  >
                    {property?.company && property?.company?.website ? (
                      <StyledLink
                        className="card-body-text"
                        to={property.company.website}
                        target="_blank"
                      >
                        {property?.company?.website}
                      </StyledLink>
                    ) : (
                      <span className="card-body-text">N/A</span>
                    )}
                  </Col>
                </Row>
                <Row className="d-flex justify-content-between w-100 gap-2 gap-xl-0">
                  <Col
                    xl={6}
                    className="d-flex justify-content-start align-items-center"
                  >
                    <StyledSpan className="body-xs-b">Company Phone</StyledSpan>
                  </Col>
                  <Col
                    xl={6}
                    className="d-flex justify-content-start justify-content-xl-end align-items-center p-xl-0"
                  >
                    {property.company && property.company.phone ? (
                      <StyledLink className="card-body-text">
                        {property.company.phone}
                      </StyledLink>
                    ) : (
                      <span className="card-body-text">N/A</span>
                    )}
                  </Col>
                </Row>
              </CardSection>
            </Card>
          </div>
        </Col>
        <Col xl={4} className="ps-2">
          <div className="d-flex flex-column gap-3 h-100">
            <Card className="h-100">
              <h4 className="card-headers">Utilities</h4>
              <CardSection>
                <Row className="d-flex justify-content-between w-100 gap-2 gap-xl-0">
                  <Col
                    xl={6}
                    className="d-flex justify-content-start align-items-center"
                  >
                    <StyledSpan className="body-xs-b">Water</StyledSpan>
                  </Col>
                  <Col
                    xl={6}
                    className="d-flex justify-content-start justify-content-xl-end align-items-center p-xl-0"
                  >
                    <span className="card-body-text">
                      {property.utilities && property.utilities.water
                        ? property.utilities.water
                        : "N/A"}
                    </span>
                  </Col>
                </Row>
                <Row className="d-flex justify-content-between w-100 gap-2 gap-xl-0">
                  <Col
                    xl={6}
                    className="d-flex justify-content-start align-items-center"
                  >
                    <StyledSpan className="body-xs-b">Electricity</StyledSpan>
                  </Col>
                  <Col
                    xl={6}
                    className="d-flex justify-content-start justify-content-xl-end align-items-center p-xl-0"
                  >
                    <span className="card-body-text">
                      {property.utilities && property.utilities.electricity
                        ? property.utilities.electricity
                        : "N/A"}
                    </span>
                  </Col>
                </Row>
                <Row className="d-flex justify-content-between w-100 gap-2 gap-xl-0">
                  <Col
                    xl={6}
                    className="d-flex justify-content-start align-items-center"
                  >
                    <StyledSpan className="body-xs-b">Gas</StyledSpan>
                  </Col>
                  <Col
                    xl={6}
                    className="d-flex justify-content-start justify-content-xl-end align-items-center p-xl-0"
                  >
                    <span className="card-body-text">
                      {property.utilities && property.utilities.gas
                        ? property.utilities.gas
                        : "N/A"}
                    </span>
                  </Col>
                </Row>
                <Row className="d-flex justify-content-between w-100 gap-2 gap-xl-0">
                  <Col
                    xl={6}
                    className="d-flex justify-content-start align-items-center"
                  >
                    <StyledSpan className="body-xs-b">Trash</StyledSpan>
                  </Col>
                  <Col
                    xl={6}
                    className="d-flex justify-content-start justify-content-xl-end align-items-center p-xl-0"
                  >
                    <span className="card-body-text">
                      {property.utilities && property.utilities.trash
                        ? property.utilities.trash
                        : "N/A"}
                    </span>
                  </Col>
                </Row>
                <Row className="d-flex justify-content-between w-100 gap-2 gap-xl-0">
                  <Col
                    xl={6}
                    className="d-flex justify-content-start align-items-center"
                  >
                    <StyledSpan className="body-xs-b">Sewage</StyledSpan>
                  </Col>
                  <Col
                    xl={6}
                    className="d-flex justify-content-start justify-content-xl-end align-items-center p-xl-0"
                  >
                    <span className="card-body-text">
                      {property.utilities && property.utilities.sewage
                        ? property.utilities.sewage
                        : "N/A"}
                    </span>
                  </Col>
                </Row>
              </CardSection>
              <CardSection>
                <StyledSpan className="tags-xs">Notes</StyledSpan>
                <span className="card-body-text">
                  {property.utilities && property.utilities.notes
                    ? property.utilities.notes
                    : "N/A"}
                </span>
              </CardSection>
            </Card>

            <Card className="h-100">
              <h4 className="card-headers">Parking</h4>
              <CardSection>
                <Row className="d-flex justify-content-between w-100 gap-2 gap-xl-0">
                  <Col
                    xl={6}
                    className="d-flex justify-content-start align-items-center"
                  >
                    <StyledSpan className="body-xs-b">Parking Type</StyledSpan>
                  </Col>
                  <Col
                    xl={6}
                    className="d-flex justify-content-start justify-content-xl-end align-items-center p-xl-0"
                  >
                    <span className="card-body-text">
                      {property.parking && property.parking.type
                        ? property.parking.type
                        : "N/A"}
                    </span>
                  </Col>
                </Row>
                <Row className="d-flex justify-content-between w-100 gap-2 gap-xl-0">
                  <Col
                    xl={6}
                    className="d-flex justify-content-start align-items-center"
                  >
                    <StyledSpan className="body-xs-b">Parking Spaces</StyledSpan>
                  </Col>
                  <Col
                    xl={6}
                    className="d-flex justify-content-start justify-content-xl-end align-items-center p-xl-0"
                  >
                    <span className="card-body-text">
                      {property.parking && property.parking.spacesNumber
                        ? property.parking.spacesNumber
                        : "N/A"}
                    </span>
                  </Col>
                </Row>
                <Row className="d-flex justify-content-between w-100 gap-2 gap-xl-0">
                  <Col
                    xl={6}
                    className="d-flex justify-content-start align-items-center"
                  >
                    <StyledSpan className="body-xs-b">Monthly Cost Per Space</StyledSpan>
                  </Col>
                  <Col
                    xl={6}
                    className="d-flex justify-content-start justify-content-xl-end align-items-center p-xl-0"
                  >
                    <span className="card-body-text">
                      {property.parking && property.parking.monthlyCostPerSpace
                        ? `$${property.parking.monthlyCostPerSpace}`
                        : "N/A"}
                    </span>
                  </Col>
                </Row>
              </CardSection>
              <CardSection>
                <StyledSpan className="tags-xs">Notes</StyledSpan>
                <span className="card-body-text">
                  {property.parking && property.parking.notes
                    ? property.parking.notes
                    : "N/A"}
                </span>
              </CardSection>
            </Card>
          </div>
        </Col>
      </Row>
        </>
      )}

    </div>
  );
};

export default Details;
