import "./Settings.scss";

import React, { useEffect, useState } from "react";
import { Col, Row, Tab } from "react-bootstrap";
import { useLocation } from "react-router-dom";
import Sidebar from "../../components/SideBar";
import Tabs from "../../components/Tabs";
import styled from "styled-components";
import SettingTab from "./Tabs/SettingTab";

const RowContainer = styled(Row)`
  .tab-content {
    padding: 0;
  }
`;
const Account = () => {
  const [activeTab, setActiveTab] = useState("settings");
  const location = useLocation();

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const query = queryParams.get("activeTab");
    if(!query) window.history.pushState({}, "", `/account?activeTab=settings`);
    setActiveTab(query ?? "settings");
  }, [location.search]);

  const handleTabChange = (eventKey) => {
    window.history.pushState({}, "", `/account?activeTab=${eventKey}`);
    setActiveTab(eventKey);
  };

  return (
    <Sidebar>
      <div className="page-main-head d-flex align-items-center">
        <Row className="align-items-center">
          <Col xl={8}>
            <div className="d-flex align-items-center flex-wrap flex-md-nowrap">
              <h2 className="color-black">Account</h2>
            </div>
          </Col>
        </Row>
      </div>

      <RowContainer className="mx-0" style={{}}>
        <Tabs
          defaultActiveKey={activeTab}
          onSelect={handleTabChange}
          style={{ paddingLeft: "32px", backgroundColor: "#EAEAEB" }}
        >
          <Tab eventKey="settings" title="Settings">
            <SettingTab />
          </Tab>
          {/* <Tab eventKey="billing" title="Billing">
              {activeTab === "billing" && <Billing />}
            </Tab>
            <Tab eventKey="users" title="Users">
              {activeTab === "users" && <Users />}
            </Tab> */}
        </Tabs>
      </RowContainer>
    </Sidebar>
  );
};

export default Account;
