import React from "react";
import { IoIosInformationCircle } from "react-icons/io";
import { AppButton } from "../designSystem/AppButton";
import useViewport from "../hooks/useViewport";
import { Button } from "react-bootstrap";

const Alert = ({ show = false, title = '', content = '', setShow, navigateTo }) => {
  const { width: screenWidth } = useViewport()
  return (
    show && <div className={`modal-outside ${screenWidth > 755 ? "" : "px-3"}`}>
      <div className="modal-inside">
        <IoIosInformationCircle size={50} color='#5271ff' />
        <p className="modal-title">{title}</p>
        <p className="expired-content">
          {content}
        </p>
        <div className="d-flex gap-3 w-100">

          {navigateTo ?
            <>
              <AppButton
                as="button"
                className="bg-white border text-dark remove-btn"
                // style={{color:"#4C4D52"}}
                onClick={() => window.location.replace(navigateTo)}
              >
                Cancel
              </AppButton>
              <AppButton
                as="button"
                className="remove-btn"
                onClick={setShow}
              >
                Save
              </AppButton>
            </> :
            <AppButton
              className="remove-btn"
              as="button"
              onClick={setShow}
            >
              Close
            </AppButton>
          }
        </div>
      </div>
    </div>
  );
};

export default Alert

