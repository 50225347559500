import { useState } from "react";
import { useParams } from "react-router-dom";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import style from "./style.module.scss";
import "./react-datepicker.scss";
import { useGetMonthAvailability } from "../../../../../api/useQueryHooks/useGetMonthAvailability";
import { useWindowValue } from "../../../../../hooks/useWindowValue";
import { toZonedTime, format } from "date-fns-tz";
import { GoCalendar } from "react-icons/go";
import { FaRegClock } from "react-icons/fa6";
import { IoIosCheckmarkCircle } from "react-icons/io";

const setSVGAttributes = (svg) => {
  svg.setAttribute("height", "20");
  svg.setAttribute("width", "20");
  const path = svg.querySelector("path");
  if (!path) return;
  path.setAttribute("stroke-width", "3.7");
};

function Step2({ currentStep, propertyId, onChange, className, agent }) {
  const { agentId } = useParams();
  const [selectedDate, setSelectedDate] = useState();
  const [selectedTime, setSelectedTime] = useState("");
  const selectedMonth = selectedDate?.getMonth();
  const selectedYear = selectedDate?.getFullYear();
  const [showDatePicker, setShowDatePicker] = useState(false);
  const [showTimePicker, setShowTimePicker] = useState(false);
  const todayDate = toZonedTime(new Date(), "America/Los_Angeles");

  const now = {
    time: todayDate.getTime(),
    month: todayDate.getMonth(),
    year: todayDate.getFullYear(),
    day: todayDate.getDay(),
  };

  const { data, isLoading, isError } = useGetMonthAvailability(
    propertyId,
    selectedMonth ?? now.month,
    selectedYear ?? now.year,
    agent ?? agentId
  );

  const windowWidth = useWindowValue("innerWidth", "resize");

  if (propertyId === "")
    return <div className={style.noProperty}>No property selected</div>;

  if (isError)
    return (
      <div>
        There was an error loading the data, please reload and try again
      </div>
    );

  const filterDates = (date) => {
    if (!data?.settings) {
      return false;
    }

    const dayNames = [
      "sunday",
      "monday",
      "tuesday",
      "wednesday",
      "thursday",
      "friday",
      "saturday",
    ];
    const dayOfWeek = date.getDay();
    const dayConfig = data?.settings.bookings.dow[dayNames[dayOfWeek]];

    return dayConfig.available;
  };
  const isAvailable = (selectedDate) => {
    const startOfDay = new Date(selectedDate);
    startOfDay.setHours(0, 0, 0, 0); 

    const endOfDay = new Date(selectedDate);
    endOfDay.setHours(23, 59, 59, 999); 

    for (
      let time = startOfDay;
      time <= endOfDay;
      time.setMinutes(time.getMinutes() + 15)
    ) {
      if (filterTimes(new Date(time))) {
        return true; 
      }
    }

    return false; 
  };
  const filterTimes = (date) => {
    if (
      date.getTime() <
      toZonedTime(new Date(), data?.settings?.bookings?.timezoneLocation)
    ) {
      return false;
    }

    if (!data?.settings) {
      return false;
    }

    const dayNames = [
      "sunday",
      "monday",
      "tuesday",
      "wednesday",
      "thursday",
      "friday",
      "saturday",
    ];
    const dayOfWeek = date.getDay();
    const { availability } = data?.settings.bookings.dow[dayNames[dayOfWeek]];

    const hours = date.getHours();
    const minutes = date.getMinutes();
    const totalMinutes = hours * 60 + minutes;
    const isAvailable = availability.some((schedule) => {
      return (
        totalMinutes >= schedule.startTime && totalMinutes <= schedule.stopTime
      );
    });

    return isAvailable;
  };

  const filterExactDates = (selectedDate, excludeDates) => {
    return excludeDates?.filter((excludeDate) => {
      return (
        excludeDate.getDay() === selectedDate.getDay() &&
        excludeDate.getMonth() === selectedMonth &&
        excludeDate.getFullYear() === selectedYear
      );
    });
  };

  const excludeTimes = (selectedDate) => {
    const excludeDates = data?.excludedDates?.map(
      (unixDate) => new Date(unixDate)
    );
    const filteredDates = filterExactDates(selectedDate, excludeDates);
    return filteredDates;
  };
  const checked = true;

  const handleDateChange = (date) => {
    setSelectedDate(date);
    setShowDatePicker(false);
  };

  const handleTimeChange = (time) => {
    setSelectedTime(format(time, "h:mm a"));
    onChange({
      target: {
        name: "localTimeRequested",
        value: format(time, "yyyy-MM-dd HH:mm"),
        type: "text",
      },
    });
    setShowTimePicker(false);
  };

  return (
    <div className={`${currentStep !== 2 ? "d-none" : ""} ${className}`}>
      <div className={style.container}>
        <p className={style.heading}>Date and Time</p>
        <div className={style.line}></div>
        <div className={style.inputContainer}>
          <label htmlFor="Date" className={style.label}>
            On
          </label>
          <button
            className={style.icon}
            onClick={() => setShowDatePicker(!showDatePicker)}
          >
            <GoCalendar size={15} />
          </button>
          <input
            name="Date"
            placeholder="Pick Date"
            className={style.inputBox}
            value={selectedDate?.toLocaleDateString()}
            onClick={() => {
              setShowDatePicker(!showDatePicker);
              setShowTimePicker(false);
            }}
          />
          {showDatePicker && (
            <div className={style.datePickerWrapper}>
              <DatePicker
                onChange={handleDateChange}
                inline
                selected={selectedDate}
                timeFormat="p"
                timeIntervals={15}
                dateFormat="Pp"
                minDate={new Date()}
                filterDate={filterDates}
              />
            </div>
          )}
        </div>
        <div className={style.inputContainer} style={{position:"relative"}}>
          <label htmlFor="Time" className={style.label2}>
            At
          </label>
          <button
            className={style.icon}
            onClick={() => setShowTimePicker(!showTimePicker)}
          >
            <FaRegClock size={15} />
          </button>
          <input
            name="Time"
            placeholder="Pick Time"
            className={style.inputBox}
            value={selectedTime}
            onClick={() => {
              setShowTimePicker(!showTimePicker);
              setShowDatePicker(false);
            }}
            disabled={!selectedDate}
          />
          {showTimePicker && (
            <div className={style.timePickerWrapper}>
              { isAvailable(selectedDate) ? <DatePicker
                selected={selectedDate}
                onChange={handleTimeChange}
                showTimeSelect
                showTimeSelectOnly
                timeIntervals={15}
                dateFormat="h:mm aa"
                filterTime={filterTimes}
                excludeTimes={excludeTimes(selectedDate)}
                inline
              /> : (<div className="copied-prop">
                <p>
                  <IoIosCheckmarkCircle
                    style={{ color: "#00BA08" }}
                    size={18}
                  />{" "}
                  There are no available times for the date selected
                </p>
              </div>)}
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default Step2;
