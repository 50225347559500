import React, { useState } from "react";
import { Button } from "react-bootstrap";
import { useProperty } from "../../../context/PropertyContext";
import { Link, useSearchParams } from "react-router-dom";
import { Title } from "@mantine/core";

import GenericModal from "../../../components/Modals";
import KeypadCodeForm from "../../../components/Keypad";
import CodeHistory from "../components/Keypad/CodeHistory";
import HardwareList from "../components/HardwareList";

import { Toaster } from "react-hot-toast";
import { IoIosAlert, IoMdInformationCircleOutline } from "react-icons/io";
import { FaClockRotateLeft } from "react-icons/fa6";
import { MdOutlineArticle } from "react-icons/md";
import InfoBanner from "../../../components/Banners/InfoBanner";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import { unassignKit } from "../../../api/kits";
import SelectKit from "../components/Kit/SelectKit";
import { RiArrowDropRightLine } from "react-icons/ri";
import ListWrapper from "../../KitHistory/components/ListWrapper";
import useViewport from "../../../hooks/useViewport";
import MobileHardwareList from "../components/Mobile-HardwareList";
import MobileListWrapper from "../../KitHistory/components/Mobile-ListWrapper";
import MobileCodeHistory from "../components/Keypad/Mobile-CodeHistory";

const System = ({ reloadHardware }) => {
  const MySwal = withReactContent(Swal);
  const [searchParams, setSearchParams] = useSearchParams();
  const { width: screenWidth } = useViewport();
  const { property, setProperty } = useProperty();
  const [modal, setModal] = useState({
    show: false,
  });

  const currentTab = searchParams.get("systemTab");

  const lockVendor = property.kit?.hardware.find(
    (hardware) => hardware.category === "lock"
  )?.lock?.vendor;

  const keypadId =
    lockVendor === "august"
      ? property.kit?.hardware.find((hardware) => hardware.category === "lock")
          ?._id
      : property.kit?.hardware.find(
          (hardware) => hardware.category === "keypad"
        )?._id;

  const handleCloseModal = () => {
    setModal({ ...modal, show: false });
  };

  const handleCreateCode = () => {
    setModal({
      show: true,
      body: (
        <KeypadCodeForm handleCancel={handleCloseModal} keypadId={keypadId} isAugust={lockVendor === "august"} />
      ),
      title: "ACCESS CODE CONFIGURATION",
      hideCloseButton: true,
    });
  };

  const updateProperty = (updatedFields) => {
    setProperty({ ...property, ...updatedFields });
  };

  const handleOpenChangeKit = () => {
    setModal({
      show: true,
      body: (
        <SelectKit
          labelText="Select the new kit you want to assign to the property."
          handleCancel={handleCloseModal}
          updateProperty={updateProperty}
          propertyId={property._id}
          reloadHardware={reloadHardware}
        />
      ),
      hideCloseButton: true,
    });
  };

  const systemTabUrl = (param, value) => {
    const newSearchParams = new URLSearchParams(searchParams);
    newSearchParams.set("activeTab", "system");
    newSearchParams.set(param, value);
    return `?${newSearchParams.toString()}`;
  };

  return (
    <section className={screenWidth < 755 ? "py-3 px-3" : "py-3 px-5"}>
      <Toaster />
      {screenWidth < 755 ? (
        <div className="bg-delet-pink d-flex justify-content-between align-items-center rounded-top w-100 p-3">
          <div className="d-flex align-items-center justify-content-between w-100">
            {property?.kit?.name ? (
              <>
                <Title className="fs-8" order={4} tt="uppercase">
                  {property.kit.name}
                </Title>

                <div className="d-flex gap-3">
                  <Button
                    className="bg-delet border-0 px-3 fs-8"
                    onClick={handleOpenChangeKit}
                  >
                    Change kit
                  </Button>

                  <Button
                    className="fs-8"
                    onClick={() =>
                      MySwal.fire({
                        title:
                          "<h4 class='fs-5'>Are you sure you want to unassign this kit?</h4>",
                        html: "<p class='text-body-tertiary fs-7'>All saved information will be lost.",
                        iconHtml: <IoIosAlert color="#5271ff" />,
                        showCancelButton: true,
                        cancelButtonText: "Close",
                        confirmButtonText: "Unassign",
                        confirmButtonColor: "#5271ff",
                        reverseButtons: true,
                        customClass: {
                          icon: "border-0 my-0 py-2",
                          popup: "px-5 pt-2",
                          title: "pt-0",
                          htmlContainer: "pt-2",
                          actions: "gap-3 w-100 flex-nowrap",
                          confirmButton: "w-100 py-2 rounded-3",
                          cancelButton:
                            "w-100 bg-white text-black border border-secondary-subtle rounded-3",
                        },
                      }).then(async (result) => {
                        if (result.isConfirmed) {
                          try {
                            await unassignKit(property.kit._id);
                            setProperty({ ...property, kit: undefined });
                            reloadHardware([])
                          } catch (error) {
                            console.log("error unassign kit: ", error);
                          }
                        }
                      })
                    }
                  >
                    Unassign Kit
                  </Button>
                </div>
              </>
            ) : (
              <div className="d-flex justify-content-between align-items-center w-100">
                <p className="d-flex gap-3 align-items-center text-body-secondary">
                  <IoIosAlert color="#929292" size={30} /> It looks like you
                  haven’t selected a kit for this property yet
                </p>
                <Button
                  className="text-white bg-delet border-0 d-flex align-items-center px-4 rounded-3"
                  onClick={handleOpenChangeKit}
                >
                  Select kit <RiArrowDropRightLine size={30} />
                </Button>
              </div>
            )}
          </div>
        </div>
      ) : (
        <div className="bg-delet-pink d-flex justify-content-between align-items-center rounded-top w-100 p-3">
          <div className="d-flex align-items-center justify-content-between w-100">
            {property?.kit?.name ? (
              <>
                <Title order={4} tt="uppercase">
                  {property.kit.name}
                </Title>

                <div className="d-flex gap-3">
                  <Button
                    className="bg-delet border-0 px-4"
                    onClick={handleOpenChangeKit}
                  >
                    Change kit
                  </Button>

                  <Button
                    onClick={() =>
                      MySwal.fire({
                        title:
                          "<h4 class='fs-5'>Are you sure you want to unassign this kit?</h4>",
                        html: "<p class='text-body-tertiary fs-6'>All saved information will be lost.",
                        iconHtml: <IoIosAlert color="#5271ff" />,
                        showCancelButton: true,
                        cancelButtonText: "Close",
                        confirmButtonText: "Unassign",
                        confirmButtonColor: "#5271ff",
                        reverseButtons: true,
                        customClass: {
                          icon: "border-0 my-0 py-2",
                          popup: "px-5 pt-2",
                          title: "pt-0",
                          htmlContainer: "pt-2",
                          actions: "gap-3 w-100 flex-nowrap",
                          confirmButton: "w-100 py-2 rounded-3",
                          cancelButton:
                            "w-100 bg-white text-black border border-secondary-subtle rounded-3",
                        },
                      }).then(async (result) => {
                        if (result.isConfirmed) {
                          try {
                            await unassignKit(property.kit._id);
                            setProperty({ ...property, kit: undefined });
                            reloadHardware([])
                          } catch (error) {
                            console.log("error unassign kit: ", error);
                          }
                        }
                      })
                    }
                  >
                    Unassign Kit
                  </Button>
                </div>
              </>
            ) : (
              <div className="d-flex justify-content-between align-items-center w-100">
                <p className="d-flex gap-3 align-items-center text-body-secondary">
                  <IoIosAlert color="#929292" size={30} /> It looks like you
                  haven’t selected a kit for this property yet
                </p>
                <Button
                  className="text-white bg-delet border-0 d-flex align-items-center px-4 rounded-3"
                  onClick={handleOpenChangeKit}
                >
                  Select kit <RiArrowDropRightLine size={30} />
                </Button>
              </div>
            )}
          </div>
        </div>
      )}

      {screenWidth < 755 ? (
        <div>
          <div className="d-flex justify-content-between col-12 border-top-0 py-3">
            <Link
              to={systemTabUrl("systemTab", "kit")}
              preventScrollReset={true}
              className={`btn so-option  px-3 bg-white d-flex  align-items-center gap-2 ${
                currentTab === "kit"
                  ? "text-primary btn-outline-primary"
                  : "text-dark-emphasis border"
              } fw-medium `}
            >
              Kit Info
            </Link>
            <Link
              to={systemTabUrl("systemTab", "history")}
              preventScrollReset={true}
              className={`btn so-option  px-3 bg-white d-flex  align-items-center gap-2 ${
                currentTab === "history"
                  ? "text-primary btn-outline-primary"
                  : "text-dark-emphasis border"
              } fw-medium `}
            >
              Codes History
            </Link>
            {property?.kit && (
              <Link
                to={systemTabUrl("systemTab", "kit-history")}
                preventScrollReset={true}
                className={`btn so-option  px-3 bg-white d-flex  align-items-center gap-2 ${
                  currentTab === "kit-history"
                    ? "text-primary btn-outline-primary"
                    : "text-dark-emphasis border"
                } fw-medium `}
              >
                Kit History
              </Link>
            )}
          </div>
          <div className="pt-3">
            {currentTab === "kit" || !currentTab ? (
              <MobileHardwareList property={property} />
            ) : currentTab === "kit-history" ? (
              <MobileListWrapper
                id={property?.kit?._id}
                hideFields={["user", "assignedUser"]}
              />
            ) : currentTab === "history" ? (
              keypadId ? (
                <MobileCodeHistory
                  keypadId={keypadId}
                  handleCreateCode={handleCreateCode}
                  vendor={lockVendor}
                />
              ) : (
                <div className="px-5">
                  <InfoBanner>
                    <span className="text-secondary fs-7">No keypad found</span>
                  </InfoBanner>
                </div>
              )
            ) : (
              <div>Incorrect route</div>
            )}
          </div>
        </div>
      ) : (
        <div className="d-flex">
          <div className="d-flex flex-column col-2 border border-top-0 p-2 gap-3">
            <Link
              to={systemTabUrl("systemTab", "kit")}
              preventScrollReset={true}
              className="btn so-option border-0 bg-white d-flex align-items-center gap-1 fw-medium text-dark-emphasis"
            >
              <IoMdInformationCircleOutline size={20} color="#4C4D52" /> Kit
              Information
            </Link>
            <Link
              to={systemTabUrl("systemTab", "history")}
              preventScrollReset={true}
              className="btn so-option border-0 bg-white d-flex align-items-center gap-1 fw-medium text-dark-emphasis"
            >
              <FaClockRotateLeft size={18} color="#4C4D52" /> Codes History
            </Link>
            {property?.kit && (
              <Link
                to={systemTabUrl("systemTab", "kit-history")}
                preventScrollReset={true}
                className="btn so-option border-0 bg-white d-flex align-items-center gap-1 fw-medium text-dark-emphasis"
              >
                <MdOutlineArticle size={20} color="#4C4D52" /> Kit History
              </Link>
            )}
          </div>

          <div className="pt-3 col">
            {currentTab === "kit" || !currentTab ? (
              <HardwareList property={property} />
            ) : currentTab === "kit-history" ? (
              <ListWrapper
                id={property?.kit?._id}
                hideFields={["user", "assignedUser"]}
              />
            ) : currentTab === "history" ? (
              keypadId ? (
                <CodeHistory
                  keypadId={keypadId}
                  handleCreateCode={handleCreateCode}
                  vendor={lockVendor}
                />
              ) : (
                <div className="px-5">
                  <InfoBanner>
                    <span className="text-secondary fs-7">No keypad found</span>
                  </InfoBanner>
                </div>
              )
            ) : (
              <div>Incorrect route</div>
            )}
          </div>
        </div>
      )}

      <GenericModal {...modal} handleClose={handleCloseModal} />
    </section>
  );
};

export default System;
