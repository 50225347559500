import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { IoCameraOutline } from "react-icons/io5";
import { Button } from "react-bootstrap";

const StyledInputImages = styled.div`
  border: 1px solid rgb(145 146 149 / 18%);
  border-radius: 6px;
  padding: 10px;
  text-align: center;
`;

const StyledPreview = styled.div`
  margin: 10px;
  padding: 5px;
  display: inline-block;
  max-width: 140px;
  position: relative;
`;

const StyledImage = styled.img`
  max-width: 100%;
  height: auto;
`;

const StyledUploadButton = styled.label`
  display: flex;
  border-radius: 100%;
  padding: 14px;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  background-color: #5d5fef;
`;

const StyledButtonDelete = styled.button`
  position: absolute;
  top: -10px;
  right: -10px;
  width: 20px;
  height: 20px;
  border: 1px solid #ccc;
  border-radius: 50%;
  background-color: #fff;
  color: #ccc;
  font-size: 14px;
  line-height: 1;
  text-align: center;
  cursor: pointer;
`;
const StyledLabel = styled.label`
  color: #5f5f5f;
  font-family: Mulish;
  font-size: 16px;
  font-weight: ${(props) =>
    props.variantType === "primary"
      ? "700"
      : props.variantType === "secondary"
      ? "400"
      : "700"};
  line-height: 140%;
  letter-spacing: 0.16px;
  padding-bottom: 0px;

  /* disabled styles */
  ${(props) =>
    props.disabled &&
    ` color: #bdbfbf;
    `}
`;
const StyledCentredSelector = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 2px;
  padding: 25px 0;
`;

const InputImages = ({ description, name, watch, setValue, register }) => {
  const [image, setImage] = useState(null);
  const [errorMessage, setErrorMessage] = useState("");
  const [inputKey, setInputKey] = useState(0);

  const files = watch(name);

  const handleImageUpload = (event) => {
    const newFile = event.target.files[0]; // Get the first file only
    const maxSize = 258 * 1024 * 1024; // 258MB in bytes

    if (!newFile) return; // If no file is selected

    if (!newFile.type.includes("image/")) {
      setErrorMessage("Only images are allowed.");
      return;
    }

    if (newFile.size > maxSize) {
      setErrorMessage("File size exceeds the 258MB limit.");
      return;
    }

    setErrorMessage("");

    // Set the new image and update the form state
    const newImage = URL.createObjectURL(newFile);
    setImage(newImage);
    setValue(name, newFile, { shouldValidate: true, shouldDirty: true });
    setInputKey((prevKey) => prevKey + 1);
  };

  const handleDeleteImage = (event) => {
    event.preventDefault();
    event.stopPropagation();

    setImage(null);
    setValue(name, null, { shouldValidate: true, shouldDirty: true }); // Clear the form state
  };

  useEffect(() => {
    if (files && files.length > 0) {
      // Check if files is not null and has items
      const initialImage = files;
      if (typeof initialImage === "string") {
        // Check if it's a string
        setImage(process.env.REACT_APP_IMAGE_PATH + initialImage); // Set the image state directly
      } else if (initialImage instanceof File) {
        // Ensure it's a File object
        const imageUrl = URL.createObjectURL(initialImage);
        setImage(imageUrl); // Set the image state with the URL
      }
    }
  }, [files]);

  return (
    <>
      <StyledLabel htmlFor="InputImages">{description}</StyledLabel>
      <StyledInputImages>
        {image ? (
          <StyledPreview>
            <StyledImage src={image} alt="Uploaded" />
            <Button onClick={handleDeleteImage} className="text-delet-blue no-bg border-0">Remove</Button>
          </StyledPreview>
        ) : (
          <div>
            <StyledCentredSelector>
              <StyledUploadButton htmlFor="uploadImageButton">
                <IoCameraOutline color="white" size={24} />
              </StyledUploadButton>
              <input
                key={inputKey}
                id="uploadImageButton"
                className="d-none"
                type="file"
                accept="image/*"
                {...register(name)}
                onChange={handleImageUpload}
              />
              <span className="body-s py-2">
                <span className="text-delet-blue">Drag</span> and{" "}
                <span className="text-delet-blue">drop</span> Files
              </span>
              <span className="body-xs" style={{ color: "#919295" }}>
                Only PNGs or JPGs Max. 258MB
              </span>
            </StyledCentredSelector>
          </div>
        )}
      </StyledInputImages>
      {errorMessage && <p className="error">{errorMessage}</p>}
    </>
  );
};

export default InputImages;
