import React, { useMemo } from "react";
import propertyDummyImage from "../../../assests/images/6-thumb.png";
import { CiLocationOn } from "react-icons/ci";
import { GoCalendar } from "react-icons/go";
import { FaRegClock } from "react-icons/fa6";
import style from "./style.module.scss";

function InstructionsCard({ property, startTime }) {
  const startDate = useMemo(() => new Date(startTime * 1000), [startTime]);

  return (
    <div className="rounded-1 shadow-lg">
      <div className={style.imgContainer}>
        <img
          src={
            property.images?.[0] || property.primaryImage || propertyDummyImage
          }
          alt="property"
          className={style.imgSize}
        />
        <table>
          <tbody>
            <tr className={style.rows}>
              <td className={style.leftTd}>
                <CiLocationOn className={style.icon2} /> Location
              </td>
              <td className={style.rightTd}>{property?.address}</td>
            </tr>
            <tr className={style.rows}>
              <td className={style.leftTd}>
                <GoCalendar className={style.icon} /> On
              </td>
              <td
                className={style.rightTd}
              >{`${startDate.getDate()} ${startDate.toLocaleString("default", {
                month: "long",
              })}, ${startDate.getFullYear()}`}</td>
            </tr>
            <tr className={style.bottomRow}>
              <td className={style.leftTd}>
                <FaRegClock className={style.icon} /> At
              </td>
              <td className={style.rightTd}>
                {startDate.toLocaleTimeString(undefined, {
                  hour: "2-digit",
                  minute: "2-digit",
                  hour12: true,
                })}
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  );
}

export default InstructionsCard;
