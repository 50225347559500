
export const PROPERTY_DEFAULT_VALUES = {
  amenitiesAndFeatures: {
    floorPlanHighlights: [],
    kitchenFeatures: [],
    buildingFeatures: []
  },
  unit: "",
  classification: null,
  category: "rent",
  sqft: "",
  bedrooms: "",
  bathrooms: "",
  propertyDescription: "",
  utilities: {
    water: "tenant",
    electricity: "tenant",
    gas: "tenant",
    trash: "tenant",
    sewage: "tenant",
    notes: ""
  },
  parking: {
    type: "streetStandard",
    spacesNumber: "",
    monthlyCostPerSpace: "",
    included: false,
    notes: "",
    tandem: []
  },
  pets: {
    allowed: false,
    dogs: {
      allowed: false,
      weight: "",
      maxAllowed: ""
    },
    cats: {
      allowed: false,
      weight: "",
      maxAllowed: ""
    },
    deposit: "",
    monthlyPetRent: "",
    notes: ""
  },
  rentalApplicationForm: {
    url: "",
    fee: "",
    instructions: "",
    enable: false
  },
  company: {
    name: "",
    website: "",
    phone: "",
    contactInformation: false
  },
  leasingAgent: {
    firstName: "",
    lastName: "",
    phone: "",
    email: "",
    contactInformation: false
  },
  propertyManager: {
    onSite: false,
    firstName: "",
    lastName: "",
    phone: "",
    email: "",
    contactInformation: false
  },
  maintenanceManager: {
    onSite: false,
    firstName: "",
    lastName: "",
    phone: "",
    email: "",
    contactInformation: false
  },
  price: "",
  deposit: "",
  dateAvailableTs: null,
  leaseTermOptions: [],
  propertyAccessCodes: {
    buildingCode: "",
    elevatorCode: "",
    instructions: "",
    enable: false
  },
  kit: "",
  shortAddress: "",
  city: "",
  zipCode: "",
  state: "",
  address: "",
  googleMap:{
    latitude: null,
    longitude: null,
  },
  images: [],
  idVerification: {
    active: false,
    frontbackID: true,
    requireFace: false,
    profile: "none"
  },
  doorUnlockLink: false,
};

export const USERPROFILE_DEFAULT_VALUES = {
  firstName: "",
  lastName: "",
  company: {
    name: "",
    idImage: "",
  },
  email: "",
  phone: ""
}

export const USER_SETTINGS_BOOKINS = {
  bookings: {
    dow: {
      sunday: {
        available: false,
        availability: [
          {
            startTime: null,
            stopTime: null
          }
        ]
      },
      monday: {
        available: false,
        availability: [
          {
            startTime: null,
            stopTime: null
          }
        ]
      },
      tuesday: {
        available: false,
        availability: [
          {
            startTime: null,
            stopTime: null
          }
        ]
      },
      wednesday: {
        available: false,
        availability: [
          {
            startTime: null,
            stopTime: null
          }
        ]
      },
      thursday: {
        available: false,
        availability: [
          {
            startTime: null,
            stopTime: null
          }
        ]
      },
      friday: {
        available: false,
        availability: [
          {
            startTime: null,
            stopTime: null
          }
        ]
      },
      saturday: {
        available: false,
        availability: [
          {
            startTime: null,
            stopTime: null
          }
        ]
      }
    },
    syncCalendar: {
      google: false,
      apple: false,
      outlook: false
    },
    timezoneLocation: "",
    duration: null,
    limitToursPerDay: null,
    allowTwoPeople: false,
    padding: null,
    timezone: null
  },
  customerSupportPhone: ""
};

export const USER_SETTINGS_NOTIFICATIONS = {
  notifications: {
    confirmation: {
      status: false,
      text: ""
    },
    reminder: {
      status: false,
      time: null,
      text: ""
    },
  },
}

export const USER_SETTINGS_COMUNICATIONS = {
  notifications: {
    confirmation: {
      status: false,
      text: ""
    },
    reminder: {
      status: false,
      time: null,
      text: ""
    },
    followUp: {
      status: false,
      time: null,
      text: ""
    }
  },
}

export const USER_SETTINGS_INTEGRATIONS = {
  integrations: {
    platformsLists: {
      realtor: {
        status: false
      },
      apartments: {
        status: false
      },
      zillow: {
        status: false
      },
      trulia: {
        status: false
      }
    },
    emailAccounts: {
      gmail: {
        status: false
      },
      outlook: {
        status: false
      },
      yahoo: {
        status: false
      },
      apple: {
        status: false
      }
    }

  }
}

export const user_booking_timezone = [
  {
    id: "US/Hawaii",
    description: "Hawaiian Time"
  },
  {
    id: "US/Alaska",
    description: "Alaskan Time"
  },
  {
    id: "America/Los_Angeles",
    description: "Pacific Time"
  },
  {
    id: "America/Denver",
    description: "Hawaiian Time"
  },
  {
    id: "America/Chicago",
    description: "Central Time"
  },
  {
    id: "America/New_York",
    description: "Eastern Time"
  }
]
export const options = [
  {
    value: 1,
    label: 'Most Recent',
  },
  {
    value: 2,
    label: 'Oldest',
  },
  {
    value: 3,
    label: 'Price (High to Low)',
  },
  {
    value: 4,
    label: 'Price (Low to High)',
  },
  // {
  //   value: 5,
  //   label: 'Available (desc)',
  // },
  // {
  //   value: 6,
  //   label: 'Available (asc)',
  // },
  // {
  //   value: 7,
  //   label: 'Rented (desc)',
  // },
  // {
  //   value: 8,
  //   label: 'Rented (asc)',
  // },
  // {
  //   value: 9,
  //   label: 'Listed (desc)',
  // },
  // {
  //   value: 10,
  //   label: 'Listed (asc)',
  // },
]

export const outcomeStatus = [
  {
    value: 1,
    label: 'Interested',
    status: 'interested'
  },
  {
    value: 2,
    label: 'Not Interested',
    status: 'not interested'
  },
  {
    value: 3,
    label: 'Rescheduled',
    status: 'rescheduled'
  },
  {
    value: 4,
    label: 'Application Sent',
    status: 'application sent'
  },
  {
    value: 5,
    label: 'Applied',
    status: 'applied'
  },
  {
    value: 6,
    label: 'Never Arrived',
    status: 'never arrived'
  }

]

export const propertyStatus = [

  {
    value: 1,
    label: 'Active',
    status: 'active',
  },
  {
    value: 2,
    label: 'Inactive',
    status: 'inactive',
  },
  {
    value: 3,
    label: 'Lease agreement sent',
    status: 'lease agreement sent',
  },
  {
    value: 4,
    label: 'Application received',
    status: 'application received',
  },
  {
    value: 5,
    label: 'Rented',
    status: 'rented',
  },
  {
    value: 6,
    label: 'Sold',
    status: 'sold',
  },

]

export const propertyFilterStatus = [
  {
    label: 'Active',
    value: 'active',
  },
  {
    label: 'Inactive',
    value: 'inactive',
  },
  {
    label: 'Lease agreement sent',
    value: 'lease agreement sent',
  },
  {
    label: 'Application received',
    value: 'application received',
  },
  {
    label: 'Rented',
    value: 'rented',
  },
  {
    label: 'Sold',
    value: 'sold',
  },
  {
    label: 'Archived',
    value: 'archived',
  },
]

export const propertyType = [
  {
    label: 'Apartment',
    value: 'apartment'
  },
  {
    label: 'House',
    value: 'house'
  },
  {
    label: 'Townhouse',
    value: 'townhouse'
  },
  {
    label: 'Condo',
    value: 'condo'
  }
];

export const states = [
  { fullName: 'Alabama', abbreviated: 'AL' },
  { fullName: 'Alaska', abbreviated: 'ALK' },
  { fullName: 'Arizona', abbreviated: 'AZ' },
  { fullName: 'Arkansas', abbreviated: 'AR' },
  { fullName: 'California', abbreviated: 'CA' },
  { fullName: 'Colorado', abbreviated: 'CO' },
  { fullName: 'Connecticut', abbreviated: 'CT' },
  { fullName: 'Delaware', abbreviated: 'DE' },
  { fullName: 'Florida', abbreviated: 'FL' },
  { fullName: 'Georgia', abbreviated: 'GA' },
  { fullName: 'Hawaii', abbreviated: 'HI' },
  { fullName: 'Idaho', abbreviated: 'ID' },
  { fullName: 'Illinois', abbreviated: 'IL' },
  { fullName: 'Indiana', abbreviated: 'IN' },
  { fullName: 'Iowa', abbreviated: 'IA' },
  { fullName: 'Kansas', abbreviated: 'KS' },
  { fullName: 'Kentucky', abbreviated: 'KY' },
  { fullName: 'Louisiana', abbreviated: 'LA' },
  { fullName: 'Maine', abbreviated: 'ME' },
  { fullName: 'Maryland', abbreviated: 'MD' },
  { fullName: 'Massachusetts', abbreviated: 'MA' },
  { fullName: 'Michigan', abbreviated: 'MI' },
  { fullName: 'Minnesota', abbreviated: 'MN' },
  { fullName: 'Mississippi', abbreviated: 'MS' },
  { fullName: 'Missouri', abbreviated: 'MO' },
  { fullName: 'Montana', abbreviated: 'MT' },
  { fullName: 'Nebraska', abbreviated: 'NE' },
  { fullName: 'Nevada', abbreviated: 'NV' },
  { fullName: 'New Hampshire', abbreviated: 'NH' },
  { fullName: 'New Jersey', abbreviated: 'NJ' },
  { fullName: 'New Mexico', abbreviated: 'NM' },
  { fullName: 'New York', abbreviated: 'NY' },
  { fullName: 'North Carolina', abbreviated: 'NC' },
  { fullName: 'North Dakota', abbreviated: 'ND' },
  { fullName: 'Ohio', abbreviated: 'OH' },
  { fullName: 'Oklahoma', abbreviated: 'OK' },
  { fullName: 'Oregon', abbreviated: 'OR' },
  { fullName: 'Pennsylvania', abbreviated: 'PA' },
  { fullName: 'Rhode Island', abbreviated: 'RI' },
  { fullName: 'South Carolina', abbreviated: 'SC' },
  { fullName: 'South Dakota', abbreviated: 'SD' },
  { fullName: 'Tennessee', abbreviated: 'TN' },
  { fullName: 'Texas', abbreviated: 'TX' },
  { fullName: 'Utah', abbreviated: 'UT' },
  { fullName: 'Vermont', abbreviated: 'VT' },
  { fullName: 'Virginia', abbreviated: 'VA' },
  { fullName: 'Washington', abbreviated: 'WA' },
  { fullName: 'West Virginia', abbreviated: 'WV' },
  { fullName: 'Wisconsin', abbreviated: 'WI' },
  { fullName: 'Wyoming', abbreviated: 'WY' },
];

export const amenities = [
  {
    label: 'Floor Plan Highlights',
    name: 'floorPlanHighlights',
    options: [
      'Air Conditioning',
      'Balcony/Patio',
      'Built-In Shelving',
      'Cable Ready',
      'Ceiling Fans',
      'Den/Dedicated Office Area',
      'Double-Pane Windows',
      'Heating',
      'High Ceilings',
      'High Speed Internet Access',
      'Loft Layout',
      'Smart Home Technology',
      'Walk-In Closets',
      'Washer/Dryer (In-Unit)',
      'Washer/Dryer (Hook-Ups Available)',
      'Views'

    ]
  },
  {
    label: 'Kitchen Features',
    name: 'kitchenFeatures',
    options: [
      'Dishwasher',
      'Garbage Disposal',
      'Ice Maker',
      'Island Kitchen',
      'Microwave',
      'Refrigerator/Freezer',
      'Range/Oven',
      'Stainless Steel Appliances',
    ]
  },
  {
    label: 'Building Features',
    name: 'buildingFeatures',
    options: [
      'Controlled Access Entry',
      'Elevator',
      'EV Charging',
      'Fitness Center',
      'Laundry Facility On Site',
      'Maintenance On Site',
      'Package Lockers',
      'Pool',
      'Storage Space',
      'Roof Terrance',
    ]
  },
];

export const category = [
  { name: 'Rent', value: 'rent' },
  { name: 'Sale', value: 'sale' }
];

export const parkingType = [
  {
    primary: { name: "Secured Access Garage", value: 'secured' },
    extra: { name: "Tandem ?", value: 'tandem' }
  },
  {
    primary: { name: "Covered Parking", value: 'covered' },
    extra: { name: "Tandem ?", value: 'tandem' }
  },
  {
    primary: { name: "Uncovered Parking", value: 'uncovered' },
    extra: { name: "Tandem ?", value: 'tandem' }
  },
  {
    primary: { name: "Street Parking (Residential Permit Enforced)", value: 'streetResidential' },
  },
  {
    primary: { name: "Street Parking (Standard)", value: 'streetStandard' },
  },
];

export const LeaseTermOptions = [
  {
    value: "15-month",
  },
  {
    value: "12-month",
  },
  {
    value: "6-month",
  },
  {
    value: "3-month"
  },
];

export const pets = [
  { name: 'No pets allowed', value: 'no' },
  { name: 'Yes, dogs allowed', value: 'dogs' },
  { name: 'Yes, cats allowed', value: 'cats' },
];

export const propertyListMenuItems = [
  {
    label: 'Edit property details'
  },
  // {
  //   label: 'Duplicate property'
  // },
  {
    label: 'Open prospect booking page'
  },
  // {
  //   label: 'Copy prospect booking link'
  // },
  // {
  //   label: 'Add new appointment time slots'
  // },
  {
    label: 'View all property leads'
  },
  {
    label: 'Archive property'
  },
];

export const propertyArchivedListMenuItems = [
  {
    label: 'Edit property details'
  },
  {
    label: 'View all property leads'
  },
  {
    label: 'Restore property'
  },
];

