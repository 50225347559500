import React from "react";
import useViewport from "../../../hooks/useViewport";

type SecurityLevelOptionProps = {
  badge: React.ReactElement;
  description: string;
  level: string;
  isDefault?: boolean;
  selected: true;
};

const SecurityLevelOption = ({
  badge,
  description,
  level,
  isDefault = false,
  selected,
}: SecurityLevelOptionProps) => {
  const { width: screenWidth } = useViewport();
  return (
    <div
      className={`border-2 rounded p-3 d-flex gap-2 align-items-center text-start ${
        selected ? "border-delet" : "border"
      }`}
    >
      <div>{badge}</div>
      <div className="gap-2">
        <h4 className="text-delet-blue">{level}</h4>
        {isDefault && <span className="default-badge">DEFAULT</span>}
        <p
          className="fw-semibold"
          style={
            screenWidth > 755 ? { fontSize: "17px" } : { fontSize: "11px" }
          }
        >
          {description}
        </p>
      </div>
    </div>
  );
};

export default SecurityLevelOption;
