import UnstyledButton from './UnstyledButton';
import { MdKeyboardArrowLeft, MdKeyboardArrowRight, MdKeyboardDoubleArrowLeft, MdKeyboardDoubleArrowRight } from 'react-icons/md';

const PagePagination = ({ totalPages, actualPage, handlePageChange }) => {
  const pageGroup = Math.ceil(actualPage / 5)
  const pagesInLastGroup = totalPages % 5
  const displayCount = pageGroup * 5 > totalPages ? pagesInLastGroup : 5

  return (
    <div className='d-flex gap-3 justify-content-center'>
      <UnstyledButton disabled={actualPage === 1} onClick={() => handlePageChange(1)}>
        <MdKeyboardDoubleArrowLeft className={`text-secondary ${actualPage === 1 && "opacity-25"}`} size={20} />
      </UnstyledButton>

      <UnstyledButton disabled={actualPage === 1} onClick={() => handlePageChange(actualPage - 1)}>
        <MdKeyboardArrowLeft className={`text-secondary ${actualPage === 1 && "opacity-25"}`} size={20} />
      </UnstyledButton>

      {Array.from({ length: displayCount }, (_, i) => {
        const page = 5 * (pageGroup - 1) + i + 1
        return (
          <UnstyledButton
            onClick={() => handlePageChange(page)}
            disabled={actualPage === page}
            className={actualPage === page ? "text-primary fw-bold" : "text-secondary"}
          >
            {page}
          </UnstyledButton>
        )
      })}

      <UnstyledButton disabled={actualPage === totalPages} onClick={() => handlePageChange(actualPage + 1)}>
        <MdKeyboardArrowRight className={`text-secondary ${actualPage === totalPages && "opacity-25"}`} size={20} />
      </UnstyledButton>

      <UnstyledButton disabled={actualPage === totalPages} onClick={() => handlePageChange(totalPages)}>
        <MdKeyboardDoubleArrowRight className={`text-secondary ${actualPage === totalPages && "opacity-25"}`} size={20} />
      </UnstyledButton>
    </div>
  );
}
export default PagePagination;