import React, { useEffect, useMemo, useRef, useState } from "react";
import CountdownTimer from "./CountdownTimer";
import InstructionsCard from "./InstructionsCard";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { getPublicLockStatus, updatePublicLockStatus } from "../../../api/lock";
import infoIcon from "../../../assests/images/info.svg";
import style from "./style.module.scss";
import { IoIosArrowBack } from "react-icons/io";
import AccessInstructions from "./AccessInstructions";
import { Button, Modal } from "react-bootstrap";
import { Link } from "react-router-dom";
import { PiInfoBold } from "react-icons/pi";

function ShowInstructionsCard({ booking, token }) {
  const queryClient = useQueryClient();
  const { property, startTime, passCode, lockVendor } = booking;
  const [timeRemaining, setTimeRemaining] = useState("");
  const [remainingButtonTime, setRemainingButtonTime] = useState(0);
  const [isActive, setIsActive] = useState(false);
  const [isButtonDisabled, setIsButtonDisabled] = useState(false);
  const [isManualAccess, setIsManualAccess] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const intervalIdRef = useRef(null);

  const start = useMemo(() => new Date(startTime * 1000 - 900000), [startTime]);
  const end = useMemo(() => new Date(startTime * 1000 + 900000), [startTime]);
  const { propertyAccessCodes, doorUnlockLink, rentalApplicationForm } =
    property;

  const {
    data: lockStatus,
    isLoading: loadingStatus,
    isError,
  } = useQuery({
    queryKey: ["public", "lock", booking._id],
    queryFn: () => getPublicLockStatus(token, property.kit),
  });

  const { mutateAsync: updateLockStatus, isPending: updatingStatus } =
    useMutation({
      mutationFn: () =>
        updatePublicLockStatus(
          token,
          property.kit,
          lockStatus === "locked" ? "unlock" : "lock"
        ),
      onSuccess: (data) => {
        const newLockStatus = data ? "locked" : "unlocked"
        queryClient.setQueryData(["public", "lock", booking._id], () => newLockStatus);
      },
    });

  const handleLock = async () => {
    await updateLockStatus();

    setIsButtonDisabled(true);
    setRemainingButtonTime(75);

    setTimeout(() => {
      setIsButtonDisabled(false);
      setRemainingButtonTime(0);
    }, 1000 * 75);
  };

  const calculateTimeRemaining = () => {
    const now = new Date();
    const diff = end - now;

    if (diff <= 0) {
      setTimeRemaining("00:00");
      setIsButtonDisabled(true);
      stopTimer();
      return;
    }

    const minutes = Math.floor(diff / 1000 / 60);
    const seconds = Math.floor((diff / 1000) % 60);
    setTimeRemaining(
      `${String(minutes).padStart(2, "0")}:${String(seconds).padStart(2, "0")}`
    );
  };

  const startTimer = () => {
    const now = new Date();
    const activePeriod = {
      start: new Date(startTime * 1000 - 7200000),
      end: new Date(startTime * 1000 + 7200000),
    };
    let intervalId;

    if (now >= activePeriod.start && now < activePeriod.end) {
      if (now >= start) {
        calculateTimeRemaining();
        intervalId = setInterval(calculateTimeRemaining, 1000);
      } else {
        setTimeRemaining(
          `The unlock button will be active at ${start.toLocaleTimeString(
            undefined,
            {
              hour: "2-digit",
              minute: "2-digit",
              hour12: true,
            }
          )}`
        );
      }
      setIsActive(true);
      return;
    }

    if (now > activePeriod.end) {
      setTimeRemaining("The link expired");
      setIsActive(false);
      return;
    }

    return () => clearInterval(intervalId);
  };
  const stopTimer = () => {
    if (intervalIdRef.current) {
      clearInterval(intervalIdRef.current);
      intervalIdRef.current = null;
    }
  };

  const handleReload = () => {
    stopTimer();
    startTimer();
  };
  const handleManualAccess = () => {
    setIsManualAccess(true);
  };
  const backToInstruct = () => {
    setIsManualAccess(false);
  };

  useEffect(() => {
    startTimer();
    return () => stopTimer();
  }, [startTime]);

  useEffect(() => {
    let intervalId;

    if (isButtonDisabled && remainingButtonTime > 0) {
      intervalId = setInterval(() => {
        setRemainingButtonTime((prev) => Math.max(0, prev - 1));
      }, 1000);
    }

    return () => {
      if (intervalId) {
        clearInterval(intervalId);
      }
    };
  }, [isButtonDisabled, remainingButtonTime]);

  return (
    <div>
      {!isManualAccess ? (
        <div
          className={`d-flex flex-column px-4 ${style.instructContainer} gap-2`}
        >
          <div>
            <h4 className={style.headerTitle}>
              Everything is ready for your Tour
            </h4>
            <p className={style.headerSubtitle}>Here are the instructions</p>
          </div>
          <CountdownTimer
            timeRemaining={timeRemaining}
            isActive={isActive}
            reload={handleReload}
            showButton={property.doorUnlockLink}
          >
            {isActive && (
              <InstructionsCard
                property={property}
                start={start}
                lockStatus={lockStatus}
                handleLock={handleLock}
                end={end}
                startTime={startTime}
                isButtonDisabled={isButtonDisabled}
                loading={updatingStatus || loadingStatus}
                timeRemaining={timeRemaining}
                passCode={passCode}
              />
            )}
            <div
              className={`d-flex justify-content-end align-items-center px-3 ${style.asSmallText}`}
            >
              <Link
                to={`/tour-property/${[property?._id]}`}
                style={{ textDecoration: "none" }}
              >
                {"See Property Details >"}
              </Link>
            </div>
            {passCode && (
              // <span className="text-uppercase bg-black text-white fw-bold ps-2 py-1 rounded">passCode: {passCode}</span>
              <div className="d-flex flex-column align-items-center py-3">
                <div className="d-flex justify-content-between align-items-end w-100">
                  <span className="fs-7 text-nowrap fw-bold text-secondary">
                    YOUR PASSCODE
                  </span>
                  <p className="fs-8 text-secondary col-6 col-lg text-end lh-sm pt-1">
                    Link and Code active 15min before to 15min after appointment
                  </p>
                </div>
                <hr className="m-0 w-100" />

                <span className={style.passCode}>{passCode}</span>
              </div>
            )}
            {propertyAccessCodes?.enable && (
              <div className="d-flex flex-column justify-content-center align-items-center w-100 gap-3 pb-3">
                <p className="fs-8 d-flex flex-wrap w-100 text-secondary justify-content-between fw-bold">
                  ACCESS INSTRUCTIONS
                  <button
                    className={style.asText}
                    onClick={() => setShowModal(true)}
                  >
                    <u>Read More</u>
                  </button>
                  <hr className="m-0 w-100" />
                </p>

                {propertyAccessCodes.buildingCode && (
                  <p className="fs-8 d-flex w-100 text-secondary justify-content-between">
                    Elevator Access Code
                    <span className="fw-bold">
                      {propertyAccessCodes.buildingCode}
                    </span>
                  </p>
                )}
                {propertyAccessCodes.elevatorCode && (
                  <p className="fs-8 d-flex w-100 text-secondary justify-content-between">
                    Building Access Code
                    <span className="fw-bold">
                      {propertyAccessCodes.elevatorCode}
                    </span>
                  </p>
                )}
              </div>
            )}

            <div className="d-flex flex-column flex-lg-row gap-3">
              {lockStatus !== "Unknown" &&
                doorUnlockLink &&
                timeRemaining !== "00:00" && (
                  <Button
                    className={`${
                      lockStatus === "locked"
                        ? "bg-delet border-0 text-white"
                        : ""
                    } w-100 py-2`}
                    variant={lockStatus === "locked" ? "" : "danger"}
                    onClick={handleLock}
                    disabled={
                      isButtonDisabled || updatingStatus || loadingStatus
                    }
                  >
                    {updatingStatus || loadingStatus ? (
                      <strong>
                        {loadingStatus ? "Loading" : lockStatus === "locked" ? "Unlocking" : "Locking"}...{" "}
                      </strong>
                    ) : (
                      <p>
                        {`${
                          lockStatus === "locked" ? "Unlock" : "Lock"
                        } Door >`}{" "}
                        {isButtonDisabled ? (
                          <span>Wait {remainingButtonTime}s</span>
                        ) : null}
                      </p>
                    )}
                  </Button>
                )}
              <Button
                className="w-100 py-2 bg-opacity-10"
                onClick={handleManualAccess}
                variant="primary"
              >
                {"Manual Access >"}
              </Button>
            </div>
            <div className="d-flex flex-column w-100 gap-3 py-3">
              <p className="fs-8 text-secondary text-center">
                To lock the door, use the keypad near the door and press the red
                button at the bottom. The door will also lock automatically
                after 30minutes
              </p>
              {rentalApplicationForm?.enable && rentalApplicationForm?.url && (
                <Link
                  to={rentalApplicationForm.url}
                  target="_blank"
                  className="text-center bg-delet text-decoration-none text-white rounded-1 py-2 fw-bold"
                >
                  Apply {">"}
                </Link>
              )}
              <p className="fs-8 text-secondary text-center d-flex gap-1 justify-content-center">
                <PiInfoBold size={18} />
                If you're having difficulty entering the unit, you can reach us
                at 310-926-7313
              </p>
            </div>
          </CountdownTimer>

          <Modal
            dialogClassName={`modal-dialog modal-dialog-centered ${style.customModal}`}
            show={showModal}
            onHide={() => setShowModal(false)}
          >
            <Modal.Header closeButton>
              <Modal.Title>Full Access Instructions</Modal.Title>
            </Modal.Header>
            <Modal.Body className="">
              <textarea
                className="border-0 bg-white w-100"
                id="instructions"
                name="instructions"
                value={propertyAccessCodes.instructions}
                disabled
                style={{ minHeight: "300px" }}
              />
            </Modal.Body>
          </Modal>
        </div>
      ) : (
        <div>
          <p className={style.backArrow}>
            <IoIosArrowBack size={20} onClick={backToInstruct} />
          </p>
          <h4 className={style.headerTitle}>Manual Access</h4>
          <p className={style.headerSubtitle}>
            Follow these steps and secure the door
          </p>
          {passCode && (
            // <span className="text-uppercase bg-black text-white fw-bold ps-2 py-1 rounded">passCode: {passCode}</span>
            <div className="d-flex flex-column justify-content-center align-items-center">
              <table>
                <tbody>
                  <tr className={style.rows}>
                    <td className={style.leftTdShow}>
                      <strong>YOUR PASSCODE</strong>
                    </td>
                    <td className={style.rightTdShow}>Available for 30 min</td>
                  </tr>
                </tbody>
              </table>
              <p className={style.passCode}>{passCode}</p>
            </div>
          )}
          <AccessInstructions vendor={lockVendor ?? "switchbot"} />

          <p className={style.helpInfo}>
            <img src={infoIcon} alt="info" />
            If you're having difficulty entering the unit, you can reach us at
            310-926-7313
          </p>
        </div>
      )}
    </div>
  );
}

export default ShowInstructionsCard;
