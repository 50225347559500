import { useState } from "react";
import moment from "moment";
import AssignProperty from "../pages/Kits/components/AssignProperty";
import { RiPencilLine } from "react-icons/ri";
import { design } from "../constants";
import { Link } from "react-router-dom";
import PagePagination from "./PagePagination";

function MobileKitsTable({
  kits,
  setSelectedKits,
  selectedKits,
  setModal,
  closeModal,
}) {
  // const navigate = useNavigate();
  const [selectAll, setSelectAll] = useState(false);

  const [page, setPage] = useState(1);
  const kitsPerPage = 11;

  const startIndex = (page - 1) * kitsPerPage;
  const endIndex = startIndex + kitsPerPage;

  const handleSelectKit = (checked, kit) => {
    setSelectAll(false);

    setSelectedKits((prev) => {
      const newSelection = [...prev];
      if (checked) {
        newSelection.push(kit._id);
      } else {
        const index = prev.indexOf(kit._id);
        if (index > -1) newSelection.splice(index, 1);
      }
      return newSelection;
    });
  };

  const showAssignProperty = (kit) => {
    setModal({
      show: true,
      content: <AssignProperty kit={kit} closeModal={closeModal} />,
      title: "",
    });
  };

  const totalPages = Math.ceil(kits.length / kitsPerPage);

  const currentPageKits = kits.slice(startIndex, endIndex);
  const handlePageChange = (newPage) => {
    setPage(newPage);
  };

  return (
    <div className="d-flex flex-column p-3" style={{ fontFamily: "Poppins" }}>
      {currentPageKits.map((kit, kitIndex) => (
        <div className="border-bottom py-3 ps-2" key={kitIndex}>
          <div className="d-flex justify-content-between">
            <div className="col-2 col-xxl-3 border-bottom-0 d-flex gap-3 text-nowrap">
              <input
                className="form-check-input"
                type="checkbox"
                checked={selectAll || selectedKits.includes(kit._id)}
                id="flexCheckDefault"
                onChange={(e) => handleSelectKit(e.target.checked, kit)}
              />{" "}
              {kit.name}
            </div>

            <Link
              className="btn btn-primary bg-delet border-0 px-4"
              to={`/kits/history/${kit._id}`}
            >
              Kit History
            </Link>
          </div>

          <div>
            <span>Activated on: </span>
            <span>
              {kit.assignedDate
                ? moment(kit.assignedDate).format("MMMM Do, YYYY")
                : "Not active"}
            </span>
          </div>

          {kit.property ? (
            <button
              className="border-0 bg-white d-flex align-items-center justify-content-between w-100 text-start"
              onClick={() => showAssignProperty(kit)}
            >
              <span>
                {`${kit.property.shortAddress}, ${
                  kit.property.unit ? `Unit ${kit.property.unit},` : ""
                } ${kit.property.city} ${kit.property.zipCode}`}
              </span>
              <RiPencilLine
                color={design.deleteMainColor}
                size={25}
                style={{ marginRight: "30px" }}
              />
            </button>
          ) : (
            <button
              className="border-0 bg-white d-flex gap-1 align-items-center text-delet-blue"
              onClick={() => showAssignProperty(kit)}
            >
              <div className="sign-icon">
                <span className="">+</span>
              </div>
              Assign property
            </button>
          )}
        </div>
      ))}

      <div className="py-3">
        <PagePagination
          totalPages={totalPages}
          actualPage={page}
          handlePageChange={handlePageChange}
        />
      </div>
    </div>
  );
}

export default MobileKitsTable;
