import React, { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import style from "../Steps/style.module.scss";
import rescheduleStyle from "../RescheduleSteps/style.module.scss";
import check from "../../../../assests/images/check.svg";
import { StepNumberLabel } from "../Steps/StepNumberLabel";
import Step2 from "../Steps/Step2/Step2";
import { useMutation } from "@tanstack/react-query";
import { createMeeting } from "../../../../api/bookings";
import { AppButton } from "../../../../designSystem/AppButton";
import { PropertyDetail } from "../PropertyDetail";
import { DrawerModalContainer } from "../../../../designSystem/DrawerModalContainer";
import { SelectedPropertyCard } from "../RescheduleSteps/SelectedProperyCard";
import Step3 from "../Steps/step3/Step3";
import Alert from "../../../../components/Alert";

const CircleContentChecked = () => <img src={check} alt="" />;

export const OnlyOnePropertySteps = ({ property }) => {
  const { idVerification } = property;
  const { propertyId, agentId } = useParams();
  const [inputValues, setInputValues] = useState({});
  const [open, setOpen] = useState(false);
  const [step, setStep] = useState(2);
  const [alertModal, setAlertModal] = useState({
    show: false,
    title: "",
    content: "",
  });
  const [errors, setErrors] = useState({
    fullName: "",
    phone: "",
    email: "",
    document: "",
    privacyPolicy: "",
  });
  const onOpen = () => {
    navigate(`/tour-property/${propertyId}`);
  };
  const onClose = () => {
    setOpen(false);
  };

  const navigate = useNavigate();

  const handleInputChange = (e) => {
    setErrors((prev) => ({
      ...prev,
      [e.target.name]: "",
    }));
    setInputValues((prev) => ({
      ...prev,
      [e.target.name]: e.target.value,
    }));
  };

  const onPrev = () => {
    if (step > 2) {
      setStep(step - 1);
    }
    setErrors({
      fullName: "",
      phone: "",
      email: "",
      document: "",
      privacyPolicy: "",
    });
  };
  const onNext = () => {
    if (step === 3) {
      // TODO:
      handleBook();
      return;
    }
    setStep(step + 1);
  };

  const handleNextDisable = () => {
    if (step === 2 && !inputValues.localTimeRequested) {
      return true;
    }

    return false;
  };

  const handleBook = () => {
    const emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;
    const phoneRegex = /^\d{10}$/i;

    setInputValues((prevValues) => {
      const splitNames = prevValues.fullName?.split(" ");
      const holdFirstName = splitNames ? splitNames[0] : "";
      const holdLastName = splitNames ? splitNames[1] : "";

      const updatedValues = {
        ...prevValues,
        firstName: holdFirstName,
        lastName: holdLastName,
        agent: agentId,
      };

      let isValidated = true;

      if (!updatedValues.lastName) {
        setErrors((prev) => ({
          ...prev,
          fullName: "A First and Last Name is required",
        }));
        isValidated = false;
      }

      if (idVerification.active && !updatedValues.verified) {
        setErrors((prev) => ({
          ...prev,
          document: "ID verification was not successful",
        }));
        isValidated = false;
      }

      if (!updatedValues.document) {
        setErrors((prev) => ({
          ...prev,
          document: "An ID is required to schedule the tour",
        }));
        isValidated = false;
      }
      if (
        updatedValues.privacyPolicy === "false" ||
        !updatedValues.privacyPolicy
      ) {
        setErrors((prev) => ({
          ...prev,
          privacyPolicy:
            "Please check the Terms & Conditions in order to proceed",
        }));
        isValidated = false;
      }

      if (!phoneRegex.test(updatedValues.phone)) {
        setErrors((prev) => ({
          ...prev,
          phone: "Incomplete or invalid phone number e.g. 3109137313",
        }));
        isValidated = false;
      }

      if (!emailRegex.test(updatedValues.email)) {
        setErrors((prev) => ({
          ...prev,
          email: "Incomplete or invalid email",
        }));
        isValidated = false;
      }
      if (!isValidated) {
        return prevValues;
      }

      meetingMutation(updatedValues);

      return updatedValues;
    });
  };

  const { mutateAsync: meetingMutation, isPending: isLoadingBooking } =
    useMutation({
      mutationFn: (booking) =>
        createMeeting({
          ...booking,
          propertyId: propertyId ?? property._id,
          agent: agentId,
        }),
      onSuccess: (data) => {
        navigate(`/book/confirmation/${data.booking}`);
      },
    });

  //MARK: Inidividual Booking
  return (
    <div className={`${style.layout} ${rescheduleStyle.layout}`}>
      {step === 2 ? (
        <>
          <h3 className={style.headerTitle}>Choose A Time</h3>
          <p className={style.headerSubtitle}>
            Book a time and day that is most convenient for you
          </p>
        </>
      ) : (
        ""
      )}
      {step === 3 ? (
        <>
          <h3 className={style.headerTitle}>Identity Verification</h3>
          <p className={style.headerSubtitle}>
            Provide an ID, then schedule your self-showing tour
          </p>
        </>
      ) : (
        ""
      )}
      <div className={style.stepLabels}>
        <StepNumberLabel
          className={style.inactive}
          circleContent={<CircleContentChecked />}
          state="completed"
          title="Choose A Property"
        />
        <StepNumberLabel
          circleContent={step === 2 ? "2" : <CircleContentChecked />}
          state={step === 2 ? "selected" : step > 2 ? "completed" : "default"}
          title="Choose Time"
        />
        <StepNumberLabel
          className={style.inactive}
          circleContent={3}
          state={step === 3 ? "selected" : step > 3 ? "completed" : "default"}
          stepNumber={3}
          title="Identity Verifications"
        />
      </div>

      <div className="d-flex flex-column gap-2 mt-5 align-items-center justify-content-center">
        {step === 2 ? (
          <SelectedPropertyCard
            className={`${rescheduleStyle.card}`}
            property={property[0] || property}
            onSeeDetails={onOpen}
          />
        ) : null}

        {step === 2 ? (
          <Step2
            className={style.containerRes}
            currentStep={step}
            propertyId={property[0]?._id || property?._id}
            onChange={handleInputChange}
          />
        ) : step === 3 ? (
          <Step3
            document={inputValues.document}
            documentBack={inputValues.documentBack}
            face={inputValues.face}
            className={style.container}
            currentStep={step}
            onChange={handleInputChange}
            idVerification={idVerification}
            verified={inputValues.verified}
            errors={errors}
            setErrors={setErrors}
          />
        ) : null}

        <div className={`${style.buttons} ${style.container}`}>
          <AppButton
            className={style.button}
            as="button"
            onClick={onPrev}
            disabled={step === 2}
            hierarchy="secondary"
            type="button"
          >
            {"< Go Back"}
          </AppButton>
          <AppButton
            disabled={handleNextDisable() || isLoadingBooking}
            className={style.button}
            as="button"
            type="button"
            onClick={onNext}
          >
            {isLoadingBooking ? (
              <div
                className="spinner-border spinner-border-sm"
                role="status"
              ></div>
            ) : (
              <span className="d-flex align-items-center">
                {step !== 3 ? "Continue >" : "Schedule My Tour"}
              </span>
            )}
          </AppButton>
        </div>
        <DrawerModalContainer onOpen={onOpen} onClose={onClose} isOpen={open}>
          <PropertyDetail property={property[0] || property} />
        </DrawerModalContainer>
      </div>
      {alertModal.show && (
        <Alert
          show={alertModal.show}
          title={alertModal.title}
          content={alertModal.content}
          setShow={() => setAlertModal({ ...alertModal, show: false })}
          navigateTo={null}
        />
      )}
    </div>
  );
};
