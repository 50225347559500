import React from "react";
import { useProperty } from "../../../context/PropertyContext";
import styled from "styled-components";
import { Button, Col, Row } from "react-bootstrap";
import { ChevronRight } from "react-feather";
import removeUndefinedWord from "../../../helper/updateString";
import { useNavigate } from "react-router-dom";

const StyledPageHeader = styled.div`
  z-index: 1;
  top: 0;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  background-color: #eaeaeb;
  margin-bottom: 0;
  padding: 24px 32px 15px 32px;

  .cta-xs {
    font-family: Mulish;
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: 1.5rem;
    letter-spacing: 1%;
    text-decoration-line: underline;
    color: #000000;
  }

  .list-item {
    &:before {
      content: "";
      display: inline-block;
      width: 0.4rem;
      height: 0.4rem;
      background-color: #000;
      border-radius: 50%;
      margin-right: 1rem;
    }
  }
`;

const StyledH2 = styled.h2`
  color: #434449;
  font-family: "Poppins";
  font-size: 28.8px;
  font-weight: 700;
  @media (max-width: 755px) {
    font-size: 20px;
  }
`;

const PropertyFormHeader = (props) => {
  const { property } = useProperty();
  const isEditPage = props?.isEditPage;
  const navigator = useNavigate();
  return (
    <StyledPageHeader className="align-items-center">
      <Row className="">
        <Col>
          <div className="d-flex align-items-center flex-wrap flex-md-nowrap justify-content-start">
            {isEditPage ? (
              <div className="d-flex align-items-center flex-wrap flex-xl-nowrap justify-content-start">
                <div
                  onClick={() => {
                    navigator(
                      isEditPage
                        ? `/property/${property?._id}`
                        : "/property/list"
                    );
                  }}
                  style={{ cursor: "pointer" }}
                >
                  <StyledH2 className={"px-2"} style={{ color: "" }}>
                    {" < "}
                  </StyledH2>
                </div>
                <StyledH2>Edit Property</StyledH2>
                <div className="d-flex align-items-center">
                  <ChevronRight className="mx-2" />
                  <span className="body-bold py-1">
                    {removeUndefinedWord(property?.shortAddress)}
                    {property?.unit &&
                      property?.unit !== "" &&
                      `, Unit ${property.unit}`}
                  </span>
                </div>
              </div>
            ) : (
              <>
                <div
                  onClick={() => {
                    navigator(
                      isEditPage
                        ? `/property/${property?._id}`
                        : "/property/list"
                    );
                  }}
                  style={{ cursor: "pointer" }}
                >
                  <StyledH2 className={"px-2"}>{" < "}</StyledH2>
                </div>
                <StyledH2>Add New Property</StyledH2>
              </>
            )}
          </div>
        </Col>
      </Row>
    </StyledPageHeader>
  );
};

export default PropertyFormHeader;
