import React, { FormEvent, useState } from 'react';
import Step1 from './Step1/Step1';
import Step2 from './Step2/Step2';
import Step3 from './step3/Step3';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { useMutation, useQuery } from '@tanstack/react-query';
import {
  getActiveProperties,
  getBuilding,
  getPublicProperty,
} from '../../../../api/properties';
import { AppButton } from '../../../../designSystem/AppButton';
import { StepNumberLabel } from './StepNumberLabel';
import style from './style.module.scss';
import check from '../../../../assests/images/check.svg';
import backArrow from '../../../../assests/images/PrevArrow.svg';
import nextArrow from '../../../../assests/images/NextArrow.svg';
import { ReactSVG } from 'react-svg';
import Alert from '../../../../components/Alert';

import useGaTracker from '../../../../hooks/useGaTracker';
import { createMeeting } from '../../../../api/bookings';
import { Card } from '../../../../designSystem/Card';

import { OnlyOnePropertySteps } from '../OnlyOnePropertySteps';

type bookingProps = {
  agent: string;
  propertyId: string;
  localTimeRequested: string;
  firstName: string;
  lastName: string;
  fullName: string;
  phone: string;
  email: string;
  privacyPolicy?: 'true' | 'false';
  idVerification?: boolean;
  document: File;
  documentBack?: File;
  face?: File;
  verified?: boolean;
}

function StepsContainer() {
  useGaTracker();
  const [searchParams] = useSearchParams();
  const { propertyId, agentId } = useParams();
  const [inputValues, setInputValues] = useState<Partial<bookingProps>>({
    agent: agentId,
  });


  const [alertModal, setAlertModal] = useState({
    show: false,
    title: '',
    content: '',
  });


  const navigate = useNavigate();
  const searchProperties = searchParams.get('properties');
  const [checked, setChecked] = useState(false);
  const [step, setStep] = useState(1);
  const [errors, setErrors] = useState({
    fullName: '',
    phone: '',
    email: '',
    document: '',
    privacyPolicy: '',
  })

  const {
    data: properties,
    isLoading: loadingProperties,
    isError: propertyError,
  } = useQuery({
    queryKey: propertyId
      ? ['property', propertyId]
      : ['properties', agentId],
    queryFn: async () => {
      const res = propertyId
        ? await getPublicProperty(propertyId)
        : await getActiveProperties(agentId, searchProperties);
      return res;
    },
  });

  const selectedProperty = !Array.isArray(properties)
    ? properties
    : properties.length === 1
      ? properties[0]
      : properties.find(property => property._id === inputValues?.propertyId) || null;

  const onNext = async () => {
    if (step === 3) {
      handleBook();
      return;
    }
    setStep(step + 1);
  };
  const handleBook = () => {
    const emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;
    const phoneRegex = /^\d{10}$/i;
    setInputValues(prevValues => {
      const splitNames: string[] | undefined = prevValues.fullName?.split(' ');
      const holdFirstName: string = splitNames ? splitNames[0] : '';
      const holdLastName: string | undefined = splitNames ? splitNames[1] : '';

      const updatedValues = {
        ...prevValues,
        firstName: holdFirstName,
        lastName: holdLastName,
        agent: agentId,
      };

      let isValidated = true;

      if (!updatedValues.lastName) {
        setErrors(prev => ({
          ...prev,
          fullName: 'A First and Last Name is required'
        }));
        isValidated = false
      }

      if (!updatedValues.document) {
        setErrors(prev => ({
          ...prev,
          document: 'An ID is required to schedule the tour'
        }));
        isValidated = false
      }
      if (updatedValues.privacyPolicy === 'false' || !updatedValues.privacyPolicy) {
        setErrors(prev => ({
          ...prev,
          privacyPolicy: 'Please check the Terms & Conditions in order to proceed'
        }));
        isValidated = false
      }

      if (!phoneRegex.test(updatedValues.phone as string)) {
        setErrors(prev => ({
          ...prev,
          phone: 'Incomplete or invalid phone number e.g. 3109137313'
        }));
        isValidated = false
      }

      if (!emailRegex.test(updatedValues.email as string)) {
        setErrors(prev => ({
          ...prev,
          email: 'Incomplete or invalid email'
        }));
        isValidated = false
      }
      if (!isValidated) {
        return prevValues
      }

      meetingMutation(updatedValues);

      return updatedValues;
    });
  };

  const { mutateAsync: meetingMutation, isPending: isLoadingBooking } = useMutation({
    mutationFn: (booking: Partial<bookingProps>) => createMeeting({ ...booking }),
    onSuccess: (booking: any) => {
      navigate(`/book/confirmation/${booking.booking}`);
    },
  });

  const onPrev = () => {
    setStep(step - 1);
    setErrors({
      fullName: '',
      phone: '',
      email: '',
      document: '',
      privacyPolicy: '',
    })
  };
  const handleSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
  };
  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value, checked, type } = event.target;
    setInputValues(prev => ({
      ...prev,
      [name]: type === 'checkbox' ? (checked ? value : '') : value,
    }));
    if (type === 'checkbox') {
      setChecked(checked);

    }
    setErrors((prev) => ({
      ...prev,
      [name]: "",
    }))

  };

  const defineCircleContent = (labelStep: number) => {
    if (labelStep < step) {
      return (
        <img
          src={check}
          alt=''
        />
      );
    } else {
      return String(labelStep);
    }
  };

  const handleNextDisable = () => {
    if (!inputValues) return true
    if (step === 2 && !inputValues.localTimeRequested) {
      return true;

    }
    if (step === 1 && !inputValues.propertyId) {
      return true;
    }
    return false;
  };

  //MARK: Whole Booking
  return loadingProperties ? (
    <div className='d-flex flex-column align-items-center justify-content-center' style={{ marginTop: '250px' }}>
      <div className='d-flex align-items-center justify-content-center'>
        <div className='spinner-border m-5' style={{ width: '3rem', height: '3rem', color: '#5271ff' }} role='status'></div>
      </div>
      <header className={style.header}>
        <h1 className={`text-center ${style.heading}`}>
          We are retrieving all the information.
        </h1>
        <Card.Title className={style.cardTitle}>
          Please hold on a moment.
        </Card.Title>
      </header>
    </div>
  ) : propertyError ? (
    <div>Error getting properties</div>
  ) : !Array.isArray(properties) ? (
    <OnlyOnePropertySteps property={properties} />
  ) : properties.length === 1 ? (
    <OnlyOnePropertySteps property={properties[0]} />
  ) : (
    <div className={style.layout}>
      {step === 1 ?
        <>
          <h3 className={style.headerTitle}>Choose A Property</h3>
          <p className={style.headerSubtitle}>Start by choosing a property you'd like to see in person</p>
        </>
        : ''}
      {step === 2 ?
        <>
          <h3 className={style.headerTitle}>Choose A Time</h3>
          <p className={style.headerSubtitle}>Book a time and day that is most convenient for you</p>
        </>
        : ''}
      {step === 3 ?
        <>
          <h3 className={style.headerTitle}>Identity Verification</h3>
          <p className={style.headerSubtitle}>Provide an ID, then schedule your self-showing tour</p>
        </>
        : ''}

      <div className={`${style.stepLabels}`}>
        <StepNumberLabel
          className={`${step !== 1 && style.inactive} `}
          circleContent={defineCircleContent(1)}
          state={step === 1 ? 'selected' : step > 1 ? 'completed' : 'default'}
          stepNumber={1}
        />
        <StepNumberLabel
          className={`${step !== 2 && style.inactive} `}
          circleContent={defineCircleContent(2)}
          state={step === 2 ? 'selected' : step > 2 ? 'completed' : 'default'}
          stepNumber={2}
        />
        <StepNumberLabel
          className={`${step !== 3 && style.inactive} `}
          circleContent={defineCircleContent(3)}
          state={step === 3 ? 'selected' : step > 3 ? 'completed' : 'default'}
          stepNumber={3}
        />
      </div>
      <form onSubmit={handleSubmit} >
        {step === 1 ? (
          <Step1
            selectedId={inputValues?.propertyId}
            currentStep={step}
            properties={properties}
            onChange={handleInputChange}
            checked={checked}
          />
        ) : step === 2 ? (
          <Step2
            className={style.container}
            currentStep={step}
            propertyId={inputValues.propertyId}
            onChange={handleInputChange} agent={undefined} />
        ) : (
          <Step3
            document={inputValues.document}
            documentBack={inputValues.documentBack}
            face={inputValues.face}
            className={style.container}
            currentStep={step}
            onChange={handleInputChange}
            idVerification={selectedProperty.idVerification}
            verified={inputValues.verified}
            errors={errors}
            setErrors={setErrors}
          />
        )}

        <div className={`${style.buttons} ${style.container}`}>
          <AppButton
            className={style.button}
            as='button'
            onClick={onPrev}
            disabled={step === 1}
            hierarchy='secondary'
            type='button'
          >
            {'< Go Back'}
          </AppButton>
          <AppButton
            disabled={handleNextDisable() || isLoadingBooking}
            className={style.button}
            as='button'
            type='button'
            onClick={onNext}
          >
            {isLoadingBooking ? <div className="spinner-border spinner-border-sm" role="status"></div> : <span className='d-flex text-center align-items-center'>
              {step !== 3 ? 'Continue >' : 'Schedule My Tour'}
            </span>}

          </AppButton>
        </div>
      </form>
      {alertModal.show && (
        <Alert
          show={alertModal.show}
          title={alertModal.title}
          content={alertModal.content}
          setShow={() => setAlertModal({ ...alertModal, show: false })}
          navigateTo={null}
        />
      )}
    </div>
  );
}

export default StepsContainer;
