import { Box } from "@mui/material";
import React from "react";
import useViewport from "../../../hooks/useViewport";
import { Card, FormTitle } from "../PropertyFormTab";
import AutocompleteInput from "../../../components/AutocompleteInput";
import InputTextComponent from "../../../components/InputTextComponent";
import AutocompleteSelect from "../../../components/AutocompleteSelect";
import {
  category,
  LeaseTermOptions,
  propertyType,
  states,
} from "../../../static/Data";
import LableComponent from "../../../components/Lable";
import InputSwitchComponent from "../../../components/InputSwitchComponent";
import InputRadio from "../../../components/InputRadio";
import InputDateComponent from "../../../components/InputDateComponent";
import InputCheckboxArray from "../../../components/InputCheckboxArray";
import InputImages from "../../../components/InputImages";
import PropertyFormPanelHeader from "./PropertyFormPanelHeader";
import PlaceAutocomplete from "../../Kits/components/Maps/PlaceAutocomplete";

const DetailsTabPanel = (props) => {
  const {
    children,
    register,
    setValue,
    errors,
    trigger,
    watch,
    isEditPage,
    value,
    index,
    saveFormData,
    ...other
  } = props;
  const { width: screenWidth } = useViewport();
  const handleAutocomplete = (place) => {
    setValue("state", "");
    setValue("city", "");
    setValue("zipCode", "");

    if (place && place.address_components) {
      setValue("shortAddress", place.name, {
        shouldValidate: true,
        shouldDirty: true,
      });
      place.address_components.forEach((component) => {
        const types = component.types;

        if (types.includes("administrative_area_level_1")) {
          setValue("state", component.short_name, {
            shouldValidate: true,
            shouldDirty: true,
          });
        }
        if (types.includes("locality")) {
          setValue("city", component.long_name, {
            shouldValidate: true,
            shouldDirty: true,
          });
        }
        if (types.includes("postal_code")) {
          setValue("zipCode", component.long_name, {
            shouldValidate: true,
            shouldDirty: true,
          });
        }
      });
      if (place.geometry && place.geometry.location) {
        setValue("googleMap.latitude", place.geometry.location.lat(), {
          shouldValidate: true,
          shouldDirty: true,
        });
        setValue("googleMap.longitude", place.geometry.location.lng(), {
          shouldValidate: true,
          shouldDirty: true,
        });
      }
    }
  };
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={screenWidth > 755 ? { p: 3 } : { p: 1 }}>
          <div>
            <div xl={12} style={{ border: "1px solid rgb(234, 234, 235)" }}>
              <PropertyFormPanelHeader
                saveFormData={saveFormData}
                title={"PROPERTY DETAILS"}
                description={
                  "This information will be visible to prospects on the Prospect Booking page."
                }
              />
              <div
                className={`${
                  screenWidth > 755 ? "px-4" : ""
                } flex-column d-flex`}
              >
                <Card id="location">
                  <FormTitle>LOCATION</FormTitle>
                  <PlaceAutocomplete
                    placeHolder="Street Address"
                    onPlaceSelect={handleAutocomplete}
                    name="shortAddress"
                    register={register}
                    trigger={trigger}
                    error={errors}
                    setValue={setValue}
                  />

                  <InputTextComponent
                    labelType="secondary"
                    label="Apt / Suite # (Optional)"
                    name="unit"
                    register={register}
                    trigger={trigger}
                    setValue={setValue}
                    error={errors}
                    placeholder="(i.e. 102, 5B, etc.)"
                  />
                  <AutocompleteInput
                    label="City"
                    name="city"
                    required={true}
                    register={register}
                    trigger={trigger}
                    setValue={setValue}
                    errorMessage={errors}
                    placeholder="(i.e. Atlanta)"
                  />
                  <AutocompleteSelect
                    required={true}
                    register={register}
                    trigger={trigger}
                    setValue={setValue}
                    label="State"
                    name="state"
                    placeholder="Select"
                    selectedOption="Select"
                    options={states.map((state) => state.abbreviated)}
                    error={errors}
                  />
                  <AutocompleteInput
                    required={true}
                    register={register}
                    trigger={trigger}
                    setValue={setValue}
                    requiredField={{
                      required: "This field is required",
                      pattern: {
                        value: /^\d{5}$/,
                        message: "Please enter exactly 5 digits",
                      },
                    }}
                    label="ZIP Code"
                    name="zipCode"
                    errorMessage={errors}
                    placeholder="5-digit postal code"
                  />
                </Card>
                <Card id="listingDetails">
                  <FormTitle>LISTING DETAILS</FormTitle>
                  <LableComponent
                    variantType="primary"
                    label="Select property type:"
                    name="classification"
                  />
                  <InputRadio
                    name="classification"
                    register={register}
                    error={errors}
                    options={propertyType.map((item) => ({
                      name: item.label,
                      value: item.value,
                    }))}
                    registerFields={{ required: true }}
                  />
                  <InputRadio
                    label="Is this property available for rent or for sale?"
                    name="category"
                    register={register}
                    error={errors}
                    options={category}
                    disabled={watch("classification") === ""}
                    registerFields={{ required: true }}
                  />
                  {isEditPage || watch("category") === "rent" ? (
                    <>
                      <InputTextComponent
                        textAtBeginning="$"
                        labelType="primary"
                        label="Monthly Rent ($)"
                        name="price"
                        type="number"
                        trigger={trigger}
                        setValue={setValue}
                        register={register}
                        error={errors}
                        placeholder=""
                      />
                      <InputTextComponent
                        textAtBeginning="$"
                        labelType="primary"
                        label="Security Deposit ($)"
                        name="deposit"
                        type="number"
                        trigger={trigger}
                        setValue={setValue}
                        register={register}
                        error={errors}
                        placeholder=""
                      />
                      <InputDateComponent
                        labelType="primary"
                        label="Available Move-In Date"
                        name="dateAvailableTs"
                        register={register}
                        error={errors}
                        placeholder=""
                      />
                      <LableComponent
                        variantType="primary"
                        label="Lease Term Options"
                        name="LeaseTermOptions"
                      />

                      <InputCheckboxArray
                        name="leaseTermOptions"
                        register={register}
                        setValue={setValue}
                        watch={watch}
                        options={LeaseTermOptions.map((option) => ({
                          name: option.value,
                          value: option.value,
                        }))}
                      />
                    </>
                  ) : (
                    <></>
                  )}
                </Card>
                <Card id="propertyImages">
                  <FormTitle>PROPERTY IMAGES</FormTitle>
                  <InputImages
                    name="images"
                    register={register}
                    setValue={setValue}
                    watch={watch}
                    description="Upload images of your property"
                    //   files={propertyImages}
                    //   onFilesChange={(files) => setPropertyImages(files)}
                  />
                </Card>
              </div>
            </div>
          </div>
        </Box>
      )}
    </div>
  );
};

export default DetailsTabPanel;
