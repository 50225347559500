import { Auth } from "aws-amplify";
import axios from "axios";
import React, { useState } from "react";
import { Button, Col, Form, Modal, Table } from "react-bootstrap";
import { Link, useParams } from "react-router-dom";
import styled from "styled-components";
import { useGeneral } from "../context/GeneralContext";
import { useProperty } from "../context/PropertyContext";
import getImageUrl from "../helper/checkImageFormat";
import moment from "moment";

import { FaUser } from "react-icons/fa";
import { MdLocalPhone } from "react-icons/md";
import { IoMail } from "react-icons/io5";
import ButtonDropdown from "./Buttons/ButtonDropdown";
import { CiExport } from "react-icons/ci";
import UnstyledButton from "./UnstyledButton";
import { BsSliders2 } from "react-icons/bs";
import ExportModal from "./Modals/ExportModal";
import { DateRangeSelect } from "./Selects/DateRangeSelect";
import { FiltersModal } from "../pages/Leads/components/FiltersModal";
import { CloseButton, rem, TextInput } from "@mantine/core";
import { IoMdSearch } from "react-icons/io";

const Container = styled.div`
  width: 100%;
  table {
    tbody {
      tr {
        td,
        td:first-child {
          text-align: left;
          padding-left: 20px;

          a {
            color: #1f2327;
            text-decoration: none;

            &[href^="mailto:"] {
              color: #5271ff;
            }
          }

          .future {
            background-color: #dbffcc !important;
          }

          .past {
            background-color: #fff8cc !important;
          }

          .custom-dropdown {
            background-color: rgba(146, 147, 153, 0.3);
            font-size: 11px;
            letter-spacing: 0.8px;
            line-height: 15px;
            color: #2d2f36;
            border-radius: 3px;
            padding: 2px 28px 2px 7px;
            display: inline-block;
            text-transform: uppercase;
            background-image: url("../../assests/images/downarrow.png");
            background-repeat: no-repeat;
            background-position: calc(100% - 10px) center;
            cursor: pointer;

            &:after {
              content: "";
              height: 10px;
              position: absolute;
              bottom: -8px;
              width: 100%;
              left: 0;
            }

            ul {
              min-width: 190px;
              background-color: #fff;
              border-radius: 3px;
              padding: 8px;
              position: absolute;
              right: 0;
              top: 28px;
              margin-bottom: 0;
              display: none;
              padding-left: 10px;
              list-style: none;
              z-index: 1;

              li {
                text-transform: uppercase;
                font-size: 11px;
                letter-spacing: 0.8px;
                line-height: 15px;
                color: #2d2f36;
                margin-bottom: 10px;
                cursor: pointer;
                text-align: left;
                display: flex;

                & .w-square {
                  padding-left: 36px;

                  &:before {
                    content: "";
                    height: 20px;
                    width: 20px;
                    position: relative;
                    display: inline-block;
                    vertical-align: middle;
                    margin-left: -35px;
                    margin-right: 8px;
                    border-radius: 3px;
                  }

                  & .interested {
                    &:before {
                      background-color: #52ff58;
                      opacity: 0.3;
                    }
                  }

                  & .no-interest {
                    &:before {
                      background-color: #ff7277;
                      opacity: 0.3;
                    }
                  }

                  & .never-arrived {
                    &:before {
                      background-color: #e9d62a;
                      opacity: 0.3;
                    }
                  }

                  & .sent-application {
                    &:before {
                      background-color: #5271ff;
                      opacity: 0.3;
                    }
                  }

                  & .rescheduled {
                    &:before {
                      background-color: #929399;
                      opacity: 0.3;
                    }
                  }

                  & .leave-this {
                    &:before {
                      background-color: rgba(146, 147, 153, 0.3);
                      content: "x";
                      color: #858588;
                      text-align: center;
                      line-height: 20px;
                    }
                  }
                }

                button {
                  background-color: rgba(82, 113, 255, 0.3);
                  font-size: 11px;
                  letter-spacing: 0.8px;
                  line-height: 15px;
                  border-radius: 3px;
                  border: none;
                  text-transform: uppercase;
                  color: #2d2f36;
                  width: 100%;
                  text-align: center;
                }
              }
            }

            &:hover {
              ul {
                display: block;
              }
            }
          }

          & .notes-btn {
            letter-spacing: 0.8px;
            line-height: 15px;
            display: block;
            text-transform: uppercase;
            position: absolute;
            bottom: 10px;
            left: 180px;
            background-color: transparent;
            padding: 0;
            border: none;
          }
        }

        td:last-child {
          text-align: right;
        }
      }
    }
  }
`;

const MobileLeadList = () => {
  const [outcomeId, setoutcomeId] = useState();
  const [outcomevalue, setoutcomeValue] = useState("");
  const [shownotes, setShownotes] = useState(false);
  const { leads } = useProperty();
  const [isLoading, setIsLoading] = useState(false);
  const [showFiltersModal, setShowFiltersModal] = useState(false);
  const [searchParam, setSearchParam] = useState("");
  const [page, setPage] = useState(1);
  const [search, setSearch] = useState(true);
  const [listOrder, setListOrder] = useState("desc");
  const [filters, setFilters] = useState({
    startDate: null,
    endDate: null,
    selectedProperties: null,
    search: "",
  });
  //Export
  const [show, setShow] = useState(false);
  const [exportStartDate, setExportStartDate] = useState("");
  const [exportEndDate, setExportEndDate] = useState("");
  const [exportProperties, setExportProperties] = useState([]);
  const [isGeneratingCsv, setIsGeneratingCsv] = useState(false);
  const [propertiesData, setPropertiesData] = useState([]);

  const handleClose = () => setShow(false);

  const handleExportDateRangeChange = (value) => {
    setExportStartDate(value[0]);
    setExportEndDate(value[1]);
  };

  const params = useParams();

  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const { setAlertModal, timezone } = useGeneral();

  const handleExport = (e) => {
    e.preventDefault();
    setIsGeneratingCsv(true);
    Auth.currentSession().then((token) => {
      let url = `${process.env.REACT_APP_NODE_API_URL}/api/contact/toCsv?`;

      if (exportProperties.length) {
        url = `${url}&propertyIds=${exportProperties
          .map((property) => property._id)
          .join()}`;
      }

      if (exportStartDate) {
        url = `${url}&startDate=${moment(exportStartDate).format(
          "YYYY-MM-DD"
        )}`;
      }
      if (exportEndDate) {
        url = `${url}&endDate=${moment(exportEndDate).format("YYYY-MM-DD")}`;
      }

      axios
        .get(url, {
          headers: {
            Authorization: `Bearer ${token.getIdToken().getJwtToken()}`,
          },
        })
        .then((response) => {
          if (response.status === 200) {
            var blob = new Blob([response.data], {
              type: "text/csv",
            });

            const a = document.createElement("a");
            a.download = "leads";
            a.href = window.URL.createObjectURL(blob);
            const clickEvt = new MouseEvent("click", {
              view: window,
              bubbles: true,
              cancelable: true,
            });
            a.dispatchEvent(clickEvt);
            a.remove();
          }

          setExportStartDate("");
          setExportEndDate("");
          setExportProperties([]);
          handleClose();
        })
        .catch((error) => {
          console.log(error);
          handleClose();

          setAlertModal({
            show: true,
            title: "Error",
            content: "There's no data to export",
          });
        })
        .finally(() => setIsGeneratingCsv(false));
    });
  };

  const handleCloseFiltersModal = () => setShowFiltersModal(false);

  const handleApplyFilters = ({ selectedProperties, startDate, endDate }) => {
    const newFilters = {};
    if (startDate)
      newFilters.startDate = moment(startDate).format("YYYY-MM-DD");
    if (endDate) newFilters.endDate = moment(endDate).format("YYYY-MM-DD");
    if (selectedProperties)
      newFilters.selectedProperties = selectedProperties
        ?.map((property) => property._id)
        .join();
    newFilters.search = "";

    setFilters(newFilters);
    setShowFiltersModal(false);
    setPage(1);
  };

  const handleResetFilters = () => {
    setFilters({
      startDate: null,
      endDate: null,
      selectedProperties: null,
      search: "",
    });
    setSearchParam("");
  };

  const handleSearchTerm = (e) => {
    if (e.target.value === "") setFilters({ ...filters, search: "" });
    setSearchParam(e.target.value);
  };

  const handleKeyDown = (e) => {
    const { key } = e;
    if (key === "Enter") {
      e.preventDefault();
      setFilters({ ...filters, search: searchParam });
      setPage(1);
    }
  };

  const handleReset = () => {
    setPage(1);
    setSearchParam("");
    setFilters({
      ...filters,
      search: "",
    });
  };

  const filteredLeads = leads.filter((lead) => {
    const firstName = lead.contact?.firstName || "";
    const lastName = lead.contact?.lastName || "";
    const email = lead.contact?.email || "";
    const leadDate = moment(lead.createdAt ?? lead.createdDate).startOf("day");

    const isWithinDateRange =
      (!filters.startDate ||
        leadDate.isSameOrAfter(moment(filters.startDate))) &&
      (!filters.endDate || leadDate.isSameOrBefore(moment(filters.endDate)));

    return (
      (firstName.toLowerCase().includes(filters.search.toLowerCase()) ||
        lastName.toLowerCase().includes(filters.search.toLowerCase()) ||
        email.toLowerCase().includes(filters.search.toLowerCase())) &&
      isWithinDateRange
    );
  });

  const handleSearch = () => {
    setPage(1);
    setSearch(!search);
  };

  return (
    <>
      <Container>
        <div className="d-flex mt-4">
          <TextInput
            placeholder="Search..."
            className="flex-grow-1 w-75"
            style={{ borderRadius: "20px" }}
            name="search"
            onKeyDown={handleKeyDown}
            leftSection={
              <IoMdSearch
                style={{ width: rem(16), height: rem(16), cursor: "pointer" }}
                stroke={1.5}
                onClick={handleSearch}
              />
            }
            value={searchParam}
            onChange={handleSearchTerm}
            rightSection={
              searchParam !== "" ? <CloseButton onClick={handleReset} /> : ""
            }
          />
          <div className="d-flex flex-row justify-content-end button-containers">
            <div className="mr-10 d-flex flex-row">
              <UnstyledButton
                variant="outline-dark"
                onClick={handleResetFilters}
                className="clear-buttons"
              >
                <span style={{ color: "#5271FF" }}>Clear All</span>
              </UnstyledButton>
              <Button
                variant="primary"
                onClick={() => setShowFiltersModal(!showFiltersModal)}
                className="filter-button-mobile"
              >
                <BsSliders2 size="14px" className="mr-4" />
              </Button>
              <Button
                onClick={(e) => handleExport(e)}
                className="filter-button-mobile"
              >
                <CiExport size="14px" className="mr-4" />
              </Button>
            </div>
          </div>
        </div>
        {showFiltersModal && (
          <FiltersModal
            show={showFiltersModal}
            handleClose={handleCloseFiltersModal}
            handleApplyFilters={handleApplyFilters}
          />
        )}
        <div className="total-contacts">
          {isLoading ? (
            <div>Getting contacts...</div>
          ) : filteredLeads?.length > 0 ? (
            <></>
          ) : (
            <p>No Records Found</p>
          )}
        </div>
        <Table responsive className="property-table">
          <tbody>
            {filteredLeads?.length > 0 &&
              filteredLeads
                ?.sort((a, b) => b.createdDate - a.createdDate)
                .map((element, index) => (
                  <tr key={index}>
                    <td>
                      <Link
                        // to="/leads-details"
                        to={`/leads-details/${element.contact._id}`} //TODO: check
                        state={{ user_id: element.contact._id }}
                        className=""
                      >
                        <div className="d-flex flex-column justify-content-start align-items-start">
                          <p className="leads-contact d-flex flex-row gap-2 align-items-center">
                            <FaUser />
                            {element.contact.firstName
                              ? element.contact.firstName
                              : ""}{" "}
                            {element.contact.lastName
                              ? element.contact.lastName
                              : ""}
                          </p>
                          <p className=" leads-contact mt-1 properties-details-link d-flex flex-row gap-2 align-items-center">
                            <MdLocalPhone />
                            <Link
                              to={`mailto:${
                                element.contact.phone
                                  ? element.contact.phone
                                  : ""
                              }`}
                              className=""
                              style={{ color: "black" }}
                            >
                              {element.contact.phone
                                ? element.contact.phone
                                : ""}
                            </Link>
                          </p>
                          <p className=" leads-contact mt-1 properties-details-link d-flex flex-row gap-2 align-items-center">
                            <IoMail />
                            <Link
                              to={`mailto:${
                                element.contact.email
                                  ? element.contact.email
                                  : ""
                              }`}
                              style={{ color: "black" }}
                            >
                              {element.contact.email
                                ? element.contact.email
                                : ""}
                            </Link>
                          </p>
                        </div>
                      </Link>

                      <div
                        style={{ width: "100%" }}
                        className="d-flex flex-row justify-content-between align-items-center gap-2 "
                      >
                          <div className="d-flex align-items-start ">
                            <div className="my-2">
                              <div className="d-flex gap-1 flex-column align-items-start latest-showing">
                                latest showing
                                <br />
                                <p className="date-time">
                                  {moment(element.startTime * 1000).format(
                                    "MMM  DD, yyyy hh:mm a"
                                  )}
                                </p>
                              </div>
                            </div>
                          </div>
                        <div className=" added-info ">
                          added on
                          <br />
                          {moment(
                            element.createdAt ?? element.createdDate
                          ).format("MMM d, YYYY hh:mm a")}
                        </div>
                      </div>
                    </td>
                  </tr>
                ))}
          </tbody>
        </Table>
      </Container>
      <ExportModal
        show={show}
        handleClose={handleClose}
        handleExport={handleExport}
        filters={[
          <Col>
            <Form.Label htmlFor="dateRangeSelect">Date</Form.Label>
            <DateRangeSelect
              startDate={startDate}
              endDate={endDate}
              handleDateRangeChange={handleExportDateRangeChange}
            />
          </Col>,
        ]}
      />
      <Modal
        centered
        show={shownotes}
        onHide={() => setShownotes(false)}
        animation={true}
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
        className="notes-modal"
      >
        <Modal.Header closeButton className="border-0">
          <Modal.Title>Showing notes</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="d-flex">
            <div className="notes-img me-2">
              <img
                src={require("../assests/images/wepik-202216-7056.png")}
                alt="properties"
              />
            </div>
            <div className="notes-address">
              {/* <h6> {data.contacts[0].firstName}  {data.contacts[0].lastName}</h6> */}

              <p>1249 W 39th Pl, unit 310, Los Angeles, CA, USA</p>
            </div>
          </div>
          <p>
            <em>
              She is interested in this apartment, with the conditions that the
              living room couch would be changed to a much bigger one and new
              drapes would be installed in the bedroom.
            </em>
          </p>
        </Modal.Body>
        <Modal.Footer className="border-0 d-flex justify-content-end">
          {/* <Button className="btn btn-perple ml-auto">Edit notes</Button> */}
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default MobileLeadList;
