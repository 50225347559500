import React from "react";
import styled from "styled-components";

export const StyledTextarea = styled.textarea({
  color: "#000000",
  fontFamily: "Mulish",
  fontSize: "18px",
  fontWeight: "400",
  lineHeight: "140%",
  letterSpacing: "0.16px",
  border: "1px solid #BDBEBF",
  borderRadius: "4px",
  width: "100%",
  boxSizing: "border-box",
  "&:focus": {
    border: "1px solid #5D5FEF",
    boxShadow: "0px 0px 4px rgba(93, 95, 239, 0.25)",
  },
  // disabled styles
  "&:disabled": {
    backgroundColor: "#EAEAEB",
    border: "1px solid #EAEAEB",
  },
  // disabled placeholder
  "&:disabled::placeholder": {
    color: "#BDBEBF",
  },
});

const StyledLabel = styled.label`
  color: #000;
  font-family: Mulish;
  font-size: 16px;
  font-weight: ${(props) =>
    props.LabeltType === "primary"
      ? "700"
      : props.LabeltType === "secondary"
      ? "400"
      : "700"};
  line-height: 140%;
  letter-spacing: 0.16px;

  /* disabled styles */
  ${(props) =>
    props.disabled &&
    ` color: #bdbfbf;
    `}
`;

const InputContainer = styled.div`
  /* Add styles for the container that holds the input and text */
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const InputTextAreaComponent = ({ disabled = false, register, ...props }) => {
  return (
    <InputContainer>
      <StyledLabel
        LabeltType={props?.LabeltType}
        className="pb-1"
        htmlFor={props?.name}
        disabled={disabled}
      >
        {props?.label}
      </StyledLabel>
      <StyledTextarea
        rows="4"
        className="form-control"
        id={props?.name}
        name={props?.name}
        {...register(props?.name)}
        placeholder={props?.placeholder}
        disabled={disabled}
        {...props}
      />
      {props?.error && <p className="error">{props?.error}</p>}
    </InputContainer>
  );
};

export default InputTextAreaComponent;
