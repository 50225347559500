import React from "react";
import { propertyStatus } from "../../../static/Data";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { patchProperty } from "../../../api/properties";
import StyledSelect, {StyledDiv} from "../../../components/Status";

const PropertyStatusSelect = ({ propertyId, status, queryKey }) => {
  const queryClient = useQueryClient();
  const { mutateAsync: handleChangeStatus, isPending } = useMutation({
    mutationFn: ({ propertyId, updatedFields }) =>
      patchProperty(propertyId, updatedFields),
    onSuccess: (updatedProperty) => {
      queryClient.setQueryData(queryKey, (prev) => {
        const newObjRes = { ...prev };
        const newPropertiesArray = [...newObjRes.properties];
        const index = newPropertiesArray.findIndex(
          (property) => property._id === updatedProperty._id
        );
        newPropertiesArray[index] = {
          ...newPropertiesArray[index],
          ...updatedProperty,
        };
        newObjRes.properties = newPropertiesArray;
        return newObjRes;
      });
    },
  });
  return (
    <>
      {status !== "archived" ? (
        <StyledSelect
          status={status}
          values={[]}
          onChange={(e) =>
            handleChangeStatus({
              propertyId: propertyId,
              updatedFields: { status: e[0].status },
            })
          }
          placeholder={isPending ? "..." : status}
          options={propertyStatus}
          size="sm"
          className="status-td"
          style={{ pointerEvents: isPending ? "none" : "auto" }}
          disabled={isPending}
        />
      ) : (
        <StyledDiv>Archived</StyledDiv>
      )}
    </>
  );
};

export default PropertyStatusSelect;
