export const design = {
  deleteMainColor: "#5271FF"
}

export const keypadInstructions = {
  switchbot: {
    step2_text: "Enter the code on the keypad and press the Check Button. Wait for a beep, then open the door.",
    keypad: '/images/switchbot/keypad.png',
    kpOpen: '/images/switchbot/kp-open.png',
    kpClose: '/images/switchbot/kp-lock.png'
  },
  yale: {
    step2_text: "Press the Yale button on top of the keypad, enter the code and press the Check button. Wait for a beep, then open the door.",
    keypad: '/images/yale/keypad.png',
    kpOpen: '/images/yale/kp-lock.png',
    kpClose: '/images/yale/kp-open.svg'
  },
  august: {
    step2_text: "Enter the code on the keypad and press the August button.",
    keypad: '/images/august/keypad.png',
    kpOpen: '/images/august/kp-lock.png',
    kpClose: '/images/august/kp-lock.png'
  }
};

export const MAX_NUMBER_CODES = 90
