import React, { useEffect } from 'react';
import {
  AdvancedMarker,
  Map,
  useMap,
  useAdvancedMarkerRef,
} from '@vis.gl/react-google-maps';
import FieldError from '../../../../components/Banners/FieldError';
import PlaceAutocomplete from './PlaceAutocomplete';

const MapWithInput = ({ selectedPlace, handleSelectPlace, incorrectAddress }: { selectedPlace: google.maps.places.PlaceResult | null, handleSelectPlace: () => void, incorrectAddress: boolean }) => {
  const [markerRef, marker] = useAdvancedMarkerRef();

  return (
    <>
      <PlaceAutocomplete onPlaceSelect={handleSelectPlace} />
      {incorrectAddress && <FieldError>Address must have street number</FieldError>}
      <div style={{ height: "150px" }} className='rounded overflow-hidden w-100'>
        <Map
          mapId={'bf51a910020fa25a'}
          defaultZoom={3}
          defaultCenter={{ lat: 22.54992, lng: 0 }}
          gestureHandling={'greedy'}
          disableDefaultUI={true}
        >
          <AdvancedMarker ref={markerRef} position={null} />
        </Map>
        <MapHandler place={selectedPlace} marker={marker} />
      </div>
    </>
  );
};

interface MapHandlerProps {
  place: google.maps.places.PlaceResult | null;
  marker: google.maps.marker.AdvancedMarkerElement | null;
}

const MapHandler = ({ place, marker }: MapHandlerProps) => {
  const map = useMap();

  useEffect(() => {
    if (!map || !place || !marker) return;

    if (place.geometry?.viewport) {
      map.fitBounds(place.geometry?.viewport);
    }
    marker.position = place.geometry?.location;
  }, [map, place, marker]);

  return null;
};


export default MapWithInput;