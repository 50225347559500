import 'react-datepicker/dist/react-datepicker.css';
import { Home, Users, Calendar, Settings, LogOut } from 'react-feather';
import {
  useEffect,
  useRef,
  useState,
} from 'react';
import axios from 'axios';
import copy from 'copy-to-clipboard';
import {
  Button,
  Modal,
  Image,
} from 'react-bootstrap';
import {
  NavLink,
  useNavigate,
} from 'react-router-dom';
import { Auth, Hub } from 'aws-amplify';
import RightArrow from '../assests/images/right-arrow.png';
import User from '../assests/images/ID.svg';
import Logo from '../assests/images/Logo.svg';
import PlaceHolder from '../assests/images/1-placeholder-X2.png'
import getImageUrl from '../helper/checkImageFormat';
import { BiBriefcase } from 'react-icons/bi';
import { useQuery, useQueryClient } from '@tanstack/react-query';


const Sidebar = ({ overrideMargin, children, startDate, setStartDate }) => {
  // alert modal
  const [showAlert, setShowAlert] = useState(false);
  const navigate = useNavigate();
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);
  const [isOpen, setIsOpen] = useState(false);
  const toggle = () => setIsOpen(!isOpen);
  const [showcopyToClipboard, setShowcopyToClipboard] = useState(false);
  const sidebarRef = useRef(null);

  const queryClient = useQueryClient();

  const { data = {}, isLoading, error } = useQuery({
    queryKey: ['profile'],
    queryFn: async () => {
      const user = await Auth.currentAuthenticatedUser();
      const attributes = user.attributes;
      const groups = user.signInUserSession.idToken.payload['cognito:groups'];

      const userInfo = {
        name: attributes.name,
        familyName: attributes.family_name,
        email: attributes.email,
        role: groups && groups.length > 0 ? groups[0] : null,
      };

      const response = await fetch(`${process.env.REACT_APP_NODE_API_URL}/api/users/profile`, {
        headers: {
          Authorization: `Bearer ${(await Auth.currentSession()).getIdToken().getJwtToken()}`,
        },
      });

      if (!response.ok) {
        throw new Error('Failed to fetch user profile');
      }

      const dbUserData = await response.json();

      return {
        ...userInfo,
        ...dbUserData,
      };
    },
    staleTime: 5 * 60 * 1000,
    cacheTime: 30 * 60 * 1000,
    enabled: Boolean(Auth.currentUserInfo),
  });

  // Listen for auth events
  useEffect(() => {
    const hubListener = Hub.listen('auth', ({ payload: { event } }) => {
      // eslint-disable-next-line default-case
      switch (event) {
        case 'signOut':
          // Clear the user data from React Query cache
          queryClient.removeQueries(['userData']);
          break;
        case 'signIn':
          // Refetch user data
          queryClient.invalidateQueries(['userData']);
          break;
      }
    });

    return () => {
      hubListener();
    };
  }, [queryClient]);

  useEffect(() => {
    const handleResize = () => {
      setScreenWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);
    const handleClickOutside = (event) => {
      if (sidebarRef.current && !sidebarRef.current.contains(event.target)) {
        setIsOpen(false);
      }
    };
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      window.removeEventListener("resize", handleResize);
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);


  const handleLogout = async (e) => {
    e.preventDefault();
    try {
      await Auth.signOut();
      navigate('/');
    } catch (error) {
      console.error('Error signing out: ', error);
    }
  };
  const copyToClipboard = async () => {
    try {
      const authData = await Auth.currentSession()
      const userId = authData.getIdToken().payload['custom:_id']
      var link = process.env.REACT_APP_URL + "/book/list/" + userId;
      copy(link);
      setShowcopyToClipboard(true)
    } catch (error) {
      console.log(error);
    }

  }
  return (
    <>
      <div className='mobile-header'>
        <div className='mobile-header-wrap'>
          <div className='panel-expand-control' height={'2px'}>
            <a href="#!" onClick={toggle} className='d-block d-md-none hamburger-icon'>
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"><path d="M0 96C0 78.3 14.3 64 32 64H416c17.7 0 32 14.3 32 32s-14.3 32-32 32H32C14.3 128 0 113.7 0 96zM0 256c0-17.7 14.3-32 32-32H416c17.7 0 32 14.3 32 32s-14.3 32-32 32H32c-17.7 0-32-14.3-32-32zM448 416c0 17.7-14.3 32-32 32H32c-17.7 0-32-14.3-32-32s14.3-32 32-32H416c17.7 0 32 14.3 32 32z" /></svg>
            </a>
          </div>
          <div className="logo d-block d-md-none">
            <NavLink to='/' className="link">
              <img src={Logo} alt="Logo" width={screenWidth <= 755 ? "67" : "115"} height={screenWidth <= 755 ? "17" : "27"} />
            </NavLink>
          </div>
        </div>
        <div className={isOpen ? 'sidebar  sidebar-active' : 'sidebar'} ref={sidebarRef}>
          <div className=''>
            <a href="#!" className="btn-close" aria-label="Close" onClick={toggle}>
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512">
                <path d="M310.6 150.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L160 210.7 54.6 105.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L114.7 256 9.4 361.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0L160 301.3 265.4 406.6c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L205.3 256 310.6 150.6z" /></svg>
            </a>
            <div className="logo">
              <NavLink to='/property/list' className="link">
                <img src={Logo} alt="Logo" width="115" height="27" />
              </NavLink>
            </div>
            <ul className='d-flex flex-column justify-content-around h-100'>
              <li>
                <NavLink to='/property/list?page=0&limit=5' className="link">
                  <span className='link-icon'>
                    <Home width='25px' height='25px' />
                  </span>
                  <span className='link-text'>Properties</span>
                </NavLink>
              </li>
              <li>
                <NavLink to='/leads' className="link">
                  <span className='link-icon'>
                    <Users width='25px' height='25px' />
                  </span>
                  <span className='link-text'>Leads</span>
                </NavLink>
              </li>
              <li>
                <NavLink to='/showings' className="link">
                  <span className='link-icon'>
                    <Calendar width='25px' height='25px' />
                  </span>
                  <span className='link-text'>Bookings</span>
                </NavLink>
              </li>
              {['admin', 'superadmin'].includes(data?.role) && <li>
                <NavLink to='/kits' className="link">
                  <span className='link-icon'>
                    <BiBriefcase name="briefcase" size={25} />
                  </span>
                  <span className='link-text'>Kits</span>
                </NavLink>
              </li>}
              {['admin', 'superadmin'].includes(data?.role) && <li>
                <NavLink to='/account' className="link">
                  <span className='link-icon'>
                    <Settings width='25px' height='25px' />
                  </span>
                  <span className='link-text'>Account</span>
                </NavLink>
              </li>}
              <li>
                <a href="" onClick={handleLogout} className="link">
                  <span className='link-icon'>
                    <LogOut width='25px' height='25px' />
                  </span>
                  <span className='link-text'>Logout</span>
                </a>
              </li>
            </ul>
          </div>
          <div className='side-bar-bottom'>
            <div className='d-flex side-bar-bottom-row'>
              <a type="button" className="link" onClick={copyToClipboard}>
                <span className='link-icon'>
                  {/* <img src={Calendar} width="14" height="16" /> */}
                  <Calendar />
                </span>
                <span className='link-text'>Share
                  calendar</span>
              </a>
            </div>
            <div className="user-info d-flex align-items-center justify-content-center gap-2 custom-border-top"  >
              <div className='user-info-left'>

                <Image
                  src={data?.company?.idImage ? getImageUrl(data.company.idImage) : PlaceHolder}
                  onError={({ currentTarget }) => {
                    currentTarget.onerror = null; // prevents looping
                    currentTarget.src = User;
                  }}
                  alt=''
                  width='48px'
                  height='48px'
                  roundedCircle
                  style={{ objectFit: "cover" }}
                />
              </div>
              <div className='user-info-middle'>
                <span className='user-info-name'>{data ? data.company?.name : ''}</span>
                <span className='user-info-email'>{data ? data.email : ''}</span>
              </div>
              {/* <div className='user-info-right'>
                <a href="/">
                  <img src={RightArrow} width="8" height="20" alt='' />
                </a>
              </div> */}
            </div>
          </div>
        </div>
      </div>
      <main className={`${isOpen ? 'main-left' : ''} main`}>
        {children}
        {showcopyToClipboard ?
          <Modal show={showcopyToClipboard} style={{ zIndex: 9999 }}>
            <Modal.Header>
              <Modal.Title>Copied</Modal.Title>
              <button className="btn-close" aria-label="Close" onClick={() => setShowcopyToClipboard(false)}></button>
            </Modal.Header>
            <Modal.Body>Calendar link copied. You can send this link to leads, so that they can create appointments.</Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={() => setShowcopyToClipboard(false)}>
                Close
              </Button>
            </Modal.Footer>
          </Modal>
          : ''
        }
      </main>

    </>
  );
};

export default Sidebar;