import { Auth } from "aws-amplify";
import axios from "axios";

export const isRouterOnline = async ({ kitId}) => {
  const url = process.env.REACT_APP_NODE_API_URL + "/api/hardware/router/status/" + kitId;

  try {
    const token = await Auth.currentSession()

    const res = await axios
    .get(url, {
      headers: {
        Authorization: `Bearer ${token.getIdToken().getJwtToken()}`,
      },

    })
  
    return res.data

  } catch (error) {
    console.log("ERROR GETTING USERS: ", error)
    return error.response.data
  }
}