import React from "react";
import LazyImgWithPlaceholder from "../../../components/Images/LazyImgWithPlaceholder";
import { useQuery } from "@tanstack/react-query";
import { isRouterOnline } from "../../../api/routers";
import { OverlayTrigger, Tooltip } from "react-bootstrap";

const PropertyImage = ({ kit, property }) => {
  const {
    data: router = { status: "unavailable" },
    isLoading,
    isError,
  } = useQuery({
    queryKey: ["router-status", property],
    queryFn: () => isRouterOnline({ kitId: kit?._id }),
    enabled: !!kit,
  });

  return (
    <p className="d-flex gap-1 align-items-center bg-secondary-subtle px-2 py-05 rounded-4 w-content">
      <span
        className={`router-status ${
          isLoading
            ? "bg-secondary"
            : router.status === "online"
            ? "completed"
            : router.status === "offline"
            ? "cancelled"
            : "bg-secondary"
        }`}
      ></span>
      <span className="fs-9">
        {isLoading ? "Getting status..." : router.status}
      </span>
    </p>
  );
};

export default PropertyImage;
