import { Box, Tab, Tabs } from "@mui/material";
import styled from "styled-components";
import React, { useEffect, useRef, useState } from "react";
import ProfilePanel from "./components/ProfilePanel";
import CalendarPanel from "./components/CalendarPanel";
import IntegrationsPanel from "./components/IntegrationsPanel";
import UsersPanel from "./components/UsersPanel";
import NotificationsPanel from "./components/NotificationsPanel";
import CommunicationPanel from "./components/CommunicationPanel";
import { useBlocker, useLocation } from "react-router-dom";
import { useGeneral } from "../../../context/GeneralContext";
import Alert from "../../../components/Alert";

export const StyledGrayButton = styled.button`
  background-color: gray !important;
  border-color: gray !important;
  transition: border 0.3s;

  &:hover {
    border-color: white !important; /* Border disappears on hover */
  }
`;

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const subTabs = [
  {
    value: 0,
    name: "profile",
  },
  {
    value: 1,
    name: "calendar",
  },
  {
    value: 2,
    name: "integrations",
  },
  {
    value: 3,
    name: "users",
  },
  {
    value: 4,
    name: "notifications",
  },
  {
    value: 5,
    name: "communications",
  },
];

const SettingTab = () => {
  const [tabValue, setTabValue] = useState(0);
  const location = useLocation();
  const [isDirty, setIsDirty] = useState(false);
  const { setAlertModal } = useGeneral();
  const [proceed, setProceed] = useState();
  const [navigateTo, setNavigateTo] = useState();

  const numberOfRefs = 2;
  const childTabRefs = useRef(Array.from({ length: numberOfRefs }, () => React.createRef()));


  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const subTab = queryParams.get("subTab");
    if (!subTab)
      window.history.pushState(
        {},
        "",
        `/account?activeTab=settings&subTab=profile`
      );
    if (subTabs.find((tab) => tab.name === subTab))
      setTabValue(subTabs.find((tab) => tab.name === subTab).value);
  }, [location.search]);

  useEffect(() => {
    window.history.pushState(
      {},
      "",
      `/account?activeTab=settings&subTab=${
        subTabs.find((tab) => tab.value === tabValue).name
      }`
    );
  }, [tabValue]);

  const handleChange = (event, newValue) => {
    if (isDirty) {
      setProceed(true);
      setNavigateTo(
        `/account?activeTab=settings&subTab=${
          subTabs.find((tab) => tab.value === newValue).name
        }`
      );
      return;
    }
    setTabValue(newValue);
  };

  const handleNavigation = ({ nextLocation }) => {
    if (isDirty) {
      setProceed(true);
      setNavigateTo(nextLocation.pathname);
      return true;
    }
    return false;
  };

  useBlocker(handleNavigation);

  const saveFormData = () => {
    childTabRefs.current[tabValue].current.saveForm();
  };

  const next = () => {
    if (isDirty) {
      setProceed(true);
      const nextTab = tabValue === 1 ? 0 : tabValue + 1;
      setNavigateTo(
        `/account?activeTab=settings&subTab=${
          subTabs.find((tab) => tab.value === nextTab).name
        }`
      );
      return;
    }
    setTabValue((prev) => (prev === 1 ? 0 : prev + 1));
  };

  const prev = () => {
    if (isDirty) {
      setProceed(true);
      const nextTab = tabValue === 0 ? 1 : tabValue - 1;
      setNavigateTo(
        `/account?activeTab=settings&subTab=${
          subTabs.find((tab) => tab.value === nextTab).name
        }`
      );
      return;
    }
    setTabValue((prev) => (prev === 0 ? 1 : prev - 1));
  };

  return (
    <>
      <Box sx={{ width: "100%" }}>
        <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
          <Tabs
            value={tabValue}
            onChange={handleChange}
            variant="scrollable"
            scrollButtons="auto"
          >
            <Tab label="PROFILE" {...a11yProps(0)} />
            <Tab label="CALENDAR" {...a11yProps(1)} />
            {/* <Tab label="INTEGRATIONS" {...a11yProps(2)} />
          <Tab label="USERS" {...a11yProps(3)} />
          <Tab label="NOTIFICATIONS" {...a11yProps(4)} />
          <Tab label="COMMUNICATION" {...a11yProps(5)} /> */}
          </Tabs>
        </Box>
        <ProfilePanel
          value={tabValue}
          index={0}
          next={next}
          prev={prev}
          setIsDirty={setIsDirty}
          ref={childTabRefs.current[0]}
        ></ProfilePanel>
        <CalendarPanel
          value={tabValue}
          index={1}
          next={next}
          prev={prev}
          setIsDirty={setIsDirty}
          ref={childTabRefs.current[1]}
        ></CalendarPanel>
        {/* <IntegrationsPanel
        value={tabValue}
        index={2}
        next={next}
        prev={prev}
      ></IntegrationsPanel>
      <UsersPanel
        value={tabValue}
        index={3}
        next={next}
        prev={prev}
      ></UsersPanel>
      <NotificationsPanel
        value={tabValue}
        index={4}
        next={next}
        prev={prev}
      ></NotificationsPanel>
      <CommunicationPanel
        value={tabValue}
        index={5}
        next={next}
        prev={prev}
      ></CommunicationPanel> */}
      </Box>
      {proceed && (
        <Alert
          show={proceed}
          setShow={() => {
            saveFormData();
            setProceed(false);
          }}
          navigateTo={navigateTo}
          title={"You have unsaved changes. Are you sure you want to exit?"}
          content={"All unsaved changes will be lost."}
        />
      )}
    </>
  );
};

export default SettingTab;
