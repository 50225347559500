import React from "react";
import { CiBatteryFull, CiBatteryEmpty } from "react-icons/ci";
import { OverlayTrigger, Tooltip } from "react-bootstrap";

const MobileBatteryStatus = ({ level, bridgeStatus, vendor }) => {
  let batteryIcon;

  if (level > 80) {
    batteryIcon = <CiBatteryFull size={25} color="white" />;
  } else if (level > 20) {
    batteryIcon = <CiBatteryFull size={25} color="white" />;
  } else {
    batteryIcon = <CiBatteryEmpty size={25} color="white" />;
  }

  const renderTooltip = (props) => (
    <Tooltip className="status-tooltip" {...props}>
      Battery level not available
    </Tooltip>
  );

  return (
    <div
      style={{ width: "41px", height: "41px", position: "relative" }}
      className="rounded-circle align-items-center justify-content-center p-2 bg-delet-secondary"
      disabled={bridgeStatus === "offline"}
    >
      {bridgeStatus === "offline" || vendor === "switchbot" ? (
        <OverlayTrigger
          placement="bottom"
          delay={{ hide: 450 }}
          overlay={renderTooltip}
          offset={[0, 10]} 
        >
          <div style={{ display: "inline-block" }}>
            {batteryIcon}
          </div>
        </OverlayTrigger>
      ) : (
        batteryIcon
      )}
      <span
        style={{
          width: `${
            bridgeStatus === "offline" ? "14px" : level > 80 ? "14px" : "12px"
          }`,
          height: "10px",
          position: "absolute",
          top: "55%",
          left: `${
            bridgeStatus === "offline" ? "46%" : level > 80 ? "46%" : "43%"
          }`,
          transform: "translate(-50%, -50%)",
          background: `${
            bridgeStatus === "offline"
              ? "#696666"
              : level > 20
              ? "#696666"
              : "#FF0000"
          }`,
        }}
      ></span>
      <span
        style={{
          position: "absolute",
          top: "55%",
          left: "47%",
          transform: "translate(-50%, -50%)",
          fontSize: "7px",
          color: "white", 
        }}
      >
        {bridgeStatus === "offline" || vendor === "switchbot" ? "?" : level}
      </span>
    </div>
  );
};

export default MobileBatteryStatus;
