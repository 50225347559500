import { Box } from "@mui/material";
import React from "react";
import { Card, FormTitle } from "../PropertyFormTab";
import InputTextComponent from "../../../components/InputTextComponent";
import InputSelectComponent from "../../../components/InputSelectComponent";
import InputTextAreaComponent from "../../../components/InputTextAreaComponent";
import { Col, Row } from "react-bootstrap";
import InputRadioHorizontal from "../../../components/InputRadioHorizontal";
import LableComponent from "../../../components/Lable";
import { parkingType, pets } from "../../../static/Data";
import InputRadio from "../../../components/InputRadio";
import InputCheckbox from "../../../components/InputCheckbox";
import useViewport from "../../../hooks/useViewport";
import PropertyFormPanelHeader from "./PropertyFormPanelHeader";

const SpecificationsAndFeesTabPanel = (props) => {
  const {
    children,
    value,
    register,
    setValue,
    errors,
    trigger,
    watch,
    index,
    saveFormData,
    ...other
  } = props;

  const { width: screenWidth } = useViewport();
  const uitilities = [
    { key: "Water", value: "water" },
    { key: "Electricity", value: "electricity" },
    { key: "Gas", value: "gas" },
    { key: "Trash", value: "trash" },
    { key: "Sewage", value: "sewage" },
  ];
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={screenWidth > 755 ? { p: 3 } : { p: 1 }}>
          <div>
            <div xl={12} style={{ border: "1px solid rgb(234, 234, 235)" }}>
              <PropertyFormPanelHeader
                saveFormData={saveFormData}
                title={"SPECIFICATIONS & FEES"}
                description={
                  "This information will be visible to prospects on the Prospect Booking page."
                }
              />
              <div
                className={`${
                  screenWidth > 755 ? "px-4" : ""
                } flex-column d-flex`}
              >
                <Card id="propertySize">
                  <FormTitle>Property Size & Description</FormTitle>
                  <InputTextComponent
                    textAtEnd="sqft"
                    labelType="primary"
                    label="Sqft"
                    name="sqft"
                    type="number"
                    register={register}
                    trigger={trigger}
                    setValue={setValue}
                    error={errors}
                    placeholder=""
                  />

                  <InputSelectComponent
                    label="# of Bedrooms"
                    name="bedrooms"
                    register={register}
                    error={errors}
                    options={Array.from({ length: 7 }, (_, i) => i.toString())}
                    selectedOption="Select"
                    placeholder="Select"
                  />
                  <InputSelectComponent
                    label="# of Bathrooms"
                    name="bathrooms"
                    register={register}
                    error={errors}
                    options={Array.from({ length: 14 }, (_, index) =>
                      (index === 0 ? 0 : 0.5 * index).toString()
                    )}
                    selectedOption="Select"
                    placeholder="Select"
                  />

                  <InputTextAreaComponent
                    labelType="secondary"
                    label="Property Description (Optional)"
                    name="propertyDescription"
                    register={register}
                    placeholder="Enter property description here..."
                  />
                </Card>
                <Card id="utilities">
                  <FormTitle>UTILITIES</FormTitle>
                  {uitilities.map((uitility) => (
                    <Row key={uitility.key}>
                      <Col xl={5} md={5} className="pb-2">
                        <span className="body-s-bold">{uitility.key}</span>
                      </Col>
                      <Col
                        xl={7}
                        md={7}
                        className="d-flex justify-content-start justify-content-lg-end"
                      >
                        <InputRadioHorizontal
                          name={`utilities.${uitility.value}`}
                          register={register}
                          error={errors}
                          options={["included", "tenant"]}
                          labels={["Included in Rent", "Tenant Pays"]}
                        />
                      </Col>
                    </Row>
                  ))}
                  <InputTextAreaComponent
                    labelType="secondary"
                    label="Utilities Notes (Optional)"
                    name="utilities.notes"
                    register={register}
                    placeholder="Enter any additional notes here..."
                  />
                </Card>
                <Card id="parking">
                  <FormTitle>PARKING</FormTitle>
                  <LableComponent
                    variantType="primary"
                    label="Parking Type"
                    name="parking.type"
                  />
                  {parkingType.map((type, index) => (
                    <Row key={type.primary.value}>
                      <Col
                        xl={8}
                        lg={8}
                        xs={12}
                        className="d-flex align-items-center justify-content-between"
                      >
                        <InputRadio
                          name="parking.type"
                          register={register}
                          options={[type.primary]}
                        />
                      </Col>
                      {type.extra && (
                        <Col
                          xl={4}
                          lg={4}
                          xs={12}
                          className="d-flex gap-2 justify-content-end flex-lg-row pt-3 pt-xl-0"
                        >
                          <InputCheckbox
                            name={`parking.tandem[${index}]`}
                            register={register}
                            option={type.extra}
                            error={errors}
                            disabled={
                              !(watch("parking.type") === type.primary.value)
                            }
                          />
                        </Col>
                      )}
                    </Row>
                  ))}
                  <InputSelectComponent
                    label="# of Parking Spaces"
                    name="parking.spacesNumber"
                    register={register}
                    error={errors}
                    options={Array.from({ length: 6 }, (_, i) =>
                      (i + 1).toString()
                    )}
                    selectedOption="Select"
                    placeholder="Select"
                    disabled={
                      !(
                        watch("parking.type") === "secured" ||
                        watch("parking.type") === "covered" ||
                        watch("parking.type") === "uncovered"
                      )
                    }
                  />
                  <Row>
                    <Col xl={8}>
                      <InputTextComponent
                        label="Monthly Cost Per Space"
                        type="number"
                        name="parking.monthlyCostPerSpace"
                        register={register}
                        trigger={trigger}
                        setValue={setValue}
                        placeholder="$ Enter dollar amount"
                        disabled={
                          !(
                            watch("parking.type") === "secured" ||
                            watch("parking.type") === "covered" ||
                            watch("parking.type") === "uncovered"
                          ) || watch("parking.included")
                        }
                      />
                    </Col>
                    <Col
                      xl={4}
                      className="d-flex align-items-end justify-content-start justify-content-lg-end pb-2 pt-3 pt-xl-0"
                    >
                      <InputCheckbox
                        name="parking.included"
                        register={register}
                        setValue={setValue}
                        option={{ name: "Included in Rent" }}
                        disabled={
                          !(
                            watch("parking.type") === "secured" ||
                            watch("parking.type") === "covered" ||
                            watch("parking.type") === "uncovered"
                          )
                        }
                      />
                    </Col>
                  </Row>
                  <InputTextAreaComponent
                    labelType="secondary"
                    label="Parking Notes (Optional)"
                    name="parking.notes"
                    register={register}
                    placeholder="Enter any additional notes here..."
                  />
                </Card>
                <Card id="petPolicies">
                  <FormTitle>PET POLICIES</FormTitle>
                  <LableComponent
                    variantType="primary"
                    label="Pets Allowed?"
                    name="petsAllowed"
                  />
                  <InputCheckbox
                    name="pets.allowed"
                    register={register}
                    option={pets[0]}
                    error={errors}
                    disabled={
                      watch("pets.cats.allowed") || watch("pets.dogs.allowed")
                    }
                    onChange={(e) => {
                      setValue("pets.allowed", e.target.checked, {
                        shouldValidate: true,
                        shouldDirty: true,
                      });
                    }}
                  />
                  <InputCheckbox
                    name="pets.dogs.allowed"
                    register={register}
                    option={pets[1]}
                    error={errors}
                    disabled={watch("pets.allowed")}
                    onChange={(e) => {
                      setValue("pets.dogs.allowed", e.target.checked, {
                        shouldValidate: true,
                        shouldDirty: true,
                      });
                    }}
                  />
                  <InputCheckbox
                    name="pets.cats.allowed"
                    register={register}
                    option={pets[2]}
                    error={errors}
                    disabled={watch("pets.allowed")}
                    onChange={(e) => {
                      setValue("pets.cats.allowed", e.target.checked, {
                        shouldValidate: true,
                        shouldDirty: true,
                      });
                    }}
                  />

                  <InputTextComponent
                    label="Pet Deposit"
                    name="pets.deposit"
                    type="number"
                    register={register}
                    trigger={trigger}
                    setValue={setValue}
                    placeholder="$ Enter dollar amount"
                    disabled={watch("pets.allowed")}
                  />
                  <InputTextComponent
                    label="Monthly Pet Rent"
                    name="pets.monthlyPetRent"
                    type="number"
                    register={register}
                    trigger={trigger}
                    setValue={setValue}
                    placeholder="$ Enter dollar amount"
                    disabled={watch("pets.allowed")}
                  />

                  <InputTextAreaComponent
                    labelType="secondary"
                    label="Pet Policy Notes (Optional)"
                    name="pets.notes"
                    register={register}
                    placeholder="Enter any additional notes here..."
                  />
                  <div
                    className={
                      watch("pets.dogs.allowed")
                        ? "d-flex flex-column gap-3"
                        : "d-none"
                    }
                  >
                    <h5 className="d-flex pb-2 align-items-start border-bottom border-dark">
                      Dogs
                    </h5>
                    <div
                      className="d-flex flex-column align-items-start"
                      style={{ gap: "32px" }}
                    >
                      <InputTextComponent
                        textAtEnd="lbs"
                        label="Weight Limit (Optional)"
                        name="pets.dogs.weight"
                        type="number"
                        register={register}
                        trigger={trigger}
                        setValue={setValue}
                        placeholder="Enter max weight"
                      />
                      <InputTextComponent
                        label="Max # of dogs Allowed (Optional)"
                        name="pets.dogs.maxAllowed"
                        type="number"
                        trigger={trigger}
                        setValue={setValue}
                        register={register}
                        placeholder="Enter numeric value"
                      />
                    </div>
                  </div>
                  <div
                    className={
                      watch("pets.cats.allowed")
                        ? "d-flex flex-column gap-3"
                        : "d-none"
                    }
                  >
                    <h5 className="d-flex pb-2 align-items-start border-bottom border-dark">
                      Cats
                    </h5>
                    <div
                      className="d-flex flex-column align-items-start"
                      style={{ gap: "32px" }}
                    >
                      <InputTextComponent
                        textAtEnd="lbs"
                        label="Weight Limit (Optional)"
                        name="pets.cats.weight"
                        type="number"
                        register={register}
                        trigger={trigger}
                        setValue={setValue}
                        placeholder="Enter max weight"
                      />
                      <InputTextComponent
                        label="Max # of cats Allowed (Optional)"
                        name="pets.cats.maxAllowed"
                        type="number"
                        register={register}
                        trigger={trigger}
                        setValue={setValue}
                        placeholder="Enter numeric value"
                      />
                    </div>
                  </div>
                </Card>
              </div>
            </div>
          </div>
        </Box>
      )}
    </div>
  );
};

export default SpecificationsAndFeesTabPanel;
