import React, { useEffect, useMemo, useRef, useState } from "react";
import style from "./style.module.scss";
import unlock from "../../../assests/images/doorknob-unlock.png";
import { keypadInstructions } from "../../../constants";

function AccessInstructions({ vendor }) {
  const { keypad, kpOpen, kpClose, step2_text } = keypadInstructions[vendor];

  return (
    <div className={style.stepContainer}>
      <div>
        <div className={style.circleFrame}>
          <img src={unlock}></img>
        </div>
        <span className={`${style.circle}`}>1</span>
        <p className={style.stepGuide}>Pull the door towards you.</p>
      </div>
      <div>
        <div className={style.circleFrame}>
          {vendor === "yale" && (
            <div className={style["yale-smallLeftFrame"]}>
              <img src={kpClose}></img>
            </div>
          )}
          <div className={style[`${vendor}-smallCircleFrame`]}>
            <img src={kpOpen}></img>
          </div>
          <img src={keypad}></img>
        </div>

        <span className={`${style.circle}`}>2</span>
        <p className={style.stepGuide}>{step2_text}</p>
      </div>
      <div>
        <div className={style.circleFrame}>
          {vendor === "yale" && (
            <div className={style["yale-smallCircleFrame"]}>
              <img src={kpOpen}></img>
            </div>
          )}
          <div className={style[`${vendor}-smallLeftFrame`]}>
            <img src={kpClose}></img>
          </div>
          <img src={keypad}></img>
        </div>
        <span className={`${style.circle}`}>3</span>
        <p className={style.stepGuide}>
          When leaving, make sure to close the door securely and press the Lock
          Button.
        </p>
      </div>
    </div>
  );
}

export default AccessInstructions;
