import { Box } from "@mui/material";
import React from "react";
import { Card, FormTitle } from "../PropertyFormTab";
import InputTextComponent from "../../../components/InputTextComponent";
import { Col, Row } from "react-bootstrap";
import InputSwitchComponent from "../../../components/InputSwitchComponent";
import InputRadio from "../../../components/InputRadio";
import useViewport from "../../../hooks/useViewport";
import PropertyFormPanelHeader from "./PropertyFormPanelHeader";
import InputPhoneNumberComponent from "../../../components/InputPhoneNumberComponent";

const ContactTabPanel = (props) => {
  const {
    children,
    value,
    register,
    setValue,
    errors,
    trigger,
    watch,
    index,
    saveFormData,
    ...other
  } = props;

  const { width: screenWidth } = useViewport();
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={screenWidth > 755 ? { p: 3 } : { p: 1 }}>
          <div>
            <div xl={12} style={{ border: "1px solid rgb(234, 234, 235)" }}>
              <PropertyFormPanelHeader
                saveFormData={saveFormData}
                title={"PROPERTY MANAGEMENT"}
                description={
                  "By default, the information entered in this section will only be visible to you. You have the option to share contact information with prospects via automated communications."
                }
              />
              <div
                className={`${
                  screenWidth > 755 ? "px-4" : ""
                } flex-column d-flex`}
              >
                <Card id="">
                  <FormTitle>COMPANY CONTACT INFORMATION</FormTitle>
                  <InputTextComponent
                    label="Property Management Group"
                    name="company.name"
                    register={register}
                    trigger={trigger}
                    setValue={setValue}
                    error={errors}
                    placeholder="Enter company name"
                  />
                  <InputTextComponent
                    labelType="secondary"
                    label="Company Website (Optional)"
                    name="company.website"
                    register={register}
                    trigger={trigger}
                    setValue={setValue}
                    error={errors}
                    placeholder="Enter website URL"
                  />
                  <InputPhoneNumberComponent
                    required={true}
                    labelType="secondary"
                    label="Company Phone Number (Optional)"
                    name="company.phone"
                    register={register}
                    trigger={trigger}
                    watch={watch}
                    setValue={setValue}
                    error={errors}
                    placeholder="1-xxx-xxx-xxxx"
                  />
                  <div className="d-flex justify-content-between align-items-center">
                    <span
                      className="body col-9"
                      style={
                        screenWidth > 755
                          ? { fontSize: "17px" }
                          : { fontSize: "14px" }
                      }
                    >
                      Include Company contact information in automated prospect
                      communications
                      <span
                        className="tags px-1 pt-1"
                        style={{ color: "#575656", fontSize: "14px" }}
                      >
                        Recommended
                      </span>
                    </span>
                    <InputSwitchComponent
                      register={register}
                      watch={watch}
                      name="company.contactInformation"
                    />
                  </div>
                </Card>
                <Card>
                  <FormTitle>Leasing Agent Contact Information</FormTitle>
                  <Row className="gap-2 gap-xl-0">
                    <Col xl={6} className="d-flex align-items-center">
                      <InputTextComponent
                        labelType="secondary"
                        label="Name (Optional)"
                        name="leasingAgent.firstName"
                        register={register}
                        trigger={trigger}
                        setValue={setValue}
                        error={errors}
                        placeholder="First Name"
                      />
                    </Col>
                    <Col
                      xl={6}
                      className="d-flex justify-content-start justify-content-xl-end flex-column flex-lg-row align-items-start align-items-xl-end"
                    >
                      <InputTextComponent
                        name="leasingAgent.lastName"
                        register={register}
                        trigger={trigger}
                        setValue={setValue}
                        error={errors}
                        placeholder="Last Name"
                      />
                    </Col>
                  </Row>

                  <InputPhoneNumberComponent
                    required={true}
                    labelType="secondary"
                    label="Phone Number (Optional)"
                    name="leasingAgent.phone"
                    register={register}
                    trigger={trigger}
                    watch={watch}
                    setValue={setValue}
                    error={errors}
                    placeholder="1-xxx-xxx-xxxx"
                  />
                  <InputTextComponent
                    required={true}
                    register={register}
                    trigger={trigger}
                    setValue={setValue}
                    requiredField={{
                      pattern: {
                        value: /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.com$/,
                        message: "Invalid email format",
                      },
                    }}
                    label="Email (Optional)"
                    name="leasingAgent.email"
                    error={errors}
                    placeholder="example@email.com"
                  />
                  <div className="d-flex justify-content-between align-items-center">
                    <span
                      className="body col-9"
                      style={
                        screenWidth > 755
                          ? { fontSize: "17px" }
                          : { fontSize: "14px" }
                      }
                    >
                      Include Leasing Agent contact information in automated
                      prospect communications
                      <span
                        className="tags px-1 pt-1"
                        style={{ color: "#575656", fontSize: "14px" }}
                      >
                        Recommended
                      </span>
                    </span>
                    <InputSwitchComponent
                      register={register}
                      watch={watch}
                      name="leasingAgent.contactInformation"
                    />
                  </div>
                </Card>
                <Card>
                  <FormTitle>PROPERTY MANAGER CONTACT INFORMATION</FormTitle>
                  <InputRadio
                    label="Property/Building Manager on site?"
                    name="propertyManager.onSite"
                    register={register}
                    error={errors}
                    options={[
                      { name: "Yes", value: "true" },
                      { name: "No", value: "false" },
                    ]}
                    initialValue={
                      watch("propertyManager.onSite") === true
                        ? "true"
                        : "false"
                    }
                  />
                  <div
                    className={`${
                      watch("propertyManager.onSite") === "true"
                        ? "d-flex flex-column gap-3"
                        : "d-flex flex-column gap-3"
                    }`}
                  >
                    <Row className="gap-2 gap-xl-0">
                      <Col xl={6} className="d-flex align-items-center">
                        <InputTextComponent
                          labelType="secondary"
                          label="Name (Optional)"
                          name="propertyManager.firstName"
                          register={register}
                          trigger={trigger}
                          setValue={setValue}
                          error={errors}
                          placeholder="First Name"
                        />
                      </Col>
                      <Col
                        xl={6}
                        className="d-flex justify-content-start justify-content-xl-end flex-column flex-lg-row align-items-start align-items-xl-end"
                      >
                        <InputTextComponent
                          name="propertyManager.lastName"
                          register={register}
                          trigger={trigger}
                          setValue={setValue}
                          error={errors}
                          placeholder="Last Name"
                        />
                      </Col>
                    </Row>

                    <InputPhoneNumberComponent
                      required={true}
                      labelType="secondary"
                      label="Phone Number (Optional)"
                      name="propertyManager.phone"
                      register={register}
                      trigger={trigger}
                      watch={watch}
                      setValue={setValue}
                      error={errors}
                      placeholder="1-xxx-xxx-xxxx"
                    />
                    <InputTextComponent
                      required={true}
                      register={register}
                      trigger={trigger}
                      setValue={setValue}
                      requiredField={{
                        pattern: {
                          value: /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.com$/,
                          message: "Invalid email format",
                        },
                      }}
                      label="Email (Optional)"
                      name="propertyManager.email"
                      error={errors}
                      placeholder="example@email.com"
                    />
                    <div className="d-flex justify-content-between align-items-center">
                      <span
                        className="body col-9"
                        style={
                          screenWidth > 755
                            ? { fontSize: "17px" }
                            : { fontSize: "14px" }
                        }
                      >
                        Include Property Manager contact information in
                        automated prospect communications
                        <span
                          className="tags px-1 pt-1"
                          style={{ color: "#575656", fontSize: "14px" }}
                        >
                          Recommended
                        </span>
                      </span>
                      <InputSwitchComponent
                        register={register}
                        watch={watch}
                        name={"propertyManager.contactInformation"}
                      />
                    </div>
                  </div>
                </Card>
                <Card>
                  <FormTitle>Maintenance Manager Contact Information</FormTitle>
                  <InputRadio
                    variantType="secondary"
                    label="Maintenance Manager on site?"
                    name="maintenanceManager.onSite"
                    register={register}
                    error={errors}
                    options={[
                      { name: "Yes", value: "true" },
                      { name: "No", value: "false" },
                    ]}
                    initialValue={
                      watch("maintenanceManager.onSite") === true
                        ? "true"
                        : "false"
                    }
                  />
                  <div
                    className={`${
                      watch("maintenanceManager.onSite") === "true"
                        ? "d-flex flex-column gap-3"
                        : "d-flex flex-column gap-3"
                    }`}
                  >
                    <Row className="gap-2 gap-xl-0">
                      <Col xl={6} className="d-flex align-items-center">
                        <InputTextComponent
                          labelType="secondary"
                          label="Name (Optional)"
                          name="maintenanceManager.firstName"
                          register={register}
                          trigger={trigger}
                          setValue={setValue}
                          error={errors}
                          placeholder="First Name"
                        />
                      </Col>
                      <Col
                        xl={6}
                        className="d-flex justify-content-start justify-content-xl-end flex-column flex-lg-row align-items-start align-items-xl-end"
                      >
                        <InputTextComponent
                          name="maintenanceManager.lastName"
                          register={register}
                          trigger={trigger}
                          setValue={setValue}
                          error={errors}
                          placeholder="Last Name"
                        />
                      </Col>
                    </Row>

                    <InputPhoneNumberComponent
                      required={true}
                      labelType="secondary"
                      label="Phone Number (Optional)"
                      name="maintenanceManager.phone"
                      register={register}
                      trigger={trigger}
                      setValue={setValue}
                      watch={watch}
                      error={errors}
                      placeholder="1-xxx-xxx-xxxx"
                    />
                    <InputTextComponent
                      required={true}
                      register={register}
                      trigger={trigger}
                      setValue={setValue}
                      requiredField={{
                        pattern: {
                          value: /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.com$/,
                          message: "Invalid email format",
                        },
                      }}
                      label="Email (Optional)"
                      name="maintenanceManager.email"
                      error={errors}
                      placeholder="example@email.com"
                    />
                    <div className="d-flex justify-content-between align-items-center">
                      <span
                        className="body col-9"
                        style={
                          screenWidth > 755
                            ? { fontSize: "17px" }
                            : { fontSize: "14px" }
                        }
                      >
                        Include Maintenance Manager contact information in
                        automated prospect communications
                        <span
                          className="tags px-1 pt-1"
                          style={{ color: "#575656", fontSize: "14px" }}
                        >
                          Recommended
                        </span>
                      </span>
                      <InputSwitchComponent
                        register={register}
                        watch={watch}
                        name={"maintenanceManager.contactInformation"}
                      />
                    </div>
                  </div>
                </Card>
              </div>
            </div>
          </div>
        </Box>
      )}
    </div>
  );
};

export default ContactTabPanel;
