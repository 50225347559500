import React from "react";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { BiSolidDoorOpen } from "react-icons/bi";
import { FaQuestion } from "react-icons/fa";
import { MdSensorDoor } from "react-icons/md";
import { TbDoorOff } from "react-icons/tb";

const MobileDoorStatus = ({ status, bridgeStatus }) => {
  return (
    <OverlayTrigger
      placement="bottom"
      overlay={
        <Tooltip id="Door status" className="status-tooltip">
          {status === "open" && bridgeStatus === "online"
            ? "The door is currently opened"
            : status === "closed" && bridgeStatus === "online"
            ? "The door is currently closed"
            : status === "unknown" && bridgeStatus === "online"
            ? "Unknown door status"
            : "Sensor is not available"}
        </Tooltip>
      }
      className="bg-white"
    >
      <div
        style={{
          background: bridgeStatus === "offline" ? "#BBBBBB" : "",
          width: "41px",
          height: "41px",
        }}
        className=" d-flex rounded-circle align-items-center justify-content-center p-2 bg-delet-secondary"
        disabled={bridgeStatus === "offline"}
      >
        {bridgeStatus === "online" ? (
          status === "open" ? (
            <BiSolidDoorOpen color="red" size={20} />
          ) : status === "closed" ? (
            <MdSensorDoor color="white" size={20} />
          ) : status === "unknown" ? (
            <FaQuestion color="white" size={15} />
          ) : (
            <TbDoorOff color="white" size={15} />
          )
        ) : (
          <TbDoorOff color="white" size={15} />
        )}
      </div>
    </OverlayTrigger>
  );
};

export default MobileDoorStatus;
