import propertyDummyImage from "../../../../../assests/images/6-thumb.png";
import style from "./style.module.scss";
import { Card } from "../../../../../designSystem/Card";

function PropertyCard({
  property,
  onChange,
  selectedId,
  onSeeDetails,
  checked,
}) {
  const { address, shortAddress, images, city, state, zipCode, unit } =
    property;

  return (
    <div className={style.container}>
      <Card
        active={selectedId === property._id}
        interactive={true}
        className={style.card}
      >
        <table className={style.tableCard}>
          <tbody>
            <tr className={style.row}>
              <td className={style.tableData}>
                <input
                  type="checkbox"
                  onChange={onChange}
                  name="propertyId"
                  id={property._id}
                  value={property._id}
                  checked={selectedId === property._id}
                  className={style.checkInput}
                ></input>
                {checked && selectedId === property._id ? (
                  <label className={style.clickedLabel}>
                    <strong>Selected</strong>
                  </label>
                ) : (
                  <label className={style.normalLabel}>Select</label>
                )}
              </td>
              <td className={style.tableData}></td>
            </tr>
          </tbody>
        </table>

        <label
          className="d-flex flex-column gap-2 pb-2 h-100"
          htmlFor={property._id}
        >
          <Card.Title title={shortAddress} className={style.title}>
            <button
              style={{
                border: "none",
                background: "none",
                color: "#979797",
              }}
            >{`${shortAddress}${unit ? ` Unit ${unit}` : ""}`}</button>
          </Card.Title>
          <Card.SubTitle title={address} className={style.subtitle}>
            {city}, {state} {zipCode}
          </Card.SubTitle>
          <Card.Image
            className={style.propImage}
            src={images?.[0] ?? propertyDummyImage}
            alt="property"
          />
          <Card.Link
            className={`${style.link}`}
            onClick={onSeeDetails}
            style={{ marginLeft: "auto" }}
            as="button"
          >
            See details
          </Card.Link>
        </label>
      </Card>
    </div>
  );
}

export default PropertyCard;
