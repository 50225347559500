import React, { useState } from "react";
import "./Mobile-Table.scss";
import { Link } from "react-router-dom";
import { Share } from "react-feather";
import getImageUrl from "../../../../helper/checkImageFormat";
import styles from "./styles.module.scss";
import FormModal from "./../../../../components/Modals/FormModal";
import { FaRegCopy } from "react-icons/fa6";
import CustomDropdown from "./../../../../components/Dropdown";
import { MoreVertical } from "react-feather";
import {
  propertyArchivedListMenuItems,
  propertyListMenuItems,
} from "./../../../../static/Data";

import MobilePropertyStatusSelect from "../Mobile-PropertyStatusSelect";
import propertyDummyImage from "../../../../assests/images/6-thumb.png";
import LazyImgWithPlaceholder from "../../../../components/Images/LazyImgWithPlaceholder";
import PropertyImage from "../PropertyImage";

const MobileTable = ({
  list,
  handleMenuItemClick,
  handleFormModal,
  queryKey,
  selectedProperty,
}) => {
  const [showModal, setShowModal] = useState(false);
  const [isVerified, setIsVerified] = useState(false);
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [emailError, setEmailError] = useState("");
  const [phoneError, setPhoneError] = useState("");
  const [formData, setFormData] = useState({
    location: "",
    fullName: "",
    email: "",
    phoneNumber: "",
  });

  const handleModalClose = () => {
    setShowModal(false);
    setIsVerified(false);
    setIsSubmitted(false);
    setFormData({ fullName: "", email: "", phoneNumber: "" });
    setPhoneError("");
    setEmailError("");
  };

  return (
    <>
      {list.map((element, index) => {
        return (
          <div className={styles.container} key={element._id}>
            <div className={styles.innerContainer}>
              <div className={styles.actionIconsContainer}>
                {element.status === "archived" ? (
                  <div style={{ width: "16px" }}></div>
                ) : (
                  <>
                    <span>
                      <Share
                        className={styles.moreIcon}
                        height={"15"}
                        width={"15"}
                        onClick={(e) => {
                          e.preventDefault();
                          e.stopPropagation();
                          handleFormModal(element);
                        }}
                      />
                    </span>

                    <span>
                      <FaRegCopy
                        className={styles.moreIcon}
                        height={"15"}
                        width={"15"}
                        onClick={() =>
                          handleMenuItemClick(
                            "Copy prospect booking link",
                            element._id
                          )
                        }
                      />
                    </span>
                  </>
                )}
              </div>
              <Link
                to={`/property/${element._id}`}
                className="position-relative d-inline-block ms-0 ms-lg-3 py-2"
              >
                <LazyImgWithPlaceholder
                  width={80}
                  height={80}
                  border={10}
                  placeholder={propertyDummyImage}
                  src={element.images?.[0] ?? getImageUrl(element.primaryImage)}
                  alt="property"
                />
              </Link>
              <div className={styles.details}>
                {/* <p className={styles.classification}>{element.classification}</p> */}
                <div className={styles.innerDetails}>
                  <div className="d-flex flex-column col align-items-start justify-content-center">
                    <h3>
                      {element.shortAddress || "No address"}
                      {element.unit && (
                        <span>
                          {", "}Unit: {element.unit}
                        </span>
                      )}
                    </h3>
                    <div className="d-flex col-12 justify-content-between pt-1">
                      <p>
                        {element.city}, {element.state} {element.zipCode}
                      </p>
                      <MobilePropertyStatusSelect
                        propertyId={element._id}
                        queryKey={queryKey}
                        status={element.status}
                      />
                    </div>
                    <PropertyImage kit={element.kit} property={element._id} />
                  </div>
                  <div className="more-actions">
                    <CustomDropdown
                      icon={<MoreVertical color="#979797" />}
                      menuItems={
                        element.status === "archived"
                          ? propertyArchivedListMenuItems
                          : propertyListMenuItems
                      }
                      onMenuItemClick={(action) =>
                        handleMenuItemClick(action, element._id)
                      }
                      style={{ pointerEvents: "auto" }}
                    />
                  </div>
                </div>
                <div className={styles.propertyMetadata}>
                  <span>
                    <strong>Price: </strong>
                    {element.price ? (
                      <p>
                        {"$"}
                        {new Intl.NumberFormat("en-US", {
                          minimumFractionDigits: 0,
                          maximumFractionDigits: 0,
                        }).format(element.price)}
                      </p>
                    ) : (
                      <p>N/A</p>
                    )}
                  </span>
                  <span>
                    <strong>Leads: </strong>
                    <p>{element.leadsCount}</p>
                  </span>
                  <span>
                    <strong>Listed On: </strong>
                    <p>
                      {element.listedTs &&
                        new Date(element.listedTs).toLocaleDateString()}
                    </p>
                  </span>
                </div>
              </div>
            </div>
            <hr />
          </div>
        );
      })}
      <FormModal
        show={showModal}
        onHide={() => handleModalClose()}
        isVerified={isVerified}
        isSubmitted={isSubmitted}
        setIsVerified={setIsVerified}
        setIsSubmitted={setIsSubmitted}
        formData={formData}
        setFormData={setFormData}
        emailError={emailError}
        setEmailError={setEmailError}
        phoneError={phoneError}
        setPhoneError={setPhoneError}
        property={selectedProperty}
      />
    </>
  );
};

export default MobileTable;
