import { Box } from "@mui/material";
import React from "react";
import { Card, FormTitle } from "../PropertyFormTab";
import { amenities } from "../../../static/Data";
import InputCheckboxArray from "../../../components/InputCheckboxArray";
import useViewport from "../../../hooks/useViewport";
import PropertyFormPanelHeader from "./PropertyFormPanelHeader";

const AmenitiesAndFeaturesTabPanel = (props) => {
  const {
    children,
    value,
    register,
    setValue,
    errors,
    trigger,
    watch,
    index,
    saveFormData,
    ...other
  } = props;

  const { width: screenWidth } = useViewport();
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={screenWidth > 755 ? { p: 3 } : { p: 1 }}>
          <div>
            <div xl={12} style={{ border: "1px solid rgb(234, 234, 235)" }}>
              <PropertyFormPanelHeader
                saveFormData={saveFormData}
                title={"FEATURES & AMENITIES"}
                description={
                  "This information will be visible to prospects on the Prospect Booking page."
                }
              />
              <div
                className={`${
                  screenWidth > 755 ? "px-4" : ""
                } flex-column d-flex`}
              >
                <Card>
                  <FormTitle>AMENITIES & FEATURES</FormTitle>
                  <div className="row row-gap-3">
                    {amenities.map((amenity) => (
                      <div className="col-12 col-lg">
                        <InputCheckboxArray
                          key={amenity.label}
                          label={amenity.label}
                          name={`amenitiesAndFeatures.${amenity.name}`}
                          register={register}
                          watch={watch}
                          setValue={setValue}
                          options={amenity.options.map((option) => ({
                            name: option,
                            value: option,
                          }))}
                          error={errors}
                        />
                      </div>
                    ))}
                  </div>
                </Card>
              </div>
            </div>
          </div>
        </Box>
      )}
    </div>
  );
};

export default AmenitiesAndFeaturesTabPanel;
