import { useState } from "react";
import style from "./style.module.scss";
import { AppButton } from "../../../designSystem/AppButton";
import propertyDummyImage from "../../../assests/images/6-thumb.png";
import { CiLocationOn } from "react-icons/ci";
import { GoCalendar } from "react-icons/go";
import { FaRegClock } from "react-icons/fa6";
import { IoIosInformationCircle } from "react-icons/io";

function ExpiredCode({ link }) {
  const [isModalOpen, setIsModalOpen] = useState(true);
  return (
    <div>
      <h4 className={style.headerTitle}>Everything is ready for your Tour</h4>
      <p className={style.headerSubtitle}>Here are the instructions</p>

      <div className={style.instructContainer}>
        <div className={style.imgContainer}>
          <img
            src={propertyDummyImage}
            alt="property"
            className={style.imgSize}
          />
          <table>
            <tbody>
              <tr className={style.rows}>
                <td className={style.leftTd}>
                  <CiLocationOn className={style.icon2} /> Location
                </td>
                <td className={style.rightTd}>
                  4676 Admiralty Way, Los Angeles, CA 90342
                </td>
              </tr>
              <tr className={style.rows}>
                <td className={style.leftTd}>
                  <GoCalendar className={style.icon} /> On
                </td>
                <td className={style.rightTd}>01 January, 2025</td>
              </tr>
              <tr className={style.bottomRow}>
                <td className={style.leftTd}>
                  <FaRegClock className={style.icon} /> At
                </td>
                <td className={style.rightTd}>10:30 AM</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>

      <div className="d-flex flex-column justify-content-center align-items-center">
        <table>
          <tbody>
            <tr className={style.rows}>
              <td className={style.leftTdShow}>
                <strong>YOUR PASSCODE</strong>
              </td>
              <td className={style.rightTdShow}>Available for 30 min</td>
            </tr>
          </tbody>
        </table>
        <p className={style.passCode}>123456</p>
      </div>

      <div className="d-flex flex-column justify-content-center align-items-center">
        <table>
          <tbody>
            <tr className={style.rows}>
              <td className={style.leftTdShow}>
                <strong>ACCESS INSTRUCTIONS</strong>
              </td>

              <div className="d-flex">
                <td className={style.rightTdShowInst}>
                  Here are the instructions
                </td>
                <button className={style.asText}>
                  <u>Read More</u>
                </button>
              </div>
            </tr>
          </tbody>
        </table>

        <table>
          <tbody>
            <tr className={style.bottomRow}>
              <td className={style.leftTdShowCode}>Building Access Code</td>
              <td className={style.rightTdShowCode}> 1234456 </td>
            </tr>
          </tbody>
        </table>

        <table>
          <tbody>
            <tr className={style.bottomRow}>
              <td className={style.leftTdShowCode}>Elevator Access Code</td>
              <td className={style.rightTdShowCode}> 1234435</td>
            </tr>
          </tbody>
        </table>
      </div>

      <div className={`${style.buttons} ${style.container} mb-5`}>
        <AppButton className={style.button} as="button" type="button">
          <>{`Unlock Door >`}</>
        </AppButton>
        <AppButton
          className={style.button}
          style={{ backgroundColor: "#4C4D52", color: "#FFFFFF" }}
          as="button"
          type="button"
          hierarchy="secondary"
        >
          {"Manual Access >"}
        </AppButton>
      </div>

      {isModalOpen && (
        <div className={style.modalOverlay}>
          <div className={style.modalContent}>
            <IoIosInformationCircle size={40} color="#5271ff" />
            <p className={style.passCode}>This Booking Has Expired.</p>
            <p className={style.expiredContent}>
              It seems the booking you're trying to view is no longer available.
              Please reschedule your tour.
            </p>
            {/* <AppButton onClick={toggleModal}>Close</AppButton> */}
            <AppButton className={style.button} as="button">
              <a
                href={link}
                style={{
                  textDecoration: "none",
                  color: "white",
                  fontFamily: "Poppins",
                }}
              >
                Reschedule
              </a>
            </AppButton>
          </div>
        </div>
      )}
    </div>
  );
}

export default ExpiredCode;
