import moment from "moment";
import React, { useState } from "react";
import { Offcanvas } from "react-bootstrap";
import { Link } from "react-router-dom";
import getImageUrl from "../../../../helper/checkImageFormat";
import propertyDummyImage from "../../../../assests/images/6-thumb.png";
import LazyImgWithPlaceholder from "../../../../components/Images/LazyImgWithPlaceholder";
import { toZonedTime, format } from "date-fns-tz";
import { IoLink, IoOpen, IoOpenOutline } from "react-icons/io5";

const BookingInfo = ({ show, handleClose, booking }) => {
  const { contact, property } = booking;

  const [showReschedule, setShowReschedule] = useState(false);
  const [showRemove, setShowRemove] = useState(false);
  if (!booking || !booking.startTime || !booking.localTimeZone) {
    return;
  }
  const startTime = new Date(booking?.startTime * 1000); 
  const endTime = new Date((booking.startTime + 15 * 60) * 1000);
  const timeZone = booking?.localTimeZone;
  const zonedStartTime = toZonedTime(startTime, timeZone);
  const zonedEndTime = toZonedTime(endTime, timeZone);
  const formattedDate = format(zonedStartTime, "EEEE, MMMM dd");
  const formattedStartTime = format(zonedStartTime, "h:mm a");
  const formattedEndTime =  format(zonedEndTime, "h:mm a");
  return (
    <Offcanvas
      show={show}
      onHide={handleClose}
      placement="end"
      name="end"
      className="showing-offcanvas"
    >
      <Offcanvas.Header closeButton>
        <Offcanvas.Title>Booking</Offcanvas.Title>
      </Offcanvas.Header>
      <Offcanvas.Body>
        <div className="date-time-panel mb-30 offcanvas-panel">
          <p
            className="py-2"
            style={{ fontWeight: "600", fontSize: "15px", color: "#9f9f9f" }}
          >
            DATE AND TIME
          </p>
          <p className="mb-0">
            {formattedDate}
            <br />
            {formattedStartTime} - {formattedEndTime}
          </p>
        </div>

        <div className="name-panel mb-30 offcanvas-panel">
          <p
            className="py-2"
            style={{ fontWeight: "600", fontSize: "15px", color: "#9f9f9f" }}
          >
            LEAD
          </p>
          <h5 className="mb-20">
            {contact?.firstName} {contact?.lastName}
          </h5>
          <div className="d-block">
            <Link
              to="tel:202-555-0153"
              className="table-phone phonenumber-w-ico link-w-ico color-black"
            >
              {contact?.phone}
            </Link>
          </div>
          <div className="d-block mb-2">
            <Link
              to={`mailto:${contact?.email}`}
              className="table-mail mail-w-ico link-w-ico"
            >
              {contact?.email}
            </Link>
          </div>
        </div>

        <div className="property-panel mb-30 offcanvas-panel">
          <p
            className="py-2"
            style={{ fontWeight: "00", fontSize: "15px", color: "#9f9f9f" }}
          >
            PROPERTY
          </p>
          <div className="properties-details-link">
            <span className="position-relative d-inline-block d-flex">
              <div>
                <LazyImgWithPlaceholder
                  width={140}
                  height={100}
                  border={10}
                  placeholder={propertyDummyImage}
                  src={getImageUrl(property?.images[0])}
                  alt="property"
                />
              </div>
              <div className="ml-20 position-relative">
                <p className="lead-name d-block mb-2">{property?.address}</p>
                <Link
                  to={`/property/${[property?._id]}`}
                  className="color-primary"
                  style={{textDecoration: "none"}}
                >
                  <IoOpenOutline/> <span style={{ fontWeight:"700", fontSize:"14px"}}> View property</span>
                </Link>
              </div>
            </span>
          </div>
        </div>
      </Offcanvas.Body>
      {/* <div className="offcanvas-footer">
        <div className="d-flex justify-content-between">
          <button
            className="btn btn-outline-secondary"
            onClick={() => setShowReschedule(true)}
          >
            Reschedule showing
          </button>
          <button
            className="btn btn-outline-secondary color-red"
            onClick={() => setShowRemove(true)}
          >
            Remove
          </button>
        </div>
      </div> */}
    </Offcanvas>
  );
};

export default BookingInfo;
