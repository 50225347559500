import { Auth } from "aws-amplify"
import axios from "axios"

export const getLockStatus = async (id) => {
  const url = `${process.env.REACT_APP_NODE_API_URL}/api/lock/${id}/status`

  const res = await axios.get(url)
  return res.data
}

export const getPublicLockStatus = async (token, kitId) => {
  const url = `${process.env.REACT_APP_NODE_API_URL}/api/booking/lock/public/${kitId}/status?token=${token}`

  try {
    const { data } = await axios.get(url)
    return data
  } catch (error) {
    console.log("ERROR GETTING LOCK STATUS: ", error)
  }


}

export const updatePublicLockStatus = async (token, kitId, action) => {
  const url = `${process.env.REACT_APP_NODE_API_URL}/api/booking/lock/${kitId}/${action}?token=${token}`

  try {
    const { data } = await axios.patch(url)
    if (data === 'lock') {
      return true
    } else if (data === 'unlock') {
      return false
    }
  } catch (error) {
    console.log("ERROR GETTING LOCK STATUS: ", error)
  }


}

export const updateLockStatus = async (lockId, action) => {
  const url = `${process.env.REACT_APP_NODE_API_URL}/api/lock/${lockId}/${action}`;

  try {
    const token = await Auth.currentSession();

    const res = await axios.put(url, null, {
      headers: {
        Authorization: `Bearer ${token.getIdToken().getJwtToken()}`,
      },
    });
    return res.data
  } catch (error) {
    console.log("ERROR GETTING LOCK STATUS: ", error)
  }
}

export const getLocksList = async () => {
  const url = `${process.env.REACT_APP_NODE_API_URL}/api/lock/august-id`

  try {
    const token = await Auth.currentSession();

    const res = await axios.get(url, {
      headers: {
        Authorization: `Bearer ${token.getIdToken().getJwtToken()}`,
      },
    });

    return res.data
  } catch (error) {
    console.error(error)
  }
}

export const getSNList = async () => {
  const url = `${process.env.REACT_APP_NODE_API_URL}/api/lock/august-sn`

  try {
    const token = await Auth.currentSession();

    const res = await axios.get(url, {
      headers: {
        Authorization: `Bearer ${token.getIdToken().getJwtToken()}`,
      },
    });

    return res.data
  } catch (error) {
    console.error(error)
  }
}