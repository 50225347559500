import styled from 'styled-components';
import Select from 'react-dropdown-select';

export const MobileStyledDiv = styled.div`
  background: #EAEAEB;
  color: #A0A0A0;
  font-family: 'Poppins';
  font-size: 11px;
  padding: 2px 10px;
  border: soild 1px #A0A0A0;
  display: inline-flex;
  border-radius: 100px;
  justify-content: center;
  align-items: center;
  vertical-align: middle;
  margin-top: 3px;
  width: auto;
`;
const MobileStyledSelect = styled(Select)`
gap: 0px;
background: ${(props) => {

    if (props.status === 'active') {
      return 'rgba(0, 186, 8, 0.10)'
    } else if (props.status === 'inactive') {
      return '#EAEAEB'
    } else if (props.status === 'rented' || props.status === 'sold') {
      return '#D8F3F3'
    }
    else if (props.status === 'lease agreement sent' || props.status === 'application received') {
      return '#FFF8CC'
    }
    else {
      return '#9293994d'
    }
  }} !important;

color: ${(props) => {
    if (props.status === 'active') {
      return '#00BA08';
    } else if (props.status === 'inactive') {
      return '#A0A0A0';
    } else if (props.status === 'rented' || props.status === 'sold') {
      return '#1A7D7D';
    } else if (props.status === 'lease agreement sent' || props.status === 'application received') {
      return '#BFA700';
    } else {
      return '#00BA08';
    }
  }};

font-family: 'Poppins';
font-size: 11px;

&.react-dropdown-select {
  display: inline-flex;
  border-radius: 100px;
  min-height: 16px;
  
  ${'' /* padding: 4px; */}
  justify-content: center;
  align-items: center;
  vertical-align: middle;
  width: auto;
  background: #E6F8E6;
  border: 0.5px solid #00BA08;
}

.react-dropdown-select-content {
  display: inline-flex;
  align-items: center;
  justify-content: center;
}
.react-dropdown-select-input {
  ${'' /* padding: 4px; */}
  display: inline-flex;
  align-items: center;
  justify-content: center;
  &::placeholder {
    color: ${(props) => {
    if (props.status === 'active') {
      return '#00BA08';
    } else if (props.status === 'inactive') {
      return '#A0A0A0';
    } else if (props.status === 'rented' || props.status === 'sold') {
      return '#1A7D7D';
    } else if (props.status === 'lease agreement sent' || props.status === 'application received') {
      return '#BFA700';
    } else {
      return '#00BA08';
    }
  }}; 
    font-family: 'Poppins';
    font-size: 11px;
    
  }
}
.react-dropdown-select-dropdown {
  text-align: left;
  padding: 10px;
  width: 190px;
  left: auto;
  right: 15px;
  border-radius: 4px;

  .react-dropdown-select-item {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis; 
  font-weight: 400;
  color: #000;
}


  @media (max-width: 767px) {
    left: 0;
  }

  .react-dropdown-select-item:hover,
  .react-dropdown-select-item:focus,
  .react-dropdown-select-item-selected {
    background: #ececec;
    color: #1f2327;
    font-family: 'Poppins';
  }

  span {
    ${'' /* border-radius: 3px;
    font-size: 13px;
    line-height: 13px; */}
    padding: 0;
    font-size: 11px;
    margin-bottom: 10px;
    display: inline;
    color: #1f2327;
    font-family: 'Poppins';

    &:last-child {
      margin-bottom: 0;
    }
  }
}

.react-dropdown-select-dropdown-handle {
  margin: 0px 4px 0 5px;
  width: 12px;
  height: 12px;
  display: flex;
  align-items: center;
  
}


`;

export default MobileStyledSelect;