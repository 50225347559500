import { Auth } from "aws-amplify";
import axios from "axios";
import moment from "moment";
import React, { useState, useMemo } from "react";
import { Badge, Button, Col, Form, Modal, Table } from "react-bootstrap";
import { Link, useParams } from "react-router-dom";
import styled from "styled-components";
import { useGeneral } from "../context/GeneralContext";
import { useProperty } from "../context/PropertyContext";
import getImageUrl from "../helper/checkImageFormat";
import { DateRangeSelect } from "./Selects/DateRangeSelect";
import ButtonDropdown from "./Buttons/ButtonDropdown";
import ExportModal from "./Modals/ExportModal";
import UnstyledButton from "./UnstyledButton";
import { BsSliders2 } from "react-icons/bs";
import { IoMdPerson, IoMdSearch } from "react-icons/io";
import { MdLocalPhone, MdEmail } from "react-icons/md";
import { CiExport, CiFilter } from "react-icons/ci";
import { Center, CloseButton, Loader, rem, TextInput } from "@mantine/core";
import removeUndefinedWord from "../helper/updateString";
import { FiltersModal } from "../pages/Leads/components/FiltersModal";
import "../pages/Leads/Leads.scss";

const Container = styled.div`
  width: 100%;
  table {
    tbody {
      tr {
        td,
        td:first-child {
          text-align: left;
          padding-left: 20px;

          a {
            color: #1f2327;
            text-decoration: none;
          }

          .future {
            background-color: #dbffcc !important;
          }

          .past {
            background-color: #fff8cc !important;
          }

          .custom-dropdown {
            background-color: rgba(146, 147, 153, 0.3);
            font-size: 11px;
            letter-spacing: 0.8px;
            line-height: 15px;
            color: #2d2f36;
            border-radius: 3px;
            padding: 2px 28px 2px 7px;
            display: inline-block;
            text-transform: uppercase;
            background-image: url("../../assests/images/downarrow.png");
            background-repeat: no-repeat;
            background-position: calc(100% - 10px) center;
            cursor: pointer;

            &:after {
              content: "";
              height: 10px;
              position: absolute;
              bottom: -8px;
              width: 100%;
              left: 0;
            }

            ul {
              min-width: 190px;
              background-color: #fff;
              border-radius: 3px;
              padding: 8px;
              position: absolute;
              right: 0;
              top: 28px;
              margin-bottom: 0;
              display: none;
              padding-left: 10px;
              list-style: none;
              z-index: 1;

              li {
                text-transform: uppercase;
                font-size: 11px;
                letter-spacing: 0.8px;
                line-height: 15px;
                color: #2d2f36;
                margin-bottom: 10px;
                cursor: pointer;
                text-align: left;
                display: flex;

                & .w-square {
                  padding-left: 36px;

                  &:before {
                    content: "";
                    height: 20px;
                    width: 20px;
                    position: relative;
                    display: inline-block;
                    vertical-align: middle;
                    margin-left: -35px;
                    margin-right: 8px;
                    border-radius: 3px;
                  }

                  & .interested {
                    &:before {
                      background-color: #52ff58;
                      opacity: 0.3;
                    }
                  }

                  & .no-interest {
                    &:before {
                      background-color: #ff7277;
                      opacity: 0.3;
                    }
                  }

                  & .never-arrived {
                    &:before {
                      background-color: #e9d62a;
                      opacity: 0.3;
                    }
                  }

                  & .sent-application {
                    &:before {
                      background-color: #5271ff;
                      opacity: 0.3;
                    }
                  }

                  & .rescheduled {
                    &:before {
                      background-color: #929399;
                      opacity: 0.3;
                    }
                  }

                  & .leave-this {
                    &:before {
                      background-color: rgba(146, 147, 153, 0.3);
                      content: "x";
                      color: #858588;
                      text-align: center;
                      line-height: 20px;
                    }
                  }
                }

                button {
                  background-color: rgba(82, 113, 255, 0.3);
                  font-size: 11px;
                  letter-spacing: 0.8px;
                  line-height: 15px;
                  border-radius: 3px;
                  border: none;
                  text-transform: uppercase;
                  color: #2d2f36;
                  width: 100%;
                  text-align: center;
                }
              }
            }

            &:hover {
              ul {
                display: block;
              }
            }
          }

          & .notes-btn {
            letter-spacing: 0.8px;
            line-height: 15px;
            display: block;
            text-transform: uppercase;
            position: absolute;
            bottom: 10px;
            left: 180px;
            background-color: transparent;
            padding: 0;
            border: none;
          }
        }

        td:last-child {
          text-align: right;
        }
      }
    }
  }
`;

const LeadList = ({ property }) => {
  const [outcomeId, setoutcomeId] = useState();
  const [outcomevalue, setoutcomeValue] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [showFiltersModal, setShowFiltersModal] = useState(false);
  const [searchParam, setSearchParam] = useState("");
  const [page, setPage] = useState(1);
  const [search, setSearch] = useState(true);
  const [listOrder, setListOrder] = useState("desc");
  const [filters, setFilters] = useState({
    startDate: null,
    endDate: null,
    selectedProperties: null,
    search: "",
  });

  const { leads } = useProperty();
  const { setAlertModal, timezone } = useGeneral();

  const memoizedFilters = useMemo(() => filters, [filters]);

  const setOutcome = (e) => {
    var outcomeValue = e.currentTarget.dataset.value;
    var id = e.currentTarget.dataset.id;
    var data = {
      outcome: outcomeValue,
      tags: ["new", "user selected"],
      status: "active",
    };

    Auth.currentSession().then((token) => {
      axios
        .patch(
          `${process.env.REACT_APP_NODE_API_URL}/api/booking/${id}`,
          data,
          {
            headers: {
              Authorization: `Bearer ${token.getIdToken().getJwtToken()}`,
            },
          }
        )
        .then((response) => {
          if (response.status === 200) {
            setoutcomeId(id);
            setoutcomeValue(outcomeValue);
            setAlertModal({
              show: true,
              title: "Success",
              content: "Outcome Updated Successfully",
            });
          } else {
            setAlertModal({
              show: true,
              title: "Error",
              content: "Error Updating Outcome",
            });
          }
        })
        .catch((error) => {
          console.log(error);
          setAlertModal({
            show: true,
            title: "Error",
            content: "Error Updating Outcome",
          });
        });
    });
  };

  const params = useParams();

  const [show, setShow] = useState(false);

  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");

  const handleClose = () => {
    setStartDate("");
    setEndDate("");
    setShow(false);
  };
  const handleShow = (e) => {
    e.preventDefault();
    setShow(true);
  };

  const handleDateRangeChange = (value) => {
    setStartDate(value[0]);
    setEndDate(value[1]);
  };

  const handleExport = (e) => {
    e.preventDefault();
    Auth.currentSession().then((token) => {
      let url = `${process.env.REACT_APP_NODE_API_URL}/api/contact/toCsv?propertyIds=${params.id}`;
      if (startDate) {
        url = `${url}&startDate=${moment(startDate).format("YYYY-MM-DD")}`;
      }
      if (endDate) {
        url = `${url}&endDate=${moment(endDate).format("YYYY-MM-DD")}`;
      }

      console.log(url);
      axios
        .get(url, {
          headers: {
            Authorization: `Bearer ${token.getIdToken().getJwtToken()}`,
          },
        })
        .then((response) => {
          if (response.status === 200) {
            var blob = new Blob([response.data], {
              type: "text/csv",
            });

            const a = document.createElement("a");
            a.download = "leads";
            a.href = window.URL.createObjectURL(blob);
            const clickEvt = new MouseEvent("click", {
              view: window,
              bubbles: true,
              cancelable: true,
            });
            a.dispatchEvent(clickEvt);
            a.remove();
          }

          setStartDate("");
          setEndDate("");
          handleClose();
        })

        .catch((error) => {
          console.log(error);
          handleClose();

          setAlertModal({
            show: true,
            title: "Error",
            content: "There's no data to export",
          });
        });
    });
  };

  const handleCloseFiltersModal = () => setShowFiltersModal(false);

  const handleApplyFilters = ({ selectedProperties, startDate, endDate }) => {
    const newFilters = {};
    if (startDate)
      newFilters.startDate = moment(startDate).format("YYYY-MM-DD");
    if (endDate) newFilters.endDate = moment(endDate).format("YYYY-MM-DD");
    if (selectedProperties)
      newFilters.selectedProperties = selectedProperties
        ?.map((property) => property._id)
        .join();
    newFilters.search = "";

    setFilters(newFilters);
    setShowFiltersModal(false);
    setPage(1);
  };

  const handleResetFilters = () => {
    setFilters({
      startDate: null,
      endDate: null,
      selectedProperties: null,
      search: "",
    });
    setSearchParam("");
  };

  const handleSearchTerm = (e) => {
    if (e.target.value === "") setFilters({ ...filters, search: "" });
    setSearchParam(e.target.value);
  };

  const handleKeyDown = (e) => {
    const { key } = e;
    if (key === "Enter") {
      e.preventDefault();
      setFilters({ ...filters, search: searchParam });
      setPage(1);
    }
  };

  const handleReset = () => {
    setPage(1);
    setSearchParam("");
    setFilters({
      ...filters,
      search: "",
    });
  };

  const filteredLeads = leads.filter((lead) => {
    const firstName = lead.contact?.firstName || "";
    const lastName = lead.contact?.lastName || "";
    const email = lead.contact?.email || "";
    const leadDate = moment(lead.createdAt ?? lead.createdDate).startOf("day");

    const isWithinDateRange =
      (!filters.startDate ||
        leadDate.isSameOrAfter(moment(filters.startDate))) &&
      (!filters.endDate || leadDate.isSameOrBefore(moment(filters.endDate)));

    return (
      (firstName.toLowerCase().includes(filters.search.toLowerCase()) ||
        lastName.toLowerCase().includes(filters.search.toLowerCase()) ||
        email.toLowerCase().includes(filters.search.toLowerCase())) &&
      isWithinDateRange
    );
  });

  const handleSearch = () => {
    setPage(1);
    setSearch(!search);
  };

  const handleSearchOnchange = (e) => {
    setPage(1);
    setSearch(!search);
  };
  return (
    <>
      <Container>
        <div className="d-flex mt-4">
          <TextInput
            placeholder="Search..."
            className="flex-grow-1 w-75"
            style={{ borderRadius: "20px" }}
            name="search"
            onKeyDown={handleKeyDown}
            leftSection={
              <IoMdSearch
                style={{ width: rem(16), height: rem(16), cursor: "pointer" }}
                stroke={1.5}
                onClick={handleSearch}
              />
            }
            value={searchParam}
            onChange={handleSearchTerm}
            rightSection={
              searchParam !== "" ? <CloseButton onClick={handleReset} /> : ""
            }
          />
          <div className="d-flex justify-content-end button-containers">
            <div className="mr-10">
              <UnstyledButton
                variant="outline-dark"
                onClick={handleResetFilters}
                className="clear-buttons"
              >
                Clear All
              </UnstyledButton>
              <Button
                variant="primary"
                onClick={() => setShowFiltersModal(!showFiltersModal)}
                className="side-buttons"
              >
                <BsSliders2 size="14px" className="mr-4" /> Filter By
              </Button>
            </div>

            <Button onClick={(e) => handleExport(e)} className="side-buttons">
              <CiExport size="14px" className="mr-4" /> Export
            </Button>
          </div>
        </div>
        <div className="total-contacts mt-3">
          {isLoading ? (
            <div>Getting contacts...</div>
          ) : filteredLeads?.length > 0 ? (
            <p>Total Contacts: {filteredLeads?.length}</p>
          ) : (
            <p>No Records Found</p>
          )}
        </div>
        <Table responsive className="">
          {isLoading ? (
            <Center mt={20}>
              <Loader />
            </Center>
          ) : (
            <tbody>
              {filteredLeads?.map((element, index) => (
                <tr key={element._id}>
                  <td>
                    <div className="d-flex align-items-center">
                      <Link
                        // to="/leads-details"
                        to={`/leads-details/${element.contact._id}`} //TODO: check
                        state={{ user_id: element.contact._id }}
                        className="properties-details-link"
                      >
                        <span className="position-relative d-inline-block">
                          <img
                            className="id-image"
                            src={getImageUrl(
                              element.contact.document ??
                                element.contact.idImage
                            )}
                            onError={({ currentTarget }) => {
                              currentTarget.onerror = null; // prevents looping
                              currentTarget.src = require("../assests/images/ID.svg");
                            }}
                            alt="identification"
                          />
                        </span>
                      </Link>
                    </div>
                  </td>
                  <td>
                    <div className="ml-20">
                      <div className="d-block">
                        <Link to="#" className="contact-info">
                          <IoMdPerson className="icon" />
                          {element.contact.firstName} {element.contact.lastName}
                        </Link>
                      </div>
                      <div className="d-block">
                        <Link
                          to={`tel:${element.contact.phone}`}
                          className="contact-info"
                        >
                          <MdLocalPhone className="icon" />
                          {element.contact.phone}
                        </Link>
                      </div>
                      <div className="d-block">
                        <Link
                          to={`mailto:${element.contact.email}`}
                          className="contact-info"
                        >
                          <MdEmail className="icon" />
                          {element.contact.email}
                        </Link>
                      </div>
                    </div>
                  </td>
                  <td>
                    <div className="d-flex align-items-center no-wrap-small-screen">
                      <div className="my-4">
                        <div className="d-block latest-showing">
                          latest showing
                          <br />
                          <p className="date-time">
                            {moment(element.startTime * 1000).format(
                              "MMM  DD, yyyy hh:mm a"
                            )}
                          </p>
                        </div>
                      </div>
                    </div>
                  </td>
                  <td className="added-info">
                    <div className="me-5">
                      added on
                      <br />
                      {moment(element.createdAt ?? element.createdDate).format(
                        "MMM d, YYYY hh:mm a"
                      )}
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
          )}
        </Table>
      </Container>

      {showFiltersModal && (
        <FiltersModal
          show={showFiltersModal}
          handleClose={handleCloseFiltersModal}
          handleApplyFilters={handleApplyFilters}
        />
      )}
    </>
  );
};

export default LeadList;
