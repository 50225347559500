import { keepPreviousData, useQuery } from "@tanstack/react-query";
import React, { useState } from "react";
import {
  Badge,
  Button,
  Col,
  Form,
  Offcanvas,
  Row,
  Table,
} from "react-bootstrap";
import { getProperties } from "../../../../api/properties";
import PagePagination from "../../../../components/PagePagination";

const initState = {
  filterBy: {
    search: "",
  },
};

const SelectProperty = ({ show, handleClose, handleSelectProperty }) => {
  const [page, setPage] = React.useState(1);
  const [filterBy, setFilterBy] = useState(initState.filterBy);

  const { data, error, isLoading } = useQuery({
    queryKey: ["projects", page],
    queryFn: async () => await getProperties({ page, filters: filterBy }),
    placeholderData: keepPreviousData,
    staleTime: 5000,
  });

  const handleNextPage = (nextPage) => {
    if (data.totalPages >= nextPage && nextPage > 0) {
      setPage(nextPage);
    }
  };

  const filteredProperties = data?.properties.filter((property) =>
    property.address.toLowerCase().includes(filterBy.search.toLocaleLowerCase())
  );

  return (
    <Offcanvas
      show={show}
      onHide={handleClose}
      placement="end"
      name="end"
      className="selectproperty-offcanvas newshowing-offcanvas"
    >
      <Offcanvas.Header closeButton>
        <Offcanvas.Title>New showing</Offcanvas.Title>
      </Offcanvas.Header>
      <Offcanvas.Body>
        <Form method="post">
          <div className="page-main-head align-items-center p-0">
            <Row className="align-items-center">
              <Col xl={8}>
                <div className="d-flex align-items-center flex-wrap flex-md-nowrap">
                  <div>
                    <Button
                      className="back-btn"
                      onClick={handleClose}
                      type="button"
                    ></Button>
                  </div>
                  <Form className="w-100">
                    <Form.Group
                      className="search-control-group d-flex "
                      controlId="search-control"
                    >
                      <Form.Control
                        type="text"
                        placeholder="Search Property"
                        onChange={(e) => {
                          setFilterBy((prev) => ({
                            ...prev,
                            search: e.target.value,
                          }));
                        }}
                      />{" "}
                    </Form.Group>
                  </Form>
                </div>
              </Col>
            </Row>
          </div>

          <Table responsive className="property-table">
            <tbody>
              {!isLoading ? (
                filteredProperties?.map((property) => (
                  <tr
                    key={property._id}
                    role="button"
                    onClick={() => handleSelectProperty(property)}
                  >
                    <td>
                      <div className="position-relative d-inline-block">
                        <img
                          width={140}
                          height={120}
                          src={
                            property.images[0] ??
                            require("../../../../assests/images/1-thumb.png")
                          }
                          className="rounded"
                          alt="properties"
                        />
                        <span className="text-uppercase properties-kit-status properties-online">
                          online
                        </span>
                      </div>
                    </td>
                    <td>
                      <div className="properties-details-link">
                        <Badge className="rent">for {property.category} </Badge>
                        <p className="addreess">{property.address}</p>
                      </div>
                      <div className="d-flex eminity-group align-items-center">
                        <p className="mb-0 f-s-17">${property.price}</p>
                        <p className="mb-0 line-height-0">
                          <span className="apartment">
                            {property.classification}
                          </span>
                        </p>
                        <p className="mb-0 line-height-0">
                          <span className="text-nowrap">
                            <span className="text-nowrap bedroom">
                              {property.features.rooms} bedrooms
                            </span>
                          </span>
                        </p>
                        <p className="mb-0 line-height-0">
                          {" "}
                          <span className="bath text-nowrap">
                            {" "}
                            {property.features.bathrooms} bath
                          </span>
                        </p>
                      </div>
                    </td>
                  </tr>
                ))
              ) : (
                <div>Getting properties...</div>
              )}
            </tbody>
          </Table>

          {data?.totalPages > 1 && (
            <PagePagination
              totalPages={data.totalPages}
              actualPage={page}
              handlePageChange={handleNextPage}
            />
          )}
        </Form>
      </Offcanvas.Body>
    </Offcanvas>
  );
};

export default SelectProperty;
